export default({titleModel, options, message}) => `
    <div class="messageContainer">
        <div class="message">
            ${message}
            ${options.isLuckyDay && !titleModel.get('isOnHold') ?
                    `<div style="display: inline-block; position: relative">
                        <div style="display: inline-block; float: right">
                            <a role="button" id="luckyDayTooltip" class="help-tip secondary-background" style="float: right"></a>
                        </div>
                    </div>`
                : '' }
            ${titleModel.get('isOnHold') ? `
                <p class="stillOnHoldMessage">${window.html('nonHoldConflictModal.stillOnHold', {title: titleModel.title})}</p>
            ` : ''}
        </div>
    </div>
    <div class="relatedTitlesContainer">
        <div class="loader"></div>
    </div>
`;
