import fillStars from './fillStars.js';
import setUserStarRating from './setUserStarRating.js';

/*
 * Handles a user selecting a rating
 * */
const selectStarRating = function($container, selectedRating, isHover) {
    const mediaId = $container.data('media-id');
    const existingRating = $container.data('user-rating');

    let isReset = false;
    if (existingRating != null && existingRating === selectedRating) {
        isReset = true;
    }
    const rating = isReset ? 0 : selectedRating;
    if (isReset) {
        $container.data('user-rating', null);
        $container.removeClass('user-rating');
        fillStars($container, $container.data('global-rating'), {setRating: false, isHover});
    } else {
        setUserStarRating($container, rating, {setRating: true, isHover});
    }

    if ($container.hasClass('js-no-auth')) {
        // TODO: This will break on pages that need their querystring?
        const forwardUrl = window.location.pathname;
        const action = ['star', mediaId, rating].join('~');
        window.location = window.url(window.routes.accountSignIn, {forward: forwardUrl, action});
    } else if (isReset) {
        ajax.deleteStarRating(mediaId);
    } else {
        ajax.setStarRating(mediaId, rating);
    }
};

export default selectStarRating;
