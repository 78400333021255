// Original: https://jira.hq.overdrive.com/browse/LHTNG-3809 Relocation: https://jira.hq.overdrive.com/browse/LHTNG-3862

const MacmillanMessage = function(model) {
    let badPublisher = parseInt(model.get("publisherAccount").id, 10) === 6222;
    let today = new Date();
    let releaseDate = new Date(model.get("estimatedReleaseDate"));
    let macmillanLicensingChangeInEffect = releaseDate >= new Date(2019, 10, 1);
    let releaseDatePlus8Weeks = new Date(releaseDate);
    releaseDatePlus8Weeks.setDate(releaseDate.getDate() + (8 * 7));
    let releaseDateWithinEightWeeks = (releaseDate < today) && (today < releaseDatePlus8Weeks);

    return window.featureManager.isEnabled('macmillanMessaging')
            && macmillanLicensingChangeInEffect
            && badPublisher
            && (releaseDateWithinEightWeeks || (macmillanLicensingChangeInEffect && model.get("isPrereleaseTitle")))
        ? html('macmillanMessage', {date : releaseDatePlus8Weeks })
        : '';
}

export default MacmillanMessage;
