import keyCodes from '../keycodes.js';

/*
 * Some all expression
 */
const someLongThing = function($target) {
    return (
        !$target.is('h2')
        && $target.closest('li').is(':first-child')
        && (
            (
                $target.closest('ul').hasClass('popular-links')
                && $target.closest('ul').closest('li').is(':first-child')
            )
            || $target.closest('ul').hasClass('featured-links')
        )
    );
};

/*
 * Force nav menu to be navigable with arrow keys in all browsers
 * @param {object} e Event object
 */
const arrowTabMenu = function(e) {
    let id = '';
    const $target = $(e.target);
    if (e.which === keyCodes.DOWN) {
        e.preventDefault();
        // focus on next available element
        if ($target.hasClass('f-dropdown')) {
            $target.children('div.featured').find('h2:first').focus();
        } else if ($target.is('h2')) {
            $target.next('ul').find('a:first').focus();
        } else if ($target.hasClass('browse-all')) {
            if ($target.parents('div').hasClass('featured')) {
                if ($target.parents('.featured').next('div').hasClass('popular')) {
                    $target.parents('.featured').next('div.popular').find('h2:first').focus();
                } else {
                    id = $target.closest('ul').attr('id');
                    $(`li[data-dropdown="${id}"] a`).focus();
                }
            } else {
                id = $target.closest('ul').attr('id');
                $(`li[data-dropdown="${id}"]`).focus();
            }
        } else {
            $target.emulateTab();
        }
    } else if (e.which === keyCodes.UP) {
        e.preventDefault();
        if (someLongThing($target)) {
            $target.closest('div').find('h2').focus();
        } else if ($target.hasClass('browse-all')) {
            $target.prev('ul').find('li:last-child a').focus();
        } else if ($target.is('h2')) {
            if ($target.closest('div').hasClass('popular')) {
                $target.closest('div.popular').prev('div.featured').find('a.browse-all').focus();
            } else {
                id = $target.closest('ul').attr('id');
                $(`li[data-dropdown="${id}"] a`).focus();
            }
        } else {
            $target.emulateTab(-1);
        }
    }
    e.stopPropagation();
};

export default arrowTabMenu;
