import { kanopyMenuAdTemplate } from './kanopy-menu-ad-view.html';
import { toStaticUrl } from '../../../../lib/assetMapper';

const { bowser } = window;

export class KanopyMenuAdView extends Backbone.View {
    highContrast;
    isTolinoDevice;
    kanopySignUpUrl;

    constructor({ highContrast, isTolinoDevice, kanopySignUpUrl }) {
        super(...arguments);

        this.highContrast = highContrast;
        this.isTolinoDevice = isTolinoDevice;
        this.kanopySignUpUrl = kanopySignUpUrl;

        this.render();
    }

    render() {
        const titlesTopRow = [];
        const titlesBottomRow = [];

        for (let index = 1; index <= 10; index++) {
            const titleSrc = toStaticUrl(
                `img/kanopy/menu-ad/kanopy-img-${index}.jpeg`
            );
            if (index <= 5) {
                titlesTopRow.push(titleSrc);
            } else {
                titlesBottomRow.push(titleSrc);
            }
        }

        const { mobile, tablet } = bowser;
        const promoteSignUp =
            !mobile && !tablet && !this.inApp && !this.isTolinoDevice;
        const kanopySignUpUrl = promoteSignUp
            ? this.kanopySignUpUrl
            : 'https://www.kanopy.com';

        const view = kanopyMenuAdTemplate({
            kanopySignUpUrl,
            promoteSignUp,
            showTitles:
                !this.isTolinoDevice &&
                !this.highContrast &&
                !window.featureManager.isEnabled('hide-kanopy-covers'),
            titlesBottomRow,
            titlesTopRow,
        });
        this.$el.empty().append(view);
    }
}
