export default class TrackingSignInForms {
    constructor(bus) {
        this.bus = bus;

        this._dataLayer = window.dataLayer || this.debugInstance;

        this.bus
            .on('signIn:library', (params) =>
                this.pushSignInEvent(params, 'Library Card Sign In')
            )
            .on('signIn:external', (params) =>
                this.pushSignInEvent(params, 'External Sign In')
            )
            .on('signIn.email', (params) =>
                this.pushSignInEvent(params, 'Email Sign In')
            )
            .on('signIn:mobileNum', (params) =>
                this.pushSignInEvent(params, 'Mobile Number Sign In')
            )
            .on('signIn:geo', (params) =>
                this.pushSignInEvent(params, 'Geo Sign In')
            )
            .on('mobile:code:verified', (params) =>
                this.pushSignInEvent(params, 'Mobile Number Code Verified')
            )
            .on('mobile:code:sent', (params) =>
                this.pushSignInEvent(params, 'Send Mobile Verification Code')
            )
            .on('mobile:code:resent', (params) =>
                this.pushSignInEvent(params, 'Resend Mobile Verification Code')
            )
            .on('email:code:sent', (params) =>
                this.pushSignInEvent(params, 'Send Email Verification Code')
            )
            .on('email:code:resent', (params) =>
                this.pushSignInEvent(params, 'Resend Email Verification Code')
            )
            .on('email:code:verified', (params) =>
                this.pushSignInEvent(params, 'Email Code Verified')
            )

            .on('mobile:register:code:sent', () =>
                this.pushRegisterEvent('Send Mobile verification code')
            )
            .on('mobile:register:code:resent', () =>
                this.pushRegisterEvent('Resend Mobile verification code')
            )
            .on('mobile:register:code:verified', () =>
                this.pushRegisterEvent('Mobile Number Code Verified')
            )
            .on('mobile:register:submit', () =>
                this.pushRegisterEvent('Form submit')
            )

            .on('geo:register:view', () =>
                this.pushRegisterEvent('View geo login page')
            )
            .on('geo:register:accept:start', () =>
                this.pushRegisterEvent('Initiated geo auth')
            )
            .on('geo:register:accept:success', () =>
                this.pushRegisterEvent('Geo auth success')
            )
            .on('geo:register:accept:fail', () =>
                this.pushRegisterEvent('Geo auth failure')
            );
    }

    get debugInstance() {
        return {
            push: ({ event, content }) => {
                console.log(`ga:log("${event}"):`, { event, content }); // eslint-disable-line no-console
            },
        };
    }

    pushSignInEvent(selectedILS, action) {
        let content = {
            event: 'auth',
            eventCategory: 'Authentication',
            eventAction: action,
            eventLabel: selectedILS,
        };

        this._dataLayer.push(content);
    }

    pushRegisterEvent(action) {
        let content = {
            event: 'register',
            eventCategory: 'Sign up',
            eventAction: 'Get a card',
            eventLabel: action,
        };

        this._dataLayer.push(content);
    }
}
