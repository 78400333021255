import Popover  from   '../../components/popover/popover';

export default class MacOSAudiobookSupportPopover{
    
    constructor(el) {
        this.template = `<p>${html("macOsAudiobookSuppression.supportedVersions")}</p>`;

        this.el = el;
    }

    render(){
        return new Popover({
            target: this.el,
            contents: this.template,
            width: 375
        });
    }
}
