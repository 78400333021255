const hideArrowsOnEndOfScroll = function(e, el) {
    const media = $(el).children('.js-carouselItem');
    const elemWidth = $(el)[0].getBoundingClientRect().width;
    const mediaWidth = media[0].getBoundingClientRect().width;
    const mediaAmount = media.length;

    if ($(el).scrollLeft() === 0) {
        $(el).parent().siblings('.js-carouselArrowLeft').css('visibility', 'hidden');
    } else {
        $(el).parent().siblings('.js-carouselArrowLeft').css('visibility', 'visible');
    }

    if ($(el).scrollLeft() + elemWidth >= (mediaWidth * mediaAmount) + (mediaAmount * 3.3)) {
        $(el).parent().siblings('.js-carouselArrowRight').css('visibility', 'hidden');

        // Stop scrolling past content in Firefox
        if (window.scrollAmt === 0) {
            window.scrollAmt = $(el).scrollLeft();
        } else {
            $(el).scrollLeft(window.scrollAmt);
            window.scrollAmt = 0;
        }
    } else {
        $(el).parent().siblings('.js-carouselArrowRight').css('visibility', 'visible');
    }
};

export default hideArrowsOnEndOfScroll;
