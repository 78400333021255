import { toStaticUrl } from '../../../../lib/assetMapper';

export default () => `
    <div>
        <img src="${toStaticUrl('img/shield-lock.svg')}" />
        <h3>${text('tlsWarning.noAccess')}</h3>
        <p class="align-left">${text('tlsWarning.description')}</p>
        <p><a href="https://help.overdrive.com/en-us/2271.htm" target="_blank" rel="noreferrer" class="underline">${text(
            'tlsWarning.learnMore'
        )}</a></p>
        <button class="button radius secondary js-close">${text(
            'tlsWarning.continueToLibrary'
        )}</button>
    </div>
`;
