
var messageformat = require('./messageformat');
var utility = require('./utility');

/**
 * @param {Translations} translations - The translations configuration object
 * @param {Logger} logger - An optional object-logger
 */
function Translater(translations, logger, websiteId=0) {
    // template text -> locale -> templateFunction
    // NOTE: We map using the template text to reduce cache key uniqueness errors
    // NOTE: We include the locale in the cache as the same text can be used for multiple locales;
    //       for example the following would cache the first locale to use it: {x, number}.
    this._compiledTemplates = {};

    // key (-> subkey)* -> (english template | locale -> template)
    this._translations = translations;

    // (level: string, message: string, args: string -> object): void
    this._logger = logger;

    this.websiteId = websiteId;
}

Translater.prototype.translate = function(key, locale, args, options, requestUrl) {
    locale = locale || 'en';
    args = args || {};
    options = options || {};

    // Sould never be valid, but also guard against later code trying keys
    // that append strings onto nothing, which could be false positives.
    if (!key) {
        var keyDescription = key === '' ? '(empty string)' : key === null ? '(null)' : '(undefined)';
        this._log('error', 'Expected a non-empty key', {keyDescription, requestUrl});

        return null;
    }

    var result = null;

    try {
        result = this._translate(key, locale, args, options, requestUrl);
    } catch (e) {
        // already logged; just gotta give them nothing for now
    }

    if (result) {
        // result should already be formatted
        return result;
    } else {
        // still gotta format nothing for all the logic to work out
        return utility.formatLocalizedText(key, result, options);
    }
};

Translater.prototype._translate = function(requestKey, locale, args, options, requestUrl) {
    var keysToTry = this._getKeysToTry(requestKey);

    for (var key of keysToTry) {
        var translation = this._tryGetTranslation(key);

        if (translation != null) {
            var templateFunction = this._compileTemplate(key, locale, translation, requestUrl);

            if (templateFunction != null) {
                var translated = templateFunction(args);
                var result = utility.formatLocalizedText(key, translated, options);

                return result;
            }
        }
    }

    // no keys could produce a result
    this._log('error', 'Could not find a translation for any of the following keys', {websiteId: this.websiteId, keysToTry, key: requestKey, locale, args, requestUrl});

    return null;
};

Translater.prototype._compileTemplate = function(key, rawLocale, translation, requestUrl) {
    var localesToTry = this._getLocalesToTry(rawLocale);

    for (var locale of localesToTry) {
        var template = translation[locale];

        if (template != null) {
            if (this._compiledTemplates[template] == null) {
                this._compiledTemplates[template] = {};
            }

            if (this._compiledTemplates[template][locale] == null) {
                this._compiledTemplates[template][locale] = messageformat.compileTemplate(template, locale);
            }

            return this._compiledTemplates[template][locale];
        }
    }
    
    this._log('error', 'Found a translation but no template for any of the following locales', {translation, localesToTry, rawLocale, key, requestUrl});

    return null;
};

Translater.prototype._tryGetTranslation = function(key) {
    var parts = key.split('.');
    var cursor = this._translations;
    for (var part of parts) {
        cursor = cursor[part];

        if (cursor == null) {
            return null;
        }
    }

    var translation = cursor;

    // If the translation mapping is just a string, treat it as a mapping
    // of english to that string.
    if (typeof translation === 'string') {
        translation = {en: translation};
    }

    return translation;
};

Translater.prototype._getLocalesToTry = function(locale) {
    if (locale && locale !== 'en') {
        var dashIndex = locale.indexOf('-');

        if (dashIndex === -1) {
            return [locale, 'en'];
        } else {
            var language = locale.substr(0, dashIndex);

            if (language.toLowerCase() === 'en') {
                return [locale, 'en'];
            } else {
                return [locale, language, 'en'];
            }
        }
    } else {
        return ['en'];
    }
};

Translater.prototype._getKeysToTry = function(key) {
    return [key + 'Html', key];
};

Translater.prototype._log = function(level, message, args) {
    if (this._logger) {
        this._logger(level, message, args);
    }
};

module.exports = Translater;
