import RemoveHistoryModal from '../../components/media/views/RemoveHistoryModal.js';

class ReadingHistory {
    constructor(bus) {
        this.bus = bus;
    }

    importFromRated() {
        this.bus.trigger('history:importFromRated:start');

        return $.post(window.url(window.routes.rest.readingHistory.index))
            .done((...params)   => this.bus.trigger('history:importFromRated:success', ...params))
            .fail((...params)   => this.bus.trigger('history:importFromRated:fail', ...params))
            .always(()          => this.bus.trigger('history:importFromRated:end'));
    }

    readingHistoryAction(titleModel, context) {
        let action = titleModel.get('isInHistory') ? 'remove' : 'add';

        this.bus.trigger('title:history:start', { titleModel, action });

        let response = new Promise((resolve, reject) => {
            if (action === 'remove') {
                new RemoveHistoryModal({
                    bus   : this.bus,
                    title : titleModel,
                    action: () => {
                        this._historyRemoveAjax(titleModel, context).then(resolve).fail(reject);
                    }
                });
            } else {
                this._historyAddAjax(titleModel, context).then(resolve).fail(reject);
            }
        });

        return response
            .then((...params)   => this.bus.trigger('title:history:success',    { titleModel, action, context }, ...params))
            .catch((...params)  => this.bus.trigger('title:history:fail',       { titleModel, action, context }, ...params))
            .then(()            => this.bus.trigger('title:history:end',        { titleModel, action, context }));
    }

    _historyAddAjax(titleModel, context) {
        this.bus.trigger('title:history:add:start', { titleModel, context });

        let response = $.ajax({
            url: window.url(window.routes.rest.readingHistory.title, {id: titleModel.get('id')}),
            type: 'PUT'
        });

        response
            .done(() => {
                titleModel.set('isInHistory', !titleModel.get('isInHistory'));
                this.bus.trigger('title:history:add:success', { titleModel, context });
            })
            .fail((...params) => this.bus.trigger('title:history:add:fail', { titleModel, context }, ...params))
            .always(() => this.bus.trigger('title:history:add:end', { titleModel, context }));

        return response;
    }

    _historyRemoveAjax(titleModel, context) {
        this.bus.trigger('title:history:remove:start', { titleModel, context });

        let response = $.ajax({
            url: window.url(window.routes.rest.readingHistory.title, {id: titleModel.get('id')}),
            type: 'DELETE'
        });

        response
            .done(() => {
                titleModel.set('isInHistory', !titleModel.get('isInHistory'));
                this.bus.trigger('title:history:remove:success', { titleModel, context });
            })
            .fail((...params) => this.bus.trigger('title:history:remove:fail', { titleModel, context }, ...params))
            .always(() => this.bus.trigger('title:history:remove:end', { titleModel, context }));

        return response;
    }
}

export default ReadingHistory;
