import LendingPeriodModal from '../../components/lending_period_modal/LendingPeriodModal';
import VisitorActionDisallowedModal from '../../components/visitor_action_disallowed_modal/VisitorActionDisallowedModal';

class Borrow {
    constructor(bus) {
        this.bus = bus;
    }

    borrowTitle(titleModel, context) {
        let borrow = () => {
            if (!context.isRenewal) {
                titleModel.set('titleState', 'waiting');
            }

            void new LendingPeriodModal({
                model: titleModel,
                bus: this.bus,
                isRenewal: !!context.isRenewal,
                action: (modal) => {
                    this._borrowAjax(titleModel, context).always(() => {
                        modal.close();
                    });
                },
                onError: (error) => {
                    this._borrowFailure(titleModel, {}, '', error);
                },
            });
        };

        if (window.featureManager.isEnabled('LHTNG-3387')) {
            if (titleModel.isAvailable || !!context.isRenewal) {
                borrow();
            } else if (window.OverDrive.isVisitor) {
                new VisitorActionDisallowedModal('borrow');
            }
        } else {
            borrow();
        }
    }

    _borrowAjax(titleModel, context) {
        this.bus.trigger('title:borrow:start', { titleModel, context });

        let { mobile, tablet } = bowser;

        const lendingPeriod = titleModel.get('lendingPeriod');
        return $.post(
            window.url(window.routes.mediaBorrow, { mediaId: titleModel.id }),
            {
                lendingPeriod: lendingPeriod.lendingPeriod,
                units: lendingPeriod.units,
                isMobile: mobile || tablet,
                returnFullLoanObject: true,
                isRenewal: !!context.isRenewal,
                isLuckyDayCheckout:
                    titleModel.isLuckyDayCheckout && !context.isRenewal,
            }
        )
            .done((response) =>
                this._borrowSuccess(titleModel, response, context)
            )
            .fail((jqXHR, textStatus, errorThrown) =>
                this._borrowFailure(titleModel, jqXHR, textStatus, errorThrown)
            )
            .always(() => {
                this.bus.trigger('title:borrow:end', titleModel);
            });
    }

    _borrowSuccess(titleModel, response, context) {
        if (!!context.isRenewal) {
            titleModel.set('titleState', 'renewed');
        } else {
            titleModel.set('titleState', 'borrowed');
        }

        this.bus.trigger('title:borrow:success', {
            expires: response.expires,
            loan: response,
            titleModel,
            context,
        });

        titleModel.set('isInHistory', true);
    }

    _borrowFailure(titleModel, jqXHR, textStatus, errorThrown, context) {
        if (!/account\/loans/.test(window.location.href)) {
            titleModel.set('titleState', 'borrow');
        }

        this.bus.trigger('title:borrow:fail', {
            jqXHR,
            textStatus,
            errorThrown,
            titleModel,
            context,
        });
    }
}

export default Borrow;
