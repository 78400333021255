
export default class TrackingPublicApiFulfillment {
    get isDebug() {
        return _(window.ga).isEmpty();
    }

    get debugInstance() {
        return {
            push: ({ event, content }) => {
                console.log(`ga:log("${event}"):`, content); // eslint-disable-line no-console
            }
        };
    }

    constructor(bus) {
        this.bus = bus;

        this._dataLayer = window.dataLayer || this.debugInstance;

        this.bus
            .on('apifulfillment:render',            (params) => this.pushEvent(params, "lhtngAPIPlutoRender"))
            .on('apifulfillment:fulfill',            (params) => this.pushEvent(params, "lhtngAPIPlutoFulfill"))
            .on('apifulfillment:appStore',            (params) => this.pushEvent(params, "lhtngAPIPlutoAppStore"))
            .on('apifulfillment:textGen',            (params) => this.pushEvent(params, "lhtngAPIPlutoTextGen"))
            .on('apifulfillment:textDone',            (params) => this.pushEvent(params, "lhtngAPIPlutoTextDone"))
            .on('apifulfillment:errors',            (params) => this.pushEvent(params, 'lhtngAPIPlutoErrors'))
    }

    pushEvent(context, eventName) {
            this._dataLayer.push({
                event: eventName,
                content: context
            });
    }
}
