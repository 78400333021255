
/* eslint-env browser */

var Translater = require('../translater');
var utility = require('../utility');

var _logger = function(level, message, args) {
    try {
        var error = level && level.toLowerCase() === 'error';

        if (error && window.NREUM) {
            var errorMessage = message + ' ' + window.JSON.stringify(args);
            window.NREUM.noticeError(errorMessage);
        }

        if (window && window.console) {
            if (error && window.console.error) {
                window.console.error(message, args);
            } else {
                window.console.log(message, args);
            }
        }
    } catch (e) {
        // ¯\_(ツ)_/¯
    }
};

var _getLocale = function() {
    if (window.OverDrive && window.OverDrive._locale) {
        return window.OverDrive._locale;
    } else {
        return 'en';
    }
};

var _translaterSingleton = new Translater(window.OverDrive.translations, _logger, window.OverDrive.tenant);

var _text = function(expectHtml, key, args) {
    var locale = _getLocale();
    var options = utility.getTranslationOptions(locale, expectHtml);

    return _translaterSingleton.translate(key, locale, args, options);
};

/**
 * @param {string} key - The key to find the translation
 * @param {object} args - The arguments to use for the template
 */
var html = _text.bind(null, true);

/**
 * @param {string} key - The key to find the translation
 * @param {object} args - The arguments to use for the template
 */
var text = _text.bind(null, false);

module.exports = {
    html,
    text
};
