import { Modal }                        from '../common/modal';

const { text } = window;

export default class VisitorActionDisallowedModal extends Modal {
    constructor(action) {
        let actionText = text(`visitorActionDisallowedModal.${action.trim().toLowerCase()}`);
        if (!actionText) {
            throw new Error(`'${action}' is not a supported action`);
        }
        let message = text('visitorActionDisallowedModal.message', {libraryName: window.OverDrive.libraryName, action: actionText});
        const modalContents = {
            classes: ['VisitorActionDisallowedModal'],
            title: text('visitorActionDisallowedModal.heading'),
            content: `<p>${message}</p>
                        <div class='button-container'>
                            <button type='button' class='confirm-button button radius secondary contrast u-allCaps'>${html('ok')}</button>
                        </div>`
        };

        super(modalContents);

        this.$el.find('.confirm-button').on('click', () => {
            this.close();
        });
    }
}
