const fillStars = function($container, stars, options) {
    stars = stars || 0;
    options = options || {};
    let children = $container.children('.icon-star');

    children.removeClass('filled');
    if (!options.isHover) { // If the user is actually setting the rating
        children.each((i, el) => {
            let ratingCopy = window.html('starRating.unratedLabel', {number: i + 1, currentRating: stars});
            if (options.setRating) {
                ratingCopy = window.html('starRating.ratedLabelLong', {number: i + 1, currentRating: stars});
            }
            $(el).attr('aria-label', ratingCopy);
        });
    }

    children = children.filter((i) => (i + 1) <= Math.round(stars))
    .addClass('filled');

    if (options.setRating) {
        children
            .last()
            .attr('aria-label', window.html('starRating.removeRating', {stars}));
    }
};

export default fillStars;
