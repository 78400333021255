import numbers from '../../../lib/numbers';

const { url, routes, Cookies } = window;

// Fakeing in the path for now ...
routes.rest.preferences = { global: { index:'/rest/preferences/global/' } };

export default class GlobalPreferences extends Backbone.Model {

    // Cookie Cache Key: 'Global Account Preferences'
    _key = '_gap'

    url()   { return url(routes.rest.preferences.global.index); }
    isNew() { return false; }

    defaults() {
        return {
            kindleFulfillment: null
        };
    }

    constructor(attributes, { bus }) {
        super(...arguments);
        this.bus = bus;

        this._initCache();
    }

    setKindleFulfillmentPreference(enabled = true, source) {
        return new Promise((resolve, reject) => {
            this.save({ kindleFulfillment: !!enabled }, {
                patch   : true,
                wait    : true,
                success : (context) => {
                    this.bus.trigger('preferences:global:update:success', { context, source });
                    this._cacheSet(this.attributes);
                    resolve();
                },
                error   : (error) => {
                    reject(error);
                }
            });
        });
    }


    // -- Caching Functionality -----------------------------------------------
    _initCache() {
        if (window.OverDrive.isAuthenticated && window.OverDrive.hasLinkedCards) {
            if (this._hasCachedModel) {
                this.set(this._cacheGet());

                // _fetch should only be used for async syncing and not for raw data access
                this._fetch = Promise.resolve();
            } else {
                this._fetch = this.fetch();
                this._fetch.then(() => {
                    this._cacheSet(this.attributes);
                });
            }
        }
    }

    _cacheGet() {
        return JSON.parse(Cookies.get(this._key) || '{}');
    }

    _cacheSet(model) {
        Cookies.set(this._key, JSON.stringify(model), {
            expires: new Date(new Date().getTime() + 10 * numbers.minuteInMilliseconds)
        });
    }

    get _hasCachedModel() {
        return !_(this._cacheGet()).isEmpty();
    }
}
