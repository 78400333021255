import HoldInformationModal from './views/HoldInformationModal';
import HoldInformationModalV2 from '../holdInformationV2/HoldInformationModalV2';
import MediaInterface from '../media/MediaInterface';
import Title from '../media/models/Title';

const createHoldsRatioModalComponent = ({el}) => {
    // The refactored stuff doesn't use this, but the account page will need to still
    // Kill this with so much fire when that's done.
    $(document).on('click', '.js-holds-ratio-help-icon.js-holds-ratio-account', (e) => {
        const mediaInterface = new MediaInterface();

        const $target = $(e.target).closest('.js-holds-ratio-help-icon.js-holds-ratio-account');
        const mediaId = $target.data('media-id');

        mediaInterface.search({titleId: mediaId}).then((response) => {
            const model = new Title(response);

            // making the assumption that titles on the holds page are on hold
            model.set({titleState: 'onHold'});

            window.ajax.getHold(model.get('id')).done((holdData) => { // should be confirming that this is on hold
                model.set({
                    holdsRatio: holdData.holdsRatio,
                    holdsCount: holdData.holdsCount,
                    holdListPosition: holdData.holdListPosition
                });

                if (window.featureManager.isEnabled('LHTNG-2863')){
                    new HoldInformationModalV2({model, hideHoldsLink: true, el});
                } else {
                    new HoldInformationModal({model, type: 'basicPosition', el});
                }

            });
        });
    });
};

export default createHoldsRatioModalComponent;
