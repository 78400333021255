const { html, url, routes } = window;

export default ({ wasSuccessful, buttonText, continueTargetUrl }) => `
<div class='info-container'>
    ${
        wasSuccessful
            ? `
        <p>${html('kindleFulfillment.setPrefSuccessModal.successMsg')}</p>
    `
            : `
        <p>${html('kindleFulfillment.setPrefErrorModal.errorMsg', {
            accountSettingsLink: `<a href='${url(
                routes.accountSettings
            )}'>${html('accountSettings.accountSettings')}</a>`,
        })}</p>
    `
    }
</div>
<div class='button-container'>
    ${
        continueTargetUrl
            ? `<a href=${continueTargetUrl} target='_blank' rel='noreferrer' class='continue-button button margin-small radius secondary contrast u-allCaps'>
                ${buttonText}
           </a>`
            : `<button type='button' class='continue-button button margin-small radius secondary contrast u-allCaps'>
                ${buttonText}
           </button>`
    }
</div>
`;
