
class StarRatingActions {
    constructor(bus) {
        this.bus = bus;

        this.bus.on('title:rating:fail', (data) => this.ratingFail(data));
    }

    ratingFail(data) {
        let response;
        try {
            response = JSON.parse(data.jqXHR.responseText);
        } catch (ex){
            response = {};
        }

        if (response.sessionExpired){
            this.bus.trigger('user:session:expired');
        } else {
            let link = `<a class="bold" href="${window.url(window.routes.supportMembers)}" tabindex="0">${window.html('pleaseContactSupport')}</a>`;
            toaster.push(`<strong>${window.html('genericError')}</strong> ${window.text('error.errorContactSupport', {link})}`, 'error');
        }
    }
}

export default StarRatingActions;
