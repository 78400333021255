import { toStaticUrl } from '../../../../lib/assetMapper';
import { appleAppStoreBadge, googlePlayStoreBadge } from '../app-badge/app-badge.html';
import { awards } from './app-store-awards.html';

const html = (key) => window.html(key);
const text = (key) => window.text(key);

const getLocale = () => ((window.OverDrive && window.OverDrive._locale) || 'en').toLowerCase();

const appStoreLinks = (appleAppStoreHref, googlePlayStoreHref, highContrast) => `
    <div class="libby-app-group__row IE-margin">
        <div class="libby-app-group__column libby-app-group__app-column">
            <div class="libby-app-group__app-container">
                <div class="libby-app-group__app-libby">
                    <img alt="${html('libby.libbyIcon')}" class="libby-app-group__app-libby-icon"
                        src="${toStaticUrl('img/Libby-App-Icon.svg')}" />

                    <span class="libby-app-group__app-libby-headline">
                        ${text('libby.libbyHeadline')}
                    </span>    
                    <span class="libby-app-group__app-libby-tagline">
                        ${text('libby.libbyTagline')}
                    </span>
                </div>

                <div class="libby-app-group__app-link-container">
                    ${appleAppStoreBadge({ href: appleAppStoreHref, highContrast, userLanguageCode: getLocale() })}
                    ${googlePlayStoreBadge({ href: googlePlayStoreHref, highContrast, userLanguageCode: getLocale() })}
                </div>
            </div>
        </div>
    </div>
`;

const kindleFire = ({ isKindle }) =>
    isKindle ? '' :
        `<div class="libby-app-group__row libby-app-group__kindle-fire-row">
        <div class="libby-app-group__column libby-app-group__kindle-fire-column">
           <p>
               <a class="libby-app-group__kindle-fire-link" 
                    href="https://www.overdrive.com/apps/libby/kindlefire" 
                    rel="noreferrer"
                    target="_blank">
                    ${text('libby.downloadFromAmazonAppstore')}
                </a>
           </p>
        </div>
    </div>
`;

export const libbyAppGroup = ({
    appleAppStoreHref, googlePlayStoreHref, highContrast, showAwards, showKindleFireLink, isKindle
}) => `
    <div class="libby-app-group">
        ${appStoreLinks(appleAppStoreHref, googlePlayStoreHref, highContrast)}
        ${showAwards ? awards(highContrast) : ''}
        ${showKindleFireLink ? kindleFire({ isKindle }) : ''}
    </div>
`;
