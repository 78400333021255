module.exports={
    "supportedLanguages": [
        {
            "languageCodes": [
                "en",
                "en-029",
                "en-AU",
                "en-BZ",
                "en-CA",
                "en-GB",
                "en-IE",
                "en-IN",
                "en-JM",
                "en-MY",
                "en-NZ",
                "en-PH",
                "en-SG",
                "en-TT",
                "en-US",
                "en-ZA",
                "en-ZW"
            ],
            "defaultLanguageCode": "en",
            "nativeDisplayName": "English",
            "displayName": "English"
        },
        {
            "languageCodes": [
                "es",
                "es-AR",
                "es-BO",
                "es-CL",
                "es-CO",
                "es-CR",
                "es-DO",
                "es-EC",
                "es-ES",
                "es-GT",
                "es-HN",
                "es-MX",
                "es-NI",
                "es-PA",
                "es-PE",
                "es-PR",
                "es-PY",
                "es-SV",
                "es-US",
                "es-UY",
                "es-VE"
            ],
            "defaultLanguageCode": "es",
            "nativeDisplayName": "Español",
            "displayName": "Spanish"
        },
        {
            "languageCodes": [
                "fr-CA",
                "fr"
            ],
            "defaultLanguageCode": "fr-CA",
            "nativeDisplayName": "Français (Canada)",
            "displayName": "French (Canadian)"
        },
        {
            "languageCodes": [
                "zh-CN",
                "zh-Hans",
                "zh-SG",
                "zh"
            ],
            "defaultLanguageCode": "zh-Hans",
            "nativeDisplayName": "中文(简体)",
            "displayName": "Chinese (Simplified)"
        },
        {
            "languageCodes": [
                "zh-Hant",
                "zh-HK",
                "zh-MO",
                "zh-TW"
            ],
            "defaultLanguageCode": "zh-Hant",
            "nativeDisplayName": "中文(繁體)",
            "displayName": "Chinese (Traditional)"
        },
        {
            "languageCodes": [
                "ja",
                "ja-JP"
            ],
            "defaultLanguageCode": "ja",
            "nativeDisplayName": "日本語",
            "displayName": "Japanese"
        },
        {
            "languageCodes": [
                "ms",
                "ms-BN",
                "ms-MY"
            ],
            "defaultLanguageCode": "ms",
            "nativeDisplayName": "Bahasa Melayu",
            "displayName": "Malay"
        },
        {
            "languageCodes": [
                "ru",
                "ru-RU"
            ],
            "defaultLanguageCode": "ru",
            "nativeDisplayName": "Русский",
            "displayName": "Russian"
        },
        {
            "languageCodes": [
                "sv",
                "sv-FI",
                "sv-SE"
            ],
            "defaultLanguageCode": "sv",
            "nativeDisplayName": "Svenska",
            "displayName": "Swedish"
        },
        {
            "languageCodes": [
                "fi",
                "fi-FI"
            ],
            "defaultLanguageCode": "fi",
            "nativeDisplayName": "Suomi",
            "displayName": "Finnish"
        },
        {
            "languageCodes": [
                "it",
                "it-CH",
                "it-IT"
            ],
            "defaultLanguageCode": "it",
            "nativeDisplayName": "Italiano",
            "displayName": "Italian"
        },
        {
            "languageCodes": [
                "de",
                "de-AT",
                "de-CH",
                "de-DE",
                "de-LI",
                "de-LU"
            ],
            "defaultLanguageCode": "de",
            "nativeDisplayName": "Deutsch",
            "displayName": "German"
        },
        {
            "languageCodes": [
                "da",
                "da-DK"
            ],
            "defaultLanguageCode": "da",
            "nativeDisplayName": "Dansk",
            "displayName": "Danish"
        },
        {
            "languageCodes": [
                "tr",
                "tr-TR"
            ],
            "defaultLanguageCode": "tr",
            "nativeDisplayName": "Türkçe",
            "displayName": "Turkish"
        },
        {
            "languageCodes": [
                "ko",
                "ko-KR"
            ],
            "defaultLanguageCode": "ko",
            "nativeDisplayName": "한국어",
            "displayName": "Korean"
        },
        {
            "languageCodes": [
                "nl",
                "nl-BE",
                "nl-NL"
            ],
            "defaultLanguageCode": "nl",
            "nativeDisplayName": "Nederlands",
            "displayName": "Dutch"
        },
        {
            "languageCodes": [
                "ta",
                "ta-IN"
            ],
            "defaultLanguageCode": "ta",
            "nativeDisplayName": "தமிழ்",
            "displayName": "Tamil"
        },
        {
            "languageCodes": [
                "is",
                "is-IS"
            ],
            "defaultLanguageCode": "is",
            "nativeDisplayName": "Íslenska",
            "displayName": "Icelandic"
        }
    ]
}
