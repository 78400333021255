const { url, routes } = window;

export default class NotificationsEventTracker {
    constructor(bus) {

        this.bus = bus;
        this._websiteId = window.OverDrive.tenant;

        this.bus
            .on('title:borrow:success',         (params) => this.pushDismissalNotification(params, 'borrow'))
            .on('title:hold:success',           (params) => this.pushDismissalNotification(params, 'hold'))
            .on('title:hold-cancel:success',    (params) => this.pushDismissalNotification(params, 'cancel-hold'))
            .on('title:suspend',                (params) => this.pushDismissalNotification(params, 'suspend-hold'))
            .on('title:suspend:cancelled',      (params) => this.pushDismissalNotification(params, 'cancel-suspended-hold'))
        ;
    }

    pushDismissalNotification({titleModel, context}, event){
        new Promise((resolve, reject) =>
        $.ajax({
            url: url(routes.rest.patronNotifications.dismissByAction),
            type: 'POST',
            data: {
                action: event,
                websiteId: this._websiteId,
                titleId: titleModel.id
            }
        })
        .done((response) => resolve(response))
        .fail((xhr, status, err) => reject(err)))
        .catch((error) => {
            window.Logger.log('error', 'Failed to send dismiss-by-circ-action notification', {message: error.statusText || error});
        });
    }
}