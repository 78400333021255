import HistoryEventListener from '../history_event_tracker/HistoryEventListener';
/**
 * class to implement proper behavior to exit sticky rooms when the back button is hit
 * the non-cached logic is in sticky-room.js
 */
export default class BackButtonRedirect extends HistoryEventListener {
    constructor(tracker) {
        super(tracker);
        this._cacheFallback();
    }

    onLoad(states) {
        if (window.OverDrive.currentRoom) {
            this.getTracker().addDataToLatest('roomKey', window.OverDrive.currentRoom.urlPath);
        }
        else {
            this.getTracker().addDataToLatest('roomKey', 'site-default');
        }
    }


    /**
     * Delete persistent room cookie if user ends up on main site while having a preferred room
     * Only applicable when the user hits the browser's back button and the page is loaded by browser cache
     */
    _cacheFallback() {
        window.hasPreferredRoom = !!Cookies.get('preferred-room');
        if (!window.OverDrive.currentRoom && window.hasPreferredRoom) {
            Cookies.remove('preferred-room', {path: '/', domain: location.hostname});
            window.hasPreferredRoom = false;
        }
    }
}
