const Backbone = window.Backbone;
const text = window.text;
const url = window.url;
const acceptableTypes = ['ebook', 'audiobook', 'video', 'magazine'];

import { Modal } from '../common/modal';
import TitleFormatBadge from '../media/views/TitleInfo/TitleFormatBadge';
import Cover from '../media/Cover';
import KindlePreference from '../kindle_preference_modal/KindlePreference';
import DownloadWarningModal from '../media/views/DownloadWarningModal';
import { getTitleFulfillmentButtonLayout } from '../../../../lib/fulfillment-ts.js';
import MacOSAudiobookSupportPopover from '../MacOSAudiobookSupportPopover/MacOSAudiobookSupportPopover';
import { toStaticUrl } from '../../../../lib/assetMapper';

export default class TitleFulfillmentModal extends Backbone.View {
    constructor({
        loan,
        title,
        bus,
        featureManager,
        patronSettings,
        accountLimits,
        isRenewal = false,
        formatLock = null,
    }) {
        super(...arguments);
        this.loan = loan;
        this.title = title;
        this.bus = bus;
        this.featureManager = featureManager;
        this.patronSettings = patronSettings;
        this.accountLimits = accountLimits;
        this.isRenewal = isRenewal;
        this.formatLock = formatLock;
        this._views = [];

        let inApp = window.OverDrive.inApp;
        this.allowMp3AudiobookDownloads = !(
            this.featureManager.isEnabled('mp3-in-app-only') && !inApp
        );
        this.showNoMP3LibbyPromo =
            this.loan.isAudiobook &&
            this.loan.hasMp3Format &&
            this.featureManager.isEnabled('mp3-in-app-only') &&
            !inApp &&
            this.featureManager.isEnabled('promoteLibby');
        this._views.push(
            (this.cover = new Cover({
                model: this.title,
                coverWidths: { mobile: 250, tablet: 250, desktop: 250 },
            }))
        );
        this._views.push(
            (this.formatBadge = new TitleFormatBadge({
                typeId: this.title.get('type').id,
            }))
        );
    }

    render() {
        if (acceptableTypes.includes(this.loan.type.id)) {
            this.formatBadge.render();

            let html = this.renderTemplate();

            let modalContents = {
                title: text('genericSuccess'),
                autoShow: false,
                classes: ['large', 'TitleFulfillmentModal'],
                content: html,
            };

            this.modal = new Modal(modalContents);
            this.attachEvents(this.modal.$el);
            this.modal.show();
        } else {
            window.Logger.log('error', 'Title has unexpected media type', {
                source: 'fulfillment modal',
                title: this.loan.title,
                id: this.loan.id,
                type: this.loan.type,
            });
            if (toaster) {
                toaster.push(text('genericError'), 'error');
            }
        }
    }

    attachEvents(node) {
        let titleModel = this.title;
        node.find('.kindleFulfillmentButton').click((evt) => {
            let formatId = $(evt.currentTarget).attr('data-format-id');

            if (
                window.appPromoManager.isAppPromoEnabled(
                    window.appPromoManager.APPLICATIONS.KINDLEPREF
                ) &&
                !(
                    Cookies.get('kindleFulfillmentAppOverride') &&
                    Cookies.get('kindleFulfillmentAppOverride') === '1'
                ) &&
                window.globalPreferences.get('kindleFulfillment') == null
            ) {
                // don't follow the href on the kindle button since we need to show a modal sequence first.
                evt.preventDefault();

                let fulfillTarget = url(window.routes.mediaDownload, {
                    format: formatId,
                    mediaId: this.loan.id,
                });

                new KindlePreference({
                    bus: this.bus,
                    globalPreferences: window.globalPreferences,
                    source: 'content_fulfillment',
                    completionTargetUrl: fulfillTarget,
                }).run();
            }
            this.bus.trigger('title:download', {
                titleModel,
                context: { formatId },
            });
        });

        node.find('.od-format-button').click((evt) => {
            let formatId = $(evt.currentTarget).attr('data-format-id');
            this.bus.trigger('title:download', {
                titleModel,
                context: { formatId },
            });
        });

        node.find('.loan-button-nonkindle, .Loans-bundledButton').click(
            (evt) => {
                new DownloadWarningModal($(evt.currentTarget));
                let formatId = $(evt.currentTarget).attr('data-format-id');
                this.bus.trigger('title:download', {
                    titleModel,
                    context: { formatId },
                });
            }
        );

        let popoverElm = node.find('.macOSAudiobookDownloadSupport');
        if (popoverElm) {
            new MacOSAudiobookSupportPopover(popoverElm).render();
        }

        node.find('.unable-to-download').click(() =>
            this.onUnableToDownloadClick()
        );
    }

    get suppressAudiobookButton() {
        const isMac = !!(
            window.navigator.userAgent.match(/Mac/) && !bowser.ios
        );
        return (
            this.loan.isAudiobook &&
            this.featureManager.isEnabled('noAudiobooksForMacOS') &&
            isMac &&
            !bowser.ios &&
            (!this.featureManager.isEnabled(
                'reenableMp3DownloadMacOSPreCatalina'
            ) ||
                !window.OverDrive.isPreCatalinaMac) &&
            !(
                this.featureManager.isEnabled('stop-treating-ipad-like-mac') &&
                window.navigator.userAgent.match(
                    /App Store OverDrive Media Console/
                )
            )
        );
    }

    get isAudiobookOnMac() {
        const isMac = !!(
            window.navigator.userAgent.match(/Mac/) && !bowser.ios
        );
        const isIpad =
            this.featureManager.isEnabled('stop-treating-ipad-like-mac') &&
            window.navigator.userAgent.match(
                /App Store OverDrive Media Console/
            );
        return (
            this.loan.isAudiobook &&
            this.featureManager.isEnabled('noAudiobooksForMacOS') &&
            isMac &&
            !bowser.ios &&
            !isIpad
        );
    }

    onUnableToDownloadClick() {
        const modal = this.modal.$el;
        const modalBackdrop = this.modal.$el.data('reveal-init').bg;

        modal.addClass('TitleFulfillmentModal--no-z-index');
        modalBackdrop.hide();

        window.windowsAppDownloadMessage.update({
            onClose: () => {
                window.windowsAppDownloadMessage.update({
                    onClose: window.hideWindowsAppDownloadMessage,
                    open: false,
                });

                modalBackdrop.show();
                modal.removeClass('TitleFulfillmentModal--no-z-index');
            },
            open: true,
            isAudiobook: this.loan.isAudiobook,
            isEbook: this.loan.isEbook,
            displayReadNowInBrowser: !!(
                this.loan.showODFormatButton || this.loan.hasMediaDoFormat
            ),
        });
    }

    renderTemplate() {
        const kindleOnly = this.isRenewal && this.formatLock === 'ebook-kindle';
        const buttons = this.getButtonsHtml(kindleOnly);
        return this.renderFullTemplate(buttons, kindleOnly);
    }

    getButtonsHtml(kindleOnly) {
        this.filterRestrictedFormats();

        let buttonLayout = {};
        let firstButton = '';
        buttonLayout = getTitleFulfillmentButtonLayout(
            this.featureManager,
            this.loan,
            {
                inApp: window.OverDrive.inApp,
                isAudiobookOnMac: this.isAudiobookOnMac,
                isIpadUserAgent: window.navigator.userAgent.match(
                    /App Store OverDrive Media Console/
                ),
                isTolinoDevice: window.OverDrive.isTolinoDevice,
                kindleOnly,
                suppressAudiobookButton: this.suppressAudiobookButton,
            }
        );

        return `
            ${firstButton}
            ${
                buttonLayout.mainButton === 'kindle'
                    ? this.renderKindleButton()
                    : ''
            }
            ${
                buttonLayout.mainButton === 'download'
                    ? this.renderDownloadButton(buttonLayout.formatID)
                    : ''
            }
            ${
                buttonLayout.mainButton === 'unsupported'
                    ? this.renderDownloadButton(buttonLayout.formatID, true)
                    : ''
            }
            ${
                buttonLayout.mainButton === 'odread'
                    ? this.renderODReadButton()
                    : ''
            }
            ${
                this.allowMp3AudiobookDownloads &&
                buttonLayout.showMacOsAudiobookSuppressionStyle === 'suppress'
                    ? this.renderMacOSSuppression()
                    : ''
            }
            ${
                buttonLayout.showNoKindleWarning
                    ? `<div class='no-kindle-warning'>${text(
                          'accountLoans.kindleFulfillment.noneAvailable'
                      )}</div>`
                    : ''
            }
            ${
                buttonLayout.showMainDropdown
                    ? this.renderDownloadDropdown()
                    : ''
            }
            ${
                buttonLayout.showDivider && !this.showNoMP3LibbyPromo
                    ? this.renderButtonDivider()
                    : ''
            }
            ${this.showNoMP3LibbyPromo ? this.renderNoMP3LibbyPromo() : ''}
            ${
                buttonLayout.subButton === 'odread'
                    ? this.renderODReadButton()
                    : ''
            }
            ${
                buttonLayout.audiobookAfterSunset ||
                buttonLayout.subButton === 'audiobook' ||
                (buttonLayout.mainButton === 'audiobook' &&
                    this.featureManager.isEnabled(
                        'sunset-audiobook-rewrite'
                    )) ||
                (buttonLayout.formatID === 'audiobook-mp3' &&
                    buttonLayout.mainButton === 'odread')
                    ? this.renderAudiobookAccordion()
                    : ''
            }
            ${
                buttonLayout.showOtherButtonBelowDivider
                    ? this.renderDownloadButton()
                    : ''
            }
            ${
                buttonLayout.showDownloadDropdownBelowDivider
                    ? this.renderDownloadDropdown()
                    : ''
            }
            ${
                buttonLayout.showRestrictedVideoWarning
                    ? this.renderStreamingVideoWarning()
                    : ''
            }
            ${
                buttonLayout.showRestrictedFormatWarning
                    ? this.renderRestrictedFormatWarning(
                          this.loan.restrictedFormats
                      )
                    : ''
            }
            ${
                this.allowMp3AudiobookDownloads &&
                buttonLayout.showMacOsAudiobookSuppressionStyle === 'warn'
                    ? this.renderAudiobookWarning()
                    : ''
            }
            ${this.renderWindowsAppDownloadMessage()}
        `;
    }

    renderNoMP3LibbyPromo() {
        if (this.showNoMP3LibbyPromo) {
            return `<div class="NoMp3LibbyPromo">
                        <div class="AudiobookReplacementLibbyPromo">
                            <div class="logoBox">
                                <img alt="Logo for Libby" src="${toStaticUrl(
                                    'img/libby-mascot-talking.svg'
                                )}" class="libby-head"></div>
                            <div class="textBox">
                                <p>${text(
                                    'fulfillmentView.getLibbyAudiobook'
                                )}</p>    
                            </div>
                        </div>
                    </div>`;
        }
    }

    renderAudiobookWarning() {
        return `
        <div class="macOsAudiobookWarning">
            <div class="supportedVersions">${html(
                'macOsAudiobookSuppression.supportedVersions'
            )}</div>
        </div>
        `;
    }

    renderMacOSSuppression() {
        return `
        <div class="secondary-color macOSAudiobookDownloadSupport">
            <div class="textContainer">
                <p>${html('macOsAudiobookSuppression.aboutMacOS')}</p>
            </div>
        </div>
        `;
    }

    renderFullTemplate(buttonStack, kindleOnly) {
        let titleSpan = `<span class='title'>${this.title.get('title')}</span>`;
        let loansRemaining = this.accountLimits.loansRemaining;

        let youCanBorrowText = '';
        if (loansRemaining > 0) {
            if (this.loan.isMagazine) {
                youCanBorrowText = text(
                    'accountLoans.magazinesDontCountAgainstCheckout',
                    { loans: loansRemaining }
                );
            } else {
                youCanBorrowText = text('accountLoans.youCanBorrowMoreTitles', {
                    loans: loansRemaining,
                });
            }
        } else if (
            !(
                this.featureManager.isEnabled(
                    'fix-titlemodal-magazine-display'
                ) && this.loan.isMagazine
            )
        ) {
            youCanBorrowText = text('accountLoans.youCanBorrowNoMoreTitles');
        }

        return `
        <div class="content">
            <div class="row">
                <div class="small-5 columns">${this.cover.$el.html()}</div>
                <div class="small-7 columns metadata-column">
                    <div class="borrowed-until">
                        <div>${
                            this.loan.useFirstPlayText
                                ? text(
                                      'borrowSuccessModal.loanedUntilFirstPlay',
                                      {
                                          date: this.loan.expires,
                                          title: titleSpan,
                                          hours: this.loan.constraints
                                              .watchExpirationHours,
                                      }
                                  )
                                : this.isRenewal
                                ? text('renewal.titleNowExpires', {
                                      title: titleSpan,
                                      expirationDate: this.loan.expires,
                                  })
                                : text('borrowSuccessModal.loanedUntil', {
                                      date: this.loan.expires,
                                      title: titleSpan,
                                  })
                        }</div>
                        ${
                            this.isRenewal
                                ? ''
                                : `<div class='loans-remaining'>${youCanBorrowText}</div>`
                        }
                    </div>
                    ${
                        this.loan.bundledContent.length > 0
                            ? this.renderBundledContent()
                            : ''
                    }
                    <div class="buttons show-for-medium-up">
                        ${
                            this.isRenewal && !!this.formatLock
                                ? kindleOnly
                                    ? `<div class="newDueDate kindleSteps bold">${html(
                                          'renewal.kindleRenewalInstructions.header'
                                      )}</div>
                                <ol>
                                    <li class="kindleSteps"><a href='https://www.amazon.com/mn/dcw/myx.html#/home/content/booksAll/dateDsc/' target='_blank' rel='noreferrer'>${html(
                                        'renewal.kindleRenewalInstructions.returnLinkText'
                                    )}</a></li>
                                    <li class="kindleSteps">${html(
                                        'renewal.kindleRenewalInstructions.sendBook'
                                    )}</li>
                                </ol>`
                                    : `<div class="newDueDate bold">${html(
                                          'renewal.downloadAgainText'
                                      )}</div>`
                                : ''
                        }
                        ${buttonStack}
                    </div>
                </div>

            </div>

            <div class="buttons full-modal-width show-for-small-only">
                ${
                    this.isRenewal && !!this.formatLock
                        ? kindleOnly
                            ? `<div class="newDueDate kindleSteps bold">${html(
                                  'renewal.kindleRenewalInstructions.header'
                              )}</div>
                        <ol>
                            <li class="kindleSteps"><a href='https://www.amazon.com/mn/dcw/myx.html#/home/content/booksAll/dateDsc/' target='_blank' rel='noreferrer'>${html(
                                'renewal.kindleRenewalInstructions.returnLinkText'
                            )}</a></li>
                            <li class="kindleSteps">${html(
                                'renewal.kindleRenewalInstructions.sendBook'
                            )}</li>
                        </ol>`
                            : `<div class="newDueDate bold">${html(
                                  'renewal.downloadAgainText'
                              )}</div>`
                        : ''
                }
                ${buttonStack}
            </div>
        </div>`;
    }

    renderButtonDivider() {
        // If we're showing the divider usually there are two fulfillment options being presented.  The exception is the case
        // where the first slot is filled by the "kindle not available" message.
        return `<div class="button-divider-container">
                    <div class="button-divider"></div>
                    ${
                        !this.loan.showNoKindleMessage
                            ? `
                    <span class="divider-text u-allCaps">
                        ${html('or')}
                    </span>`
                            : ''
                    }
                </div>`;
    }

    renderKindleButton() {
        return `<a target="_blank" rel="noreferrer" href="${url(
            window.routes.mediaDownload,
            {
                format: this.loan.kindleFormat.id,
                mediaId: this.loan.id,
            }
        )}"
                    class="button radius kindleFulfillmentButton" data-download tabindex="0" role="button"
                    data-format-id="${
                        this.loan.kindleFormat.id
                    }" data-media-id="${this.loan.id}"
                    data-format-name="${html(
                        `formats.${this.loan.kindleFormat.id}`
                    )}">
                    <span> ${text('kindleFulfillment.readNow', {
                        Kindle: `<i class="icon-kindle-logo" aria-label="${html(
                            'kindleFulfillment.kindle'
                        )}"></i>`,
                    })}</span>
                </a>
                <div class='kindle-read-on'>
                    <span>${text(
                        'accountLoans.kindleFulfillment.kindleReadOn'
                    )}</span>
                    <span style="display:inline-block">
                        <i class='icon-android'></i>
                        <i class='icon-apple'></i>
                        <i class='icon-windows'></i>
                    </span>
                </div>
                ${
                    this.loan.showKindleTroubleAccordian
                        ? this.renderKindleTroubleAccordion()
                        : ''
                }
                `;
    }

    renderKindleTroubleAccordion() {
        let embeddedButton = '';
        if (this.loan.hasOverdriveFormat) {
            embeddedButton = this.renderODReadButton();
        }

        return `<ul class="accordion kindle-trouble-container" data-accordion>
                    <li class="accordion-navigation" >
                        <a class="accordion-title" href="#havingTroublePanel${
                            this.loan.id
                        }">
                            <div class="row">
                                <div class='small-10 columns title-text'>${text(
                                    'accountLoans.havingTrouble'
                                )}</div>
                                <div class='small-2 columns down-arrow-container'>
                                    <i class="icon-down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>
                        </a>
                        <div id="havingTroublePanel${
                            this.loan.id
                        }" class="content">
                            ${embeddedButton}
                        </div>
                    </li>
                </ul>`;
    }

    renderAudiobookAccordion() {
        if (
            !this.featureManager.isEnabled('disableOdAppAccess') &&
            !this.featureManager.isEnabled('promoteLibby') &&
            this.featureManager.isEnabled('keep-od-app-fulfillment') &&
            this.allowMp3AudiobookDownloads
        ) {
            return `<div class="loan-button-audiobook">
            ${
                this.loan.hasNonKindleNonReadFormat
                    ? `<div class="content">
                        ${this.buildAudiobookAccordionComponent()}
                    </div>`
                    : ''
            }
                </div>`;
        }

        if (
            !this.featureManager.isEnabled('promoteLibby') &&
            this.allowMp3AudiobookDownloads &&
            !this.suppressAudiobookButton
        ) {
            return `<div class="loan-button-audiobook">
                    ${
                        this.loan.hasNonKindleNonReadFormat
                            ? `<ul class="accordion kindle-trouble-container" data-accordion>
                        <li class="accordion-navigation" >
                            <a class="accordion-title" href="#havingTroublePanel${
                                this.loan.id
                            }">
                                <div class="row">
                                    <div class='small-11 columns title-text'>
                                        ${
                                            this.featureManager.isEnabled(
                                                'disableOdAppAccess'
                                            )
                                                ? text(
                                                      'accountLoans.Mp3FulfillmentWarning'
                                                  )
                                                : text(
                                                      'accountLoans.OdAppInstalled'
                                                  )
                                        }
                                    </div>
                                    <div class='small-1 columns down-arrow-container'>
                                        <i class="icon-down-arrow" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>
                            <div id="havingTroublePanel${
                                this.loan.id
                            }" class="content">
                                ${this.renderDownloadButton()}
                            </div>
                        </li>
                    </ul>`
                            : ''
                    }
                </div>`;
        }

        return `<div class="loan-button-audiobook">
                    ${
                        this.featureManager.isEnabled('promoteLibby')
                            ? this.buildAudiobookAccordionLibbyPromo()
                            : ''
                    }
                    
                    ${
                        this.loan.hasNonKindleNonReadFormat
                            ? this.buildAudiobookAccordionComponent()
                            : ''
                    }
                </div>`;
    }

    buildAudiobookAccordionLibbyPromo() {
        if (!this.showNoMP3LibbyPromo) {
            if (this.featureManager.isEnabled('download-libby-modal')) {
                return `<p class="downloadText">${text(
                    'accountLoans.DownloadInApp'
                )}</p>
                        <a href="https://help.libbyapp.com/en-us/6103.htm" class="libby-link bold" target="_blank" rel="noreferrer" role="link">
                            ${text('accountLoans.GetLibby')}
                        </a>`;
            } else {
                return `<a href="https://meet.libbyapp.com/" class="libby-link bold" target="_blank" rel="noreferrer" role="link">
                    ${text('accountLoans.GetLibby')}
                </a>`;
            }
        } else {
            return '';
        }
    }

    buildAudiobookAccordionComponent() {
        let dropdownText = window.featureManager.isEnabled('disableOdAppAccess')
            ? text('accountLoans.Mp3FulfillmentWarning')
            : text('accountLoans.OdAppInstalled');
        let dropdownElement =
            this.allowMp3AudiobookDownloads && !this.suppressAudiobookButton
                ? this.renderDownloadButton()
                : '';

        if (
            this.featureManager.isEnabled('show-od-desktop-message') &&
            !this.allowMp3AudiobookDownloads
        ) {
            dropdownText = text('accountLoans.Mp3FulfillmentWarning');
            dropdownElement = `<p class="noDownloadButtonMessage"> 
            ${text('accountLoans.downloadAudiobooksWithLibby')} </p>`;
        }

        if (dropdownElement !== '') {
            return `<ul class="accordion kindle-trouble-container" data-accordion>
                    <li class="accordion-navigation" >
                        <a class="accordion-title" href="#havingTroublePanel${this.loan.id}">
                            <div class="row">
                                <div class='small-11 columns title-text'>
                                    ${dropdownText}
                                </div>
                                <div class='small-1 columns down-arrow-container'>
                                    <i class="icon-down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>
                        </a>
                        <div id="havingTroublePanel${this.loan.id}" class="content">
                            ${dropdownElement}
                        </div>
                    </li>
                </ul>`;
        } else {
            return '';
        }
    }

    renderDownloadButton(
        formatId = this.loan.nonKindleNonReadFormats[0].id,
        unsupportedFormat = false
    ) {
        if (unsupportedFormat) {
            return `
                <div class="text-center no-download">${window.text(
                    `formats.${formatId}`
                )}<br/>
                    <strong>${window.text('accountLoans.unsupportedFormat', {
                        formats: 1,
                    })}</strong>
                </div>
            `;
        } else if (!this.featureManager.isEnabled('tolino-other-button')) {
            const unsupportedTolinoFormatDetails =
                this._hideUnsupportedDownloadOptionsForTolino(formatId);
            if (unsupportedTolinoFormatDetails.hide) {
                return `
                <div class="text-center no-download">${
                    unsupportedTolinoFormatDetails.format
                }<br/>
                    <strong>${window.text('accountLoans.unsupportedFormat', {
                        formats: 1,
                    })}</strong>
                </div>
            `;
            }
        }

        return `<a target="_blank" rel="noreferrer"
                    class="button loan-button-nonkindle radius primary" tabindex="0" role="button"
                    data-format-id="${formatId}" data-media-id="${this.loan.id}"
                    data-format-name="${html(`formats.${formatId}`)}">
                    <b>${
                        formatId === 'ebook-media-do'
                            ? html('readNow')
                            : this.isRenewal && !!this.formatLock
                            ? html('renewal.downloadAgain')
                            : html('accountLoans.download')
                    }</b><br/>
                    <span class="dl-text">${html(`formats.${formatId}`)}</span>
                </a>`;
    }

    renderODReadButton() {
        let btnSubText = '';
        let btnActionName = '';

        if (this.loan.isEbook || this.loan.isMagazine) {
            btnActionName = text('readNow');
        } else if (this.loan.isVideo) {
            btnActionName = window.OverDrive.inApp
                ? text('addToBookshelf')
                : text('watchNow');
        } else if (this.loan.isAudiobook) {
            btnActionName = text('listenNow');
        }

        if (this.loan.hasReadalong) {
            btnSubText = `<br /><span class="dl-text">${html(
                'narratedModal.heading'
            )}</span>`;
        }

        return `<a ${
            this.loan.overDriveFormat.id === 'video-streaming'
                ? 'data-artificial-lock'
                : ''
        }
                href= "${url(window.routes.mediaDownload, {
                    format: this.loan.overDriveFormat.id,
                    mediaId: this.loan.id,
                })}"
                target = "_blank" rel="noreferrer"
                class="button secondary radius od-format-button"
                role = "button"
                data-format-id="${this.loan.overDriveFormat.id}"
                data-media-id="${
                    this.loan.id
                }" > <b>${btnActionName}</b>${btnSubText}
            </a>`;
    }

    renderDownloadDropdown() {
        let options = '';

        this.loan.nonKindleNonReadFormats.forEach((format) => {
            options = `${options}
                        <li role="listitem">
                            <a data-artificial-lock tabindex="-1" data-format="${html(
                                `formats.${format.id}`
                            )}" data-format-id="${format.id}"
                            data-media-id="${this.loan.id}" aria-label="${html(
                'accountLoans.downloadAs',
                { format: html(`formats.${format.id}`) }
            )}"
                            ${
                                this.loan.otherFormats[0].id === 'ebook-kindle'
                                    ? 'target="_blank" rel="noreferrer"'
                                    : ''
                            }
                            class="format"
                            href="${url(window.routes.mediaDownload, {
                                format: format.id,
                                mediaId: this.loan.id,
                            })}">${html(`formats.${format.id}`)}</a>
                        </li>`;
        });

        let content = `<button role="button" data-dropdown="formats-${
            this.loan.id
        }m"
                                aria-controls="formats-${
                                    this.loan.id
                                }" aria-expanded="false" id="download-dropdown"
                                class="button dropdown radius download-dropdown"><b>${html(
                                    'accountLoans.download'
                                )}</b><br /></button>
                        <ul id="formats-${
                            this.loan.id
                        }m" data-dropdown-content class="f-dropdown download-dropdown-formats" aria-hidden="true" role="list" aria-haspopup="true">
                            ${options}
                        </ul >`;
        return content;
    }

    renderBundledContent() {
        let bundledButtons = '';
        let restrictedBundle = [];
        this.loan.bundledContent.forEach((subcontent) => {
            let format = subcontent.otherFormats[0];
            let label = '';
            switch (format.id) {
                case 'ebook-overdrive':
                case 'magazine-overdrive':
                    label = html('readNow');
                    break;
                case 'audiobook-overdrive':
                    label = html('listenNow');
                    break;
                case 'video-streaming':
                    label = html('watchNow');
                    break;
                default:
                    label = `${html(`formats.${format.id}`)}<span> ${html(
                        'accountLoans.download'
                    )}</span>`;
            }
            if (this.isRestricted(format)) {
                restrictedBundle.push(format);
            } else if (subcontent.id) {
                bundledButtons += `<li class="Loans-bundleListItem"><a class="Loans-bundledButton button primary radius" data-media-id="${
                    subcontent.id
                }" data-format-id="${
                    format.id || subcontent.overDriveFormat.id
                }">
                               ${
                                   subcontent.type
                                       ? `<i class="icon-${subcontent.type.id} Loans-bundledFormatIcon"></i>`
                                       : ''
                               }
                               ${label}</a></li>`;
            }
            // Thunder isn't passing us the bundled content ID's yet, so we have to use a "Go to loans" button for now
            else {
                bundledButtons = `<li class="Loans-bundleListItem"><a href="/account/loans" class="Loans-bundledButton button primary radius u-allCaps">${text(
                    'goToLoans'
                )}</a></li>`;
            }
        });

        return `<a class="Loans-bundleIconLink" data-dropdown="bundleDrop-${
            this.loan.id
        }" aria-controls="bundleDrop-${
            this.loan.id
        }" aria-expanded="false" aria-label="${html(
            'accountLoans.bundledLabel'
        )}">
                    <i class="icon-attachment Loans-bundledIcon" aria-hidden="true"></i>
                </a>
                <ul id="bundleDrop-${
                    this.loan.id
                }" class="f-dropdown Loans-bundledContentList" data-dropdown-content aria-hidden="true" tabindex="-1">
                    <li class="Loans-bundledLabel">${html(
                        'accountLoans.bundledLabel'
                    )}</li>
                    ${bundledButtons}
                    ${
                        restrictedBundle.length > 0
                            ? `<li class="Loans-bundleListItem">${this.renderRestrictedFormatWarning(
                                  restrictedBundle
                              )}</li>`
                            : ''
                    }
                </ul>
        `;
    }

    renderRestrictedFormatWarning(formats) {
        let formatNames = [];
        formats.forEach((format) => {
            formatNames.push(format.name);
        });
        let formatsText = formatNames.join(' | ');
        return `<div class="text-center no-download">
                    <span>${formatsText}</span>
                    <strong>${window.text('accountLoans.unsupportedFormat', {
                        formats: formats.length,
                    })}</strong>
                </div>`;
    }

    renderStreamingVideoWarning() {
        return `<div class="text-center no-download">
                    <span>${window.text('formats.video-streaming')}</span>
                    <strong>${window.text(
                        'accountLoans.updateForVideo'
                    )}</strong>
                </div>`;
    }

    renderWindowsAppDownloadMessage() {
        let isWindows = window.OverDrive.isWindows;
        let inApp = window.OverDrive.inApp;
        let isEbookOrAudiobook = this.loan.isEbook || this.loan.isAudiobook;

        if (
            isWindows &&
            inApp &&
            isEbookOrAudiobook &&
            this.featureManager.isEnabled('windows-app-download-message')
        ) {
            return `
                <div class="download-issue-message">
                    <br><img class="download-issue-icon" src="${toStaticUrl(
                        'img/download-issue-icon.svg'
                    )}">
                    <button class="unable-to-download"> ${html(
                        'windowsAppDownloadMessage.headline'
                    )} </button>
                </div>
            `;
        }
        return '';
    }

    remove() {
        this._views.forEach((view) => view.remove());
        super.remove();
    }

    filterRestrictedFormats() {
        this.loan.restrictedFormats = [];
        if (this.formatLock) {
            this.loan.otherFormats = this.loan.otherFormats.filter(
                (format) => format.id === this.formatLock
            );
        }
        this.loan.otherFormats = this.loan.otherFormats.filter((format) => {
            let isRestricted = this.isRestricted(format);
            if (isRestricted) {
                this.loan.restrictedFormats.push(format);
            }
            return !isRestricted;
        });
    }

    isRestricted(format) {
        let device = window.bowser;
        let isChromebook = !!window.navigator.userAgent.match(/CrOS/);
        let isWindows =
            window.navigator.platform.match(/Win/) && !device.windowsphone;
        let inApp = window.OverDrive.inApp;
        let isMac = window.navigator.userAgent.match(/Mac/) && !device.ios;
        let isIpad =
            this.featureManager.isEnabled('stop-treating-ipad-like-mac') &&
            window.navigator.userAgent.match(
                /App Store OverDrive Media Console/
            );

        switch (format.id) {
            case 'ebook-pdf-adobe':
                return (
                    device.ios ||
                    device.android ||
                    device.windowsphone ||
                    device.blackberry ||
                    isChromebook ||
                    (isWindows && inApp) ||
                    isIpad
                );
            case 'ebook-epub-adobe':
                return isChromebook && !inApp;
            case 'ebook-pdf-open':
                return (
                    ((device.ios ||
                        device.android ||
                        isWindows ||
                        isChromebook) &&
                        inApp) ||
                    device.windowsphone ||
                    device.blackberry ||
                    isIpad
                );
            case 'audiobook-mp3':
                return (
                    (isChromebook && !inApp) ||
                    (isMac &&
                        (this.title.get('formats').length === 1 ||
                            (inApp &&
                                this.title.get('formats').length === 2)) &&
                        this.featureManager.isEnabled('noAudiobooksForMacOS') &&
                        (!this.featureManager.isEnabled(
                            'reenableMp3DownloadMacOSPreCatalina'
                        ) ||
                            !window.OverDrive.isPreCatalinaMac))
                );
            default:
                return false;
        }
    }

    _hideUnsupportedDownloadOptionsForTolino(formatId) {
        if (!window.OverDrive.isTolinoDevice) {
            return { hide: false, format: '' };
        }

        if (formatId === 'ebook-media-do') {
            return { hide: true, format: window.text(`formats.${formatId}`) };
        }

        return { hide: false, format: '' };
    }
}
