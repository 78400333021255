import fillStars from './fillStars.js';

/*
 * Forces a user star rating to be displayed; doesn't persist
 */
const setUserStarRating = function($container, rating, options) {
    $container.data('user-rating', rating);
    $container.removeClass('user-rating').addClass('user-rating');
    fillStars($container, rating, options);
};

export default setUserStarRating;
