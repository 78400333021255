import QuickSearchModel from './models/quick_search';
import AutocompletionsCollection from './collections/autocompletions';
import SearchBarView from './views/search_bar';
import SearchBarAutocompleteView from './views/search_bar_autocomplete';
import AutocompletionsView from './views/autocompletions';
const Backbone = window.Backbone;

const createComponent = ({searchEl, autocompletionsEl, toggleSearchEl, options}) => {
    const query = new QuickSearchModel();
    const featureManager = window.featureManager;

    // Disable autocomplete for rooms
    if (featureManager.isEnabled('autocomplete')) {
        const autocompleteBus = _.extend({}, Backbone.Events);
        const searchBarAutocomplete = new SearchBarAutocompleteView({el: searchEl, toggleSearchEl, query, autocompleteBus}); // eslint-disable-line no-unused-vars
        const autocompletions = new AutocompletionsCollection(null, {query});
        const viewOptions = {
            query,
            autocompleteBus,
            el: autocompletionsEl,
            collection: autocompletions,
            options
        };
        const autocompletionsView = new AutocompletionsView(viewOptions); // eslint-disable-line no-unused-vars
    } else {
        const searchBar = new SearchBarView({el: searchEl, query, options: {$toggleSearch: toggleSearchEl}}); // eslint-disable-line no-unused-vars
    }
};

export default createComponent;
