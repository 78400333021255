import { toStaticUrl } from '../../../../lib/assetMapper';

const getLocaleWithoutSubtag = (locale) => {
    const subtagIndex = locale.indexOf('-');
    return subtagIndex > 0 ? locale.substr(0, subtagIndex) : locale;
};

const getLocaleSpecificUrl = (locale, urlHandler) =>
    urlHandler(locale) ||
    urlHandler(getLocaleWithoutSubtag(locale)) ||
    urlHandler('en');

const getStaticUrlHandler = (urlResolver) => (locale) => {
    const url = toStaticUrl(urlResolver(locale));
    return url.endsWith('undefined') ? undefined : url;
};

const getLocaleStaticUrl = (locale, urlResolver) =>
    getLocaleSpecificUrl(locale, getStaticUrlHandler(urlResolver));

const appBadge = ({ alt, href, id, src }) => `
<a class="app-store-badge__link"
   href="${href}"
   rel="noreferrer"
   target="_blank">
    <img alt="${alt}"  
        class="app-store-badge__icon"
        ${id ? `id="${id}"` : ''}
        src="${src}" />
</a>
`;

export const appleAppStoreBadge = ({
    highContrast,
    href,
    id,
    userLanguageCode,
}) => {
    const src = highContrast
        ? getLocaleStaticUrl(
              userLanguageCode,
              (locale) => `img/apple-app-store/badges/${locale}-hcm.svg`
          )
        : getLocaleStaticUrl(
              userLanguageCode,
              (locale) => `img/apple-app-store/badges/${locale}.svg`
          );
    return appBadge({
        alt: html('apps.badges.appleAppStore'),
        href,
        id,
        src,
    });
};

export const googlePlayStoreBadge = ({
    highContrast,
    href,
    id,
    userLanguageCode,
}) => {
    const src = highContrast
        ? getLocaleStaticUrl(
              userLanguageCode,
              (locale) => `img/google-play-store/badges/${locale}-hcm.svg`
          )
        : getLocaleStaticUrl(
              userLanguageCode,
              (locale) => `img/google-play-store/badges/${locale}.svg`
          );
    return appBadge({
        alt: html('apps.badges.googlePlayStore'),
        href,
        id,
        src,
    });
};
