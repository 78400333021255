
class StarRating {
    constructor(bus) {
        this.bus = bus;
    }

    handlingRating(model) {
        if (model.get('userStarRating') > 0) {
            // User is adding rating
            this._rateTitleAjax(model);
        } else {
            // User is removing rating
            this._deleteRatingAjax(model);
        }
    }

    _rateTitleAjax(model) {
        return $.ajax({
            url: window.url(window.routes.patronMediaStarRatings, {titleId: model.get('id')}),
            type: 'PUT',
            data: {rating: model.get('userStarRating')},
            timeout: 0
        })
        .fail((jqXHR, textStatus, errorThrown) => this.ratingFailure(model, jqXHR, textStatus, errorThrown));
    }

    _deleteRatingAjax(model) {
        return $.ajax({
            url: window.url(window.routes.patronMediaStarRatings, {titleId: model.get('id')}),
            type: 'DELETE',
            timeout: 0
        })
        .fail((jqXHR, textStatus, errorThrown) => this.ratingFailure(model, jqXHR, textStatus, errorThrown));
    }

    ratingFailure(model, jqXHR, textStatus, errorThrown) {
        this.bus.trigger('title:rating:fail', {model, jqXHR, textStatus, errorThrown});
    }
}

export default StarRating;
