/*
 * Snap to nearest cover
 */
const roundScroll = function(n, direction, width) {
    if (direction === 'left') {
        return Math.ceil(n / width) * width;
    }
    return Math.floor(n / width) * width;
};

export default roundScroll;
