
var MessageFormat = require('messageformat');

var compileTemplate = function(template, locale) {
    locale = locale || 'en';

    return new MessageFormat(locale).setIntlSupport(true).compile(template);
};

module.exports = {
    compileTemplate
};
