import BaseCollection from '../../../collections/base';
import AutocompletionModel from '../models/autocompletion';
import {decodeEntities} from '../../../util/text';
import ensureSimpleRequest from '../../../util/requests/ensureSimpleRequest';

const AutocompletionsCollection = BaseCollection.extend({

    model: AutocompletionModel,

    url() {
        return `${OverDrive.autocompleteHost}/autocomplete`;
    },

    initialize(models, {query, count = 15, sortBy = 'score'}) {
        this.query = query;
        this.count = count;
        this.sortBy = sortBy;
        this.apiKey = OverDrive.autocompleteApiKey;

        this.mediaType = this._getSupportedMediaTypes();

        this.listenTo(this.query, 'change:text', this.textChangeHandler.bind(this));
        this.on('sync', this.smartSyncTrigger);
    },

    textChangeHandler() {
        if (this.query.isValidForAutocomplete()) {
            // Try to cancel the previous XHR request if it has not already resolved, otherwise noop
            this._lastFetch && this._lastFetch.abort();

            this._lastFetch = this.fetch();
        } else {
            this.reset();
        }
    },

    queryParams() {
        let params = {query: this.query.get('text'), maxSize: this.count, categorySize: this.count, sortBy: this.sortBy, mediaType: this.mediaType};
        if (OverDrive.autocompleteHost !== OverDrive.thunderHost) {
            params['api-key'] = this.apiKey;
        }

        let result = window.featureManager.isEnabled('filter-out-ntc') ? $.param(params, true) : $.param(params);
        return result;
    },

    fetch() {
        const data = this.queryParams();
        return ensureSimpleRequest(BaseCollection.prototype.fetch.bind(this, {data}));
    },

    smartSyncTrigger(collection, response, options) {
        if (options.data === this.queryParams()) {
            this.trigger('smartsync');
        }
    },

    parse(response) {
        const mapped = _.map(response.items, (item, index) => ({text: decodeEntities(item.text), field: item.field, index}));
        return _.uniq(mapped, (item) => item.text.toUpperCase());
    },

    _getSupportedMediaTypes() {
        let mediaType = [];

        const supportedFormats = window.OverDrive.supportedFormats;
        if (supportedFormats && _.isArray(supportedFormats)) {
            // Rooms only have the format class, so we're doing something gross and
            // stripping the format class off of each format based on string convention
            mediaType = supportedFormats.map((format) => {
                let formatClass = format.id;
                const classEndIndex = formatClass.indexOf('-');
                if (classEndIndex !== -1) {
                    formatClass = formatClass.substring(0, classEndIndex);
                }
                return formatClass;
            });
            mediaType = mediaType.filter((value, index) => mediaType.indexOf(value) === index);
        }

        const room = window.OverDrive.currentRoom;
        const roomFormats = room && room.filters && room.filters.mediaType;
        if (roomFormats) {
            mediaType = _(mediaType).intersection(roomFormats);
        }

        if(window.featureManager.isEnabled('filter-out-ntc')) {
            var whitelistedMediaTypes = window.OverDrive.whitelistedMediaTypes.split(',');
            mediaType = mediaType.filter(type => whitelistedMediaTypes.includes(type));
        }

        return mediaType;
    }
});

export default AutocompletionsCollection;
