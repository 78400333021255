var SimpleHash = {
    hash: function(toHash) {
        var hash = 0, i, chr, len = toHash.length;
        if (toHash.length === 0) {
            return hash;
        }
        for (i = 0; i < len; i++) {
            chr   = toHash.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;

    }
};

module.exports = SimpleHash;
