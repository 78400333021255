var _ = require('underscore');

var urlJoin = require('url-join');
var routes = require('../../routes/routing-configuration').routes;

// Converts nested route map into flattened route hash table
var mapRoutePaths = function(routeMap) {
    var response = {};

    var mapRoutes = function(currentRouteMap, targetObject) {
        _.each(currentRouteMap, function(route) {
            if (_.isObject(route)) {
                mapRoutes(route, targetObject);
            }
            else {
                targetObject[route] = true;
            }
        });
    };

    mapRoutes(routeMap, response);

    return response;
};


// These routes should not be prefixed with advantage keys.
var _unprefixedRoutes = [routes.accountSignIn, routes.accountLinkLibraryCardComplete, routes.assets.css.colors, routes.assets.css.versionedColors, routes.accountSignInComplete, routes.ozone.accountSignoutComplete];

var _caseInsensitiveLookup = function (obj, keyLookup) {
    if (obj != null && keyLookup != null) {
        for (var prop in obj) {
            if (keyLookup.toLowerCase() == prop.toLowerCase()) {
                return {key: prop, value: obj[prop]};
            }
        }
    }

    return null;
};

var routeReverseLoopkup = mapRoutePaths(routes);

var _generateUrlWithParameters = function (pathTemplate, originalParameterDictionary) {
    if (pathTemplate == null) {
        return null;
    }

    // Check to see if the route passed in is in our routing-configuration
    if (!routeReverseLoopkup[pathTemplate]) {
        return null;
    }

    // make a shallow clone of the parameter dictionary
    var parameterDictionary = {};
    for (var originalKey in originalParameterDictionary) {
        if ({}.hasOwnProperty.call(originalParameterDictionary, originalKey)) {
            parameterDictionary[originalKey] = originalParameterDictionary[originalKey];
        }
    }

    var satisfiedAllParameters = true;
    var path = pathTemplate.replace(/:\w+\??/g, function (match) {
        var isOptional = match[match.length - 1] === '?';

        // the length of the key is one less than the match because of the colon prefix
        var keyLength = match.length - 1;
        if (isOptional) {
            // and one less if optional to not include the question mark
            --keyLength;
        }

        // start from 1 to skip the colon prefix
        var key = match.substr(1, keyLength);

        var tuple = _caseInsensitiveLookup(parameterDictionary, key);

        if (tuple == null || tuple.value == null) {
            if (isOptional) {
                delete parameterDictionary[key];  // remove it so it's not in the querystring

                return '';
            } else {
                satisfiedAllParameters = false;

                return null;
            }
        } else {
            delete parameterDictionary[tuple.key];  // remove it so it's not in the querystring

            return tuple.value;
        }
    });

    // TODO: log?  error?
    if (!satisfiedAllParameters) {
        return null;
    }

    // url-join will trim off a trailing slash ONLY when there's a querystring; we'd
    // rather be consistent and just always trim it ourselves to avoid confusion
    // NOTE: the index path is the only case where ending in a slash isn't trailing
    var lastPathChar = path[path.length - 1];
    if (lastPathChar === '/' && path !== '/') {
        path = path.substr(0, path.length - 1);
    }

    // TODO: Support arrays as querystring values
    // TODO: Convert non-strings to strings
    var queryParts = [];
    for (var key in parameterDictionary) {
        if ({}.hasOwnProperty.call(parameterDictionary, key)) {
            queryParts.push(key + '=' + encodeURIComponent(parameterDictionary[key]));
        }
    }
    var query = queryParts.length === 0 ? '' : '?' + queryParts.join('&');

    return path + query;
};

var _prependRoot = function (req, route) {
    var baseUrl = req.baseUrl || '/';
    if (baseUrl === '/') {
        return route;
    } else if (route === '/') {
        return baseUrl;
    } else {
        return urlJoin(baseUrl, route);
    }
};

var getRouteUrl = function (req, route, parameters) {
    var fullRoute = _generateUrlWithParameters(route, parameters || {});
    if (fullRoute != null) {
        var isPrefixed = _unprefixedRoutes.indexOf(route) === -1;
        if (isPrefixed) {
            return _prependRoot(req, fullRoute);
        } else {
            return fullRoute;
        }
    } else {
        // probably log here
        return null;
    }
};

module.exports = {
    routes: routes,
    getRouteUrl: getRouteUrl,

    _caseInsensitiveLookup: _caseInsensitiveLookup
};
