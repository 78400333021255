import BaseModel from '../../../models/base';

const Autocompletion = BaseModel.extend({

    defaults() {
        return {
            text: '',
            active: false
        };
    },

    parse(result) {
        result.text = this.replaceHtml(result.text);
        return result;
    },

    replaceHtml(text) {
        return text.replace('<sup>TM</sup>', '™');
    }
});

export default Autocompletion;
