import { Modal } from '../components/common/modal';

export default class Preview {
    constructor(bus) {
        this.bus = bus;
    }

    giveSample(titleModel){  
        let opts = {
            autoShow: false,
            title: titleModel.get('title'),
            content: `<iframe src="${ titleModel.get('sample').href }"></iframe>`,
            classes: ['sample-modal'],
            closeOnEsc : true,
            closeOnBlur : true,
            showCloseButton: true
        };
        this.modal = new Modal(opts);
        this.modal.show();
    }
}
