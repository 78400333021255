const expensiveDomTraversalForDyslexicPleaseDoNotUse = (font, fontsizeOffset) => {
    $(':not("i"):not(".menu-icon"):not("style"):not("script"):not(.icon-search-nav)').each((index, el) => {
        el.style.setProperty('font-family', font, 'important');
        const $el = $(el);
        if ($el.children().length === 0 || ($el.children().length === 1 && $el.children('i').length >= 1)) {
            const fontsize = parseInt($el.css('font-size'), 10);
            $el.css('font-size', `${fontsize + fontsizeOffset}px`);
        }
    });
};

export default expensiveDomTraversalForDyslexicPleaseDoNotUse;
