/*
 * Add the provided query parameter and navigate to the new URL
 * @param {string} name Name of the query parameter to add
 * @param {string} value The new value of the query parameter to add
 */
const addQueryParam = function(name, value) {
    const path = window.location.pathname;
    let queryString = window.location.search;

    if (queryString) {
        queryString += `&${name}=${value}`;
    } else {
        queryString += `?${name}=${value}`;
    }

    window.location.href = path + queryString;
};

export default addQueryParam;
