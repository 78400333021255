const Backbone  = window.Backbone;

import { Modal } from '../../common/modal';
import TitleActionButton from '../TitleActionButton';
import HoldConflictPanel from '../templates/HoldConflictPanel.html';
import Popover  from   '../../../components/popover/popover';

const HoldConflictModal = Backbone.View.extend({
    initialize(bus, titleModel, options = {}) {
        this.bus = bus;

        const viewData = {
            heading: window.html(!!options.isLuckyDay ? 'nowUnavailableModal.luckyDayNotAvailableHtml' :
                                'nowUnavailableModal.isNotCurrentlyAvailableHtml',
                                {span: `<span>${titleModel.get('title')}</span>`}),
            src: titleModel.getCoverSrc(510),
            isLuckyDay: !!options.isLuckyDay
        };

        let modalContents = {
            autoShow: false,
            title: window.html('nowUnavailableModal.heading'),
            classes: ['now-unavailable-modal'],
            content: HoldConflictPanel(viewData),
            id: 'now-unavailable-modal',
            labelledBy: 'now-unavailable-modal-title'
        };
        this.modal = new Modal(modalContents);

        this.modal.$('.js-cancel').on('click', () => {
            this.modal.close();
        });

        this.listenTo(this.bus, 'title:hold:fail', () => {
           this.modal.close ();
        });

        this.titleActionButton = new TitleActionButton({ model: titleModel, type: 'button', el: this.modal.$('.TitleAction'), style: 'fill' });

        this.modal.show();

        if (options.isLuckyDay){
            this.ldtooltip = new Popover({
                target: this.modal.$el.find('#luckyDayTooltip'),
                contents: `<p>${html('nonHoldConflictModal.luckyDayInfo')}</p>`
            });
        }
    }
});

module.exports = HoldConflictModal;
