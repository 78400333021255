import {html} from '../../../../lib/i18n/browser';
import {toStaticUrl} from '../../../../lib/assetMapper';

const handleForwardActionHold = function(data, titleId) {
    $.when(ajax.getPatronEmail()).done((emailResp) => {
        if (emailResp === '') {
            const title = data.title;
            const typeId = data.type.id;
            const typeName = data.type.name;
            const artwork = data.covers.cover300Wide.href == null ? toStaticUrl('img/no-cover_en_US.jpg') : data.covers.cover300Wide.href;
            const prereleaseDate = html('date-full', {d: data.estimatedReleaseDate});
            const isPrerelease = data.isPreReleaseTitle;
            const mediaId = titleId;

            const modal = $('#hold-dynamic-modal');
            $('[data-id="title"]').html(title);
            $('[data-id="format-icon"]').removeClass().addClass(`icon-${typeId}`);
            $('[data-id="format-name"]').html(typeName);
            $('[data-id="cover-image"]').attr('src', artwork);
            if (prereleaseDate != null && isPrerelease) {
                $('[data-id="pre-release-label"]').text(window.text('accountHolds.prereleaseExpected', {date: prereleaseDate})).removeClass('hideMe');
            } else {
                $('[data-id="pre-release-label"]').text('').addClass('hideMe');
            }

            $('form#hold-dynamic-form').attr({'data-media-id': mediaId, 'data-from-ajax': 'true'});

            // Disney publisher ids are 98146 - 98155
            if (data.publisher.id >= 98146 && data.publisher.id <= 98155 && Cookies.get('disney-eula-setting') !== '1') {
                $('#disney-eula-modal').foundation('reveal', 'open');
            } else {
                modal.foundation('reveal', 'open');
            }
        } else {
            ajax.placeHold(titleId, emailResp, $('body'));
        }
    });
};

export default handleForwardActionHold;
