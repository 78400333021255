import cookieConfig from '../../../lib/cookieConfig';

export default class FeatureHighlightManager {
    constructor(highlightedFeatureState){
        this.highlightedFeatureState = highlightedFeatureState;

        $('.highlightedFeature.notVisited .highlightedFeatureTriggerElement').on('click', (e) => this.highlightedFeatureClicked(e));
        $('.visitAllFeaturesTriggerElement').on('click', () => this.allFeaturesVisited());
    }

    allFeaturesVisited() {
        this.highlightedFeatureState.visitAllActiveFeatures();
        this.save();
    }

    highlightedFeatureClicked (e){
        let featureName = this.getFeatureName(e.currentTarget);

        if (!this.highlightedFeatureState.wasVisited(featureName)){
            this.highlightedFeatureState.visit(featureName);
            this.save();
        }

        this.clearFeatureHighlight(featureName);
        this.updatedHamburgerDot(); // yummy
    }

    getFeatureName(element){
        let parent = this.getFeatureRootElement(element);
        return parent.attributes['data-feature-name'].value;
    }

    clearFeatureHighlight(featureName){
        $(`[data-feature-name="${featureName}"]`).removeClass('notVisited');
    }

    getFeatureRootElement(currentElement){
        if ($(currentElement).hasClass('highlightedFeature')) {
            return currentElement;
        }
        else {
            let featureParent = $(currentElement).closest('.highlightedFeature').first();
            if (!featureParent || featureParent.length === 0){
                throw new Error('Found no ancestor with class "highlightedFeature"');
            }
            return featureParent[0];
        }
    }

    updatedHamburgerDot(){
        if (!this.highlightedFeatureState.unvisitedFeaturePresent) {
            $('.MobileNav-accountMenu').removeClass('highlightedFeaturePresent');
        }
    }

    save() {
        let cookieInfo = cookieConfig.visitedFeatures;
        Cookies.set(cookieInfo.name, this.highlightedFeatureState.flags, { expires: new Date(Date.now() + cookieInfo.durationMS)});

        if(window.OverDrive.isAuthenticated) {
            $.ajax({
                url: window.url(window.routes.preferences),
                type: 'PATCH',
                contentType : 'application/json',
                data: JSON.stringify({ visitedFeatures: this.highlightedFeatureState.flags}),
                error(jqXHR, textStatus, errorThrown) {
                    window.Logger.log('error', 'failed to save visitedFeatures flags to user preferences',{status: textStatus, error: errorThrown});
                }
            });
        }
    }
}
