import template from './SuspendHoldSuccessModalTemplate.html';
import {Modal} from '../common/modal';
const { html, globalEvents } = window;

export default class SuspendHoldSuccessModal extends Backbone.View {
    constructor(model, expirationDate) {
        super(...arguments);
        this.model = model;
        this.expirationDate = expirationDate;
        this.bus = globalEvents;
        this.render();
    }

    render(){
        let modalContents = {
            title: html('suspendHoldModal.headingSuccess'),
            autoShow: false,
            classes: ['small', 'suspension-modal', 'version-two'],
            content: template(this.model.attributes, "", "", "", this.expirationDate)
        };

        if(window.featureManager.isEnabled('suspend-modal-data-adjustment')){
            let cover = this.model.getCover(150);
            modalContents.content = template(null, cover && cover.href, this.model.get('title'), this.model.get('firstCreatorName'), this.expirationDate);
        }

        this.modal = new Modal(modalContents);
        this.attachEvents(this.modal.$el);
        this.modal.show();
    }

    attachEvents(modal){
        modal.find(".cancel").on('click', () => {
            modal.find('.close-reveal-modal').click();
        });

        this.modal.on('close cancel', () => this.bus.trigger('hold:suspend:completed'));
    }
}
