import { toStaticUrl } from '../../../../lib/assetMapper';

const awardBadge = ({ alt, src }) =>
    `<img alt="${alt}" class="libby-app-group__award-icon" src="${toStaticUrl(src)}" />`;

const appleAppStoreAwardBadge = (highContrast) => {
    const src = highContrast
        ? 'img/apple-app-store/app-of-the-day--white.svg'
        : 'img/apple-app-store/app-of-the-day--black.svg';
    return awardBadge({ alt: html('libby.awardAppleAppStore'), src });
};

const googlePlayStoreAwardBadge = (highContrast) => {
    const src = highContrast
        ? 'img/google-play-store/editors-choice--white.svg'
        : 'img/google-play-store/editors-choice--black.svg';
    return awardBadge({ alt: html('libby.awardGooglePlayStore'), src });
};

export const awards = (highContrast) => `
    <div class="libby-app-group__row IE-margin">
        <div class="libby-app-group__column libby-app-group__award-column">
            ${appleAppStoreAwardBadge(highContrast)}
            ${googlePlayStoreAwardBadge(highContrast)}
        </div>
    </div>
`;
