export default class Checkbox extends Backbone.View {
    template({className, name, label, ariaLabel}) {
        return `
            <label class='custom-checkbox'>
                <input type='checkbox' name='${name}' class='${className}' aria-label='${ariaLabel}'/>
                <i class='icon icon-checked'></i>
                <i class='icon icon-unchecked'></i>
                <span class='checkbox-label'>${label}</span>
                <span class="js-saved Saved-container"></span>
            </label>`;
    }

    get events() {
        return {
            'change [type=checkbox]': (e) => {
                this.trigger('change', e.currentTarget.checked);
            }
        };
    }

    constructor({ className, name, label, ariaLabel}) {
        super(...arguments);

        _.extend(this, arguments[0]);
    }

    render() {
        this.$el.empty().append(this.template(this));

        this.$checkbox = this.$el.find('[type=checkbox]:first');

        return this;
    }

    set(checked) {
        this.$checkbox.prop('checked', checked).change();
    }
}
