/* eslint-disable no-invalid-this */
window.ajax = {
    bus: window.globalEvents,
    // Ajax request to borrow a title
    borrow: function (mediaId, lendingPeriod, elements, units) {
        elements.each(function () {
            $(this).trigger('borrowRequest');
        });

        return $.post(
            window.url(window.routes.mediaBorrow, { mediaId: mediaId }),
            {
                lendingPeriod: lendingPeriod,
                units: units,
                isMobile: bowser.mobile || bowser.tablet,
            }
        )
            .done(function (data) {
                elements.each(function (i, el) {
                    var $elm = $(el);

                    if ($elm.attr('data-type') === 'periodicals') {
                        $(document).trigger('nookSuccess', [$elm, data]);

                        if ($elm.context.tagName === 'BODY') {
                            $elm.removeAttr('data-type').remove(
                                'data-media-id'
                            ); // Remove temporary data type attribute
                        }
                    } else {
                        $(document).trigger('borrowSuccess', [$elm, data]);
                    }
                });
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                elements.each(function (i, el) {
                    var $elm = $(el);
                    $elm.trigger('borrowFailure', [
                        $elm,
                        {
                            jqXHR: jqXHR,
                            textStatus: textStatus,
                            errorThrown: errorThrown,
                        },
                    ]);
                });
            });
    },

    returnTitle: function (mediaId) {
        var self = this;
        $.ajax({
            url: window.url(window.routes.mediaReturn, {
                mediaId: parseInt(mediaId, 10),
            }),
            type: 'DELETE',
            success: function () {
                window.location = window.location.pathname;
            },
            error: function (jqXHR, textStatus, errorThrown) {
                var response = JSON.parse(jqXHR.responseText);
                if (response.sessionExpired) {
                    self.bus.trigger('user:session:expired');
                } else if (toaster) {
                    var link = '';
                    if (response.errorCode === 'TitleCannotBeEarlyReturned') {
                        link = `<a href="${window.supportUrl(
                            window.OverDrive._locale,
                            window.OverDrive.supportArticles.howToReturn
                        )}" class="bold">${window.html(
                            'learnAboutReturning'
                        )}</a>`;
                    } else if (
                        response.errorCode ===
                        'CannotEarlyReturnWhenFulfilledOnKindle'
                    ) {
                        link = `<a href="${window.url(
                            window.routes.mediaDownload,
                            {
                                format: 'ebook-kindle',
                                mediaId: mediaId,
                            }
                        )}" class="bold" target="_blank" rel="noreferrer">${window.html(
                            'amazonsSite'
                        )}</a>`;
                    }

                    toaster.push(
                        '<strong>' +
                            window.html('genericError') +
                            '</strong> ' +
                            window.text('error.' + response.errorCode, {
                                link: link,
                            }),
                        'error'
                    );
                }

                $('.reveal-modal').foundation('reveal', 'close');
            },
        });
    },

    //Ajax request for placing a hold
    placeHold: function (mediaId, email, elements) {
        elements.each(function () {
            if (!$(this).hasClass('primary-action')) {
                $(this).trigger('holdRequest', $(this));
            }
        });

        $.ajax({
            url: window.url(window.routes.mediaPlaceHold),
            type: 'POST',
            data: {
                email: email,
                mediaId: mediaId,
                isMobile: bowser.mobile || bowser.tablet,
                languageCode:
                    window.patronSettings['language-selection'] ||
                    window.Cookies.get('language-selection') ||
                    $('html').attr('lang') ||
                    'en',
            },
            success: function (data, textStatus, xhr) {
                elements.each(function () {
                    // If status is 303: hold failed because title has already been placed on hold.
                    if (xhr.status === 303) {
                        var message = { responseText: data };
                        $(this).trigger('holdFailure', [$(this), message]);
                    } else {
                        $(this).trigger('holdSuccess', [$(this), data]);
                    }
                });
            },
            error: function (response) {
                elements.each(function () {
                    $(this).trigger('holdFailure', [$(this), response]);
                });
            },
        });
    },

    //Ajax request for retrieving a single hold
    getHold: function (mediaId) {
        return $.ajax({
            url: window.url(window.routes.getSingleHold, {
                mediaId: parseInt(mediaId),
            }),
            type: 'GET',
        });
    },

    //Ajax request for retrieving a single media item
    getMediaItem: function (mediaId) {
        return $.ajax({
            url: window.url(window.routes.mediaInfoId, {
                mediaId: parseInt(mediaId),
            }),
            type: 'GET',
        });
    },

    removeHold: function (elem, mediaId) {
        $.ajax({
            url: window.url(window.routes.mediaRemoveHold, {
                mediaId: mediaId,
                forward: 'account',
            }),
            type: 'DELETE',
            success: function () {
                window.location.reload();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                var response = JSON.parse(jqXHR.responseText);
                if (toaster) {
                    toaster.push(
                        '<strong>' +
                            window.html('genericError') +
                            '</strong> ' +
                            window.html('error.' + response.errorCode),
                        'error'
                    );
                }
                $('.modal').foundation('reveal', 'close');
            },
        });
    },

    //Ajax request for adding to wish list
    wishListAdd: function (mediaId, elements) {
        $(elements).each(function () {
            $(this).trigger('wishListAddRequest');
        });

        $.ajax({
            url: window.url(window.routes.accountWishlistAdd, {
                mediaId: mediaId,
            }),
            type: 'PUT',
            success: function (data) {
                $(elements).each(function () {
                    $(this).trigger('wishListAddSuccess', data);
                });
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $(elements).each(function () {
                    $(this).trigger(
                        'wishListAddFailure',
                        jqXHR,
                        textStatus,
                        errorThrown
                    );
                });
            },
        });
    },

    //Ajax request for removing from wish list
    wishListRemove: function (mediaId, elements, reload) {
        $(elements).each(function () {
            $(this).trigger('wishListRemoveRequest', elements);
        });

        var self = this;

        $.ajax({
            url: window.url(window.routes.accountWishlistRemove, {
                mediaId: mediaId,
            }),
            type: 'DELETE',
            success: function (data) {
                if (reload) {
                    // Reload wishlist account page
                    window.location.reload();
                } else {
                    $(elements).each(function () {
                        $(this).trigger('wishListRemoveSuccess', data);
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                var response = JSON.parse(jqXHR.responseText);
                if (response.sessionExpired) {
                    self.bus.trigger('user:session:expired');
                } else {
                    $(elements).each(function () {
                        $(this).trigger(
                            'wishListRemoveFailure',
                            jqXHR,
                            textStatus,
                            errorThrown
                        );
                    });
                }
            },
        });
    },

    getMediaStatus: function (items) {
        if (
            items &&
            items.length &&
            window.OverDrive.isAuthenticated &&
            window.OverDrive.hasLinkedCards
        ) {
            $('body').trigger('mediaStatusRequest');

            $.ajax({
                url: window.url(window.routes.mediaInfo),
                type: 'POST',
                contentType: 'application/json',
                timeout: 10000,
                data: JSON.stringify({ titleIds: items }),
                success: function (data) {
                    $('body').trigger('mediaStatusSuccess', data);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $('body').trigger(
                        'mediaStatusFailure',
                        jqXHR,
                        textStatus,
                        errorThrown,
                        items
                    );
                },
            });
        }
    },

    getLendingPeriods: function (mediaId, elements, callback) {
        if (mediaId == '' || mediaId == null) {
            return;
        }

        $(elements).each(function () {
            $(this).trigger('lendingPeriodsRequest');
        });

        var self = this;
        $.ajax({
            url: window.url(window.routes.patronLendingPeriod, {
                mediaId: mediaId,
            }),
            type: 'GET',
            timeout: 0,
            success: function (data) {
                $(elements).each(function () {
                    $(this).trigger('lendingPeriodsRetrieved', { items: data });
                });

                if (callback != null) {
                    callback(data);
                }
            },

            error: function (data) {
                // Using new event bus for future-proofing
                self.bus.trigger('lendingPeriods:fetch:fail');
            },
        });
    },

    getAvailability: function (mediaId) {
        return $.ajax({
            url: window.url(window.routes.mediaAvailabilityId, { id: mediaId }),
            type: 'GET',
            timeout: 0,
        });
    },

    getStarRating: function (mediaId, elements) {
        $.ajax({
            url: window.url(window.routes.patronMediaStarRatings, {
                titleId: mediaId,
            }),
            type: 'GET',
            timeout: 0,
            success: function (data) {
                $(elements).each(function () {
                    $(this).trigger('starRatingsRetrieved', data);
                });
            },

            error: function (data) {
                $(this).trigger('starRatingsGetError', data);
            },
        });
    },

    setStarRating: function (mediaId, rating) {
        var self = this;
        $.ajax({
            url: window.url(window.routes.patronMediaStarRatings, {
                titleId: mediaId,
            }),
            type: 'PUT',
            data: { rating: rating },
            timeout: 0,

            error: function (data) {
                $(this).trigger('starRatingsSetError', data);

                // Using new event bus for future-proofing
                self.bus.trigger('rating:set:fail');
            },
        });
    },

    deleteStarRating: function (mediaId) {
        var self = this;
        $.ajax({
            url: window.url(window.routes.patronMediaStarRatings, {
                titleId: mediaId,
            }),
            type: 'delete',
            timeout: 0,

            error: function (data) {
                $(this).trigger('starRatingsSetError', data);

                // Using new event bus for future-proofing
                self.bus.trigger('rating:delete:fail');
            },
        });
    },

    getPatronEmail: function (callback) {
        return $.ajax({
            url: window.url(window.routes.patronEmail),
            type: 'GET',
            timeout: 0,
        });
    },

    borrowSuccessToast: function (expires, mediaTypeName) {
        var exp = window.html('date-full', { d: expires });
        var toastCopyKey = 'toasts.borrowSuccessHtml';
        if (exp.match(/invalid/i)) {
            toastCopyKey = 'toasts.borrowSuccessNoExpHtml';
        }
        var linkUrl = window.url(window.routes.accountLoans);
        var loans = window.html('loans');
        if (toaster) {
            toaster.push(
                window.html(toastCopyKey, {
                    mediaTypeName: mediaTypeName,
                    exp: exp,
                    link:
                        '<a class="bold" href="' +
                        linkUrl +
                        '" tabindex="0">' +
                        loans +
                        '</a>',
                }),
                'userAction'
            );
        }
    },
};
