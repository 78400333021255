
export default class TrackingKindleFulfillment {
    get isDebug() {
        return _(window.ga).isEmpty();
    }

    get debugInstance() {
        return {
            push: ({ event, content }) => {
                console.log(`ga:log("${event}"):`, content); // eslint-disable-line no-console
            }
        };
    }

    constructor(bus) {
        this.bus = bus;

        this._dataLayer = window.dataLayer || this.debugInstance;

        this.bus
            .on('preferences:global:update:success',({context, source}) => this.pushEvent({context, source}));
    }

    pushEvent({context, source}) {
        if (_(Object.keys(context.changed)).contains('kindleFulfillment')) {
            this._dataLayer.push({
                event: 'kindleFulfillment',
                content: {
                    source, // settings_page, results_filter, or content_fulfillment
                    value: context.changed.kindleFulfillment
                }
            });
        }
    }
}
