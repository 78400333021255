var querystring = require('querystring');

var supportUrl = function(preferredLang, article, params) {
    var language = preferredLang || 'en';
    var url = 'https://help.overdrive.com';
    if (language === 'fr' || language === 'fr-CA') {
        url += '/customer/fr/portal/articles/';
        if (!!article) {
            url += article;
        }
    } else if (!!article) {
        url += '/customer/portal/articles/' + article;
    }
    if (!!params) {
        var queryString = querystring.stringify(params);
        if (queryString) {
            url += '?' + queryString;
        }
    }
    return url;
};

module.exports = supportUrl;
