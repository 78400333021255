import onDocumentReady from './document_ready';
import onWindowPageshow from './window_pageshow';

// ****** Bindings To Native Events On Window ******
$(window).bind('pageshow', onWindowPageshow);

// ****** Bindings To Native Events On Document ******
$(document).ready(onDocumentReady);

if (window.OverDrive.features.isEnabled('ajax-error-reporting')) {
    $.ajaxPrefilter((_, __, jqXhr) => {
        jqXhr._callStack = new Error().stack;
    });

    $(document).ajaxError((event, jqXhr, settings) => {
        try {
            // No need to report if the ajaxError endpoint is also failing (will calls an infinite loop)
            if (settings.url === '/api/reporting/ajaxError') {
                return;
            }

            $.post(window.url(window.routes.api.reporting.ajaxError), {
                callStack: jqXhr._callStack,
                eventType: event.type,
                pageHref: window.location.href,
                requestDataType: settings.dataType,
                requestMethod: settings.type,
                requestUrl: settings.url,
                responseStatusCode: jqXhr.status,
                responseText: jqXhr.responseText
            });
        } catch (err) { }
    });
}
