import 'es5-shim';
import 'babel-polyfill';

import _onlyForBootstrapping     from '../global_bootstrapping.js';
import _onlyForBindings          from '../global_bindings.js';

import Logger                    from '../util/logging/logger';
import EventBridge               from '../components/site/EventBridge';
import Card                      from '../models/Card';
import GlobalPreferences         from '../models/GlobalPreferences';
import HighlightedFeatureState   from '../../../models/HighlightedFeatureState';
import FeatureHighlightManager   from '../util/FeatureHighlightManager';
import CookieConfig              from '../../../lib/cookieConfig';

window.Logger = Logger;

void (_onlyForBootstrapping, _onlyForBindings);

window.globalPreferences = new GlobalPreferences({}, { bus: window.globalEvents });
window.card = new Card();

void (new EventBridge({
    bus               : window.globalEvents,
    card              : window.card,
    patronSettings    : window.patronSettings,
    featureManager    : window.featureManager,
    globalPreferences : window.globalPreferences
}));

window.OverDrive.highlightedFeatureState = new HighlightedFeatureState(Cookies.get(CookieConfig.visitedFeatures.name) || '0', window.featureManager, window.OverDrive.hasPartnerLibraries);
window.OverDrive.featureHighlightManager = new FeatureHighlightManager(window.OverDrive.highlightedFeatureState);
