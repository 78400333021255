import template from './RemoveSuspendHoldModalTemplate.html';
import {Modal} from '../common/modal';
const { html } = window;

export default class SuspendHoldModal extends Backbone.View {
    constructor(model, isNotification = false){
        super(...arguments);
        this.model = model;
        this.isNotification = isNotification;
        this.render();
    }

    render(){
        let modalContents = {
            title: html('removeSuspensionModal.heading'),
            autoShow: false,
            classes: ['small', 'remove-suspension-modal'],
            content: template(this.model.get('title'), this.model.get("ownedCopies") === 0 ? html("holdRedelivery.removeSuspensionLapsedMA") : html("holdRedelivery.removeSuspension"))
        };

        this.modal = new Modal(modalContents);
        this.attachEvents(this.modal.$el);
        this.modal.show();
    }
    
    attachEvents(modal){
        modal.find("#remove-suspend").on('click', () => {
            featureManager.isEnabled("holdRedelivery") ? window.removeSuspension(this.model.get('id')) : window.removeSuspendedHold(this.model.get('id'));
            window.globalEvents.trigger("title:suspend:cancelled", { titleModel: this.model, context: { isNotification: this.isNotification }});
        });

        modal.find(".cancel").on('click', () => {           
            modal.find('.close-reveal-modal').click();
        });
    }
}
