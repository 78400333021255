import { Modal } from '../common/modal';
import template from './tlsWarningModal.html';
import keys from '../../util/keycodes';

export default class TlsWarningModal extends Modal {
    constructor() {
        let content = template();
        let modalContents = {
            classes: ['small', 'tlsWarningModal'],
            title: window.html('tlsWarning.title'),
            hiddenTitle: true,
            content
        };

        super(modalContents);
    }

    close() {
        this.setCookie();
        super.close();
    }

    setCookie() {
        Cookies.remove('tls-final-warning', {path: '/'});
        Cookies.set('tls-final-warning', 'dismissed', {path: '/', expires: 14});
    }
}