/*
 * Append copy to toast explaining cookies to the user
 */
const getCookieExplanation = function () {
    const privacyPolicy = window.html('privacyPolicyClickHere');
    const exitSite = window.html('exitSite');
    const cookieToast = window.html('cookieToast', {
        link: `<a href="http://company.overdrive.com/privacy-policy/" target="_blank" rel="noreferrer">${privacyPolicy}</a>`,
    });
    const cookieToastExit = window.html('cookieToastExit', {
        link: `<a href="https://www.overdrive.com/">${exitSite}</a>`,
    });
    $('#eu-notify-toast').html(
        // eslint-disable-line prefer-template
        `${cookieToast}
         <br/><br/>
         <strong>
            ${cookieToastExit}
         </strong>`
    );
};

export default getCookieExplanation;
