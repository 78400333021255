// Remove all of these ASAP
// TODO: The number of accesses to ambient function bits seems to be mounting, trying to decide if there is a better way to reference these
// Idea! - Make a class that is an aggregate of all the .locals functions and expose it such that the front and back end can import it

// Imports for Global Namespace Purposes
import { removeQueryParam } from './util/query.js';
import { url, routes } from './util/routes.js';
import { html, text } from '../../lib/i18n/browser';
import stripNoLinkedCardQuery from './util/url/stripNoLinkedCardQuery.js';
import getCookieExplanation from './util/toast/getCookieExplanation.js';
import rfc3986EncodeURIComponent from './util/encoding/rfc3986EncodeURIComponent.js';
import scrollCarousel from './util/carousel/scrollCarousel.js';
import hideCarouselArrowsForEl from './util/carousel/hideCarouselArrows.js';
import hideArrowsOnEndOfScrollForEl from './util/carousel/hideArrowsOnEndOfScroll.js';
import arrowTabAccountMenu from './util/account/arrowTabAccountMenu.js';
import arrowTabMenu from './util/compatibility/arrowTabMenu.js';
import onunload from './util/compatibility/onunload.js';
import getQueryParams from './util/url/getQueryParams.js';
import addDays from './util/dates/addDays.js';
import replaceQueryParam from './util/url/replaceQueryParam.js';
import addQueryParam from './util/url/addQueryParam.js';
import componentToHex from './util/colors/componentToHex.js';
import rgbToHex from './util/colors/rgbToHex.js';
import processInstantForwardActions from './util/title-actions/processInstantForwardActions.js';
import setUserStarRating from './util/title-actions/setUserStarRating.js';
import fillStars from './util/title-actions/fillStars.js';
import selectStarRating from './util/title-actions/selectStarRating.js';
import openHoldConflictModal from './util/title-actions/openHoldConflictModal.js';
import handleForwardActionHold from './util/title-actions/handleForwardActionHold.js';
import noCard from './util/noCard.js';
import findFormatId from './util/findFormatId.js';
import toaster from './components/toaster/app.js';
import Title from './components/media/models/Title.js';
import buildFeatureManager from '../../lib/featureManagerBuilder';
import supportUrl from '../../lib/supportUrl';
import AppPromoManager from '../../lib/appPromoManager';
import './polyfills/custom-event';

// Attaching Functions to Global Namespace
window.featureManager = buildFeatureManager(
    OverDrive.libraryConfigurations,
    OverDrive.tenant,
    OverDrive.allFeatures,
    OverDrive.features
);
window.Title = Title;
window.removeQueryParam = removeQueryParam;
window.routes = routes;
window.url = url;
window.supportUrl = supportUrl;
window.html = html;
window.text = text;
window.stripNoLinkedCardQuery = stripNoLinkedCardQuery;
window.getCookieExplanation = getCookieExplanation;
window.rfc3986EncodeURIComponent = rfc3986EncodeURIComponent;
window.scrollCarousel = scrollCarousel;
window.hideCarouselArrows = hideCarouselArrowsForEl;
window.hideArrowsOnEndOfScroll = hideArrowsOnEndOfScrollForEl;
window.arrowTabAccountMenu = arrowTabAccountMenu;
window.arrowTabMenu = arrowTabMenu;
window.onunload = onunload;
window.getQueryParams = getQueryParams;
window.addDays = addDays;
window.replaceQueryParam = replaceQueryParam;
window.addQueryParam = addQueryParam;
window.componentToHex = componentToHex;
window.rgbToHex = rgbToHex;
window.processInstantForwardActions = processInstantForwardActions;
window.setUserStarRating = setUserStarRating;
window.fillStars = fillStars;
window.selectStarRating = selectStarRating;
window.openHoldConflictModal = openHoldConflictModal;
window.handleForwardActionHold = handleForwardActionHold;
window.noCard = noCard;
window.findFormatId = findFormatId;
window.toaster = window.OverDrive.isTolinoDevice ? undefined : toaster;
window.appPromoManager = AppPromoManager.createClient(window);

/**
 * Global event handler
 * Used for consuming component level events that are better
 * handled at the global level
 * Do not go attaching events to this all willy nilly!
 */
window.globalEvents = _.clone(window.Backbone.Events);

document.dispatchEvent(new CustomEvent('global-bootstrapping-ready'));
