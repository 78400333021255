const Backbone  = window.Backbone;

import ConfirmDialog from '../../common/confirmDialog';
import { toStaticUrl } from '../../../../../lib/assetMapper';

const fs = require('fs');
const path = require('path');
const NookEulaTemplate = fs.readFileSync(path.join(__dirname, '../templates/NookEula.html'), 'utf8');

const NookEulaModal = Backbone.View.extend({
    template: _.template(NookEulaTemplate),

    initialize(bus, titleModel) {
        this.bus = bus;

        const viewData = {
            bnLogoUrl: toStaticUrl('img/bn-logo.jpg'),
            html: window.html,
            text: window.text
        };

        this.$el.html(this.template(viewData));

        let modalContents = {
            autoShow: false,
            submitText: window.html('nookEULAModal.acceptToContinue'),
            title: window.html('sendToNook'),
            classes: ['small', 'NookEulaModal'],
            content: this.$el
        };

        this.modal = new ConfirmDialog(modalContents);

        this.modal.show();

        this.modal.on('submit', () => this.bus.trigger('title:nook:eula-accepted', { title: titleModel }));

        this.listenTo(this.bus, 'title:nook:end', () => { this.modal.close(); });
    }
});

module.exports = NookEulaModal;
