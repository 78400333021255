const { html } = window;

export default({heading, src, isLuckyDay}) => `
    <div id="now-unavailable-modal-title" class="title-text">
        <div class="now-unavailable-text">${heading}
            ${isLuckyDay ?
                `<div style="display: inline-block; position: relative">
                    <div style="display: inline-block; float: right">
                        <a role="button" id="luckyDayTooltip" class="help-tip secondary-background" style="float: right"></a>
                    </div>
                </div>`
            : ''}
        </div>
    </div>

    <div class="img-container">
        <img data-id="cover-image" src="${src}" alt="${html('coverImage')}"/>
    </div>

    <div class="medium-8 medium-centered columns button-container">
        <span class="TitleAction" />
        <button type="button" class="button js-cancel cancel radius u-allCaps outline gray">${html('cancel')}</button>
    </div>
`;
