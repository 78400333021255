const hideCarouselArrowsForEl = function(el) {
    const media = $(el).children('.js-carouselItem');
    const mediaAmount = media.length;
    const mediaWidth = media[0].getBoundingClientRect().width;
    const elemWidth = $(el)[0].getBoundingClientRect().width;

    $(el).parent().siblings('.js-carouselArrowRight').css('visibility', 'visible');

    if ((mediaAmount * mediaWidth) <= elemWidth) {
        $(el).parent().siblings('.js-carouselArrowLeft').css('visibility', 'hidden');
        $(el).parent().siblings('.js-carouselArrowRight').css('visibility', 'hidden');
    }
};

export default hideCarouselArrowsForEl;
