import { toStaticUrl } from '../../../../lib/assetMapper';
import { libbyAppGroup } from '../libby-app-group/libby-app-group.html';

const html = (key) => window.html(key);

const toast = ({
    columns = 10,
    id,
    message,
    messageId,
    showDismissButton,
    toasterType,
}) => `
    <div class="Toaster-toast js-toast ${toasterType} animated fadeIn" role="alert">
        <div class="row">
            <div class="large-${columns} large-centered medium-${columns} medium-centered columns">
                <div class="Toaster-bread">
                    <div role="alert" 
                        class="Toaster-message js-toastMessage Toaster-messageContent" 
                        tabindex="0" 
                        id="${id}" 
                        data-message-id="${messageId}">
                        ${message}
                    </div>
                    
                    ${
                        showDismissButton
                            ? `
                        <button 
                            aria-label="${html('toasts.dismissAria')}"
                            class="js-closeToast Toaster-close">
                            &times;
                        </button>
                    `
                            : ''
                    }
                </div>
            </div>
        </div>
    </div>
`;

export const standardToast = ({ id, message, messageId, toasterType }) =>
    toast({ id, message, messageId, showDismissButton: true, toasterType });
