import HoldInformationTemplate              from '../templates/HoldInformation.html';

const { html, url, routes } = window;

export default class HoldInformation extends Backbone.View {
    get expirationWarning() {
        const isExpiredContent = this.model.get('isOwned') && !this.model.get('ownedCopies');
        if (isExpiredContent) {
            if (this.type === 'successPosition') {
                return `${html('holdsRatioHelpModal.noCopiesOnPlaceHold')} <a class='HoldSuccessModal-link' href='${url(routes.accountHolds)}'>${html('holdSuccessModal.manageHolds')}</a>`;
            } else if (window.featureManager.isEnabled('betterPatronState') ? this.model.get('isOnHold') : this.model.get('titleState') === 'onHold') {
                return html('holdsRatioHelpModal.noCopiesTextHeld');
            }
            return html('holdsRatioHelpModal.noCopiesText');
        }
        return '';
    }

    get description() {
        const holdsRatio = this.model.get('holdsRatio');
        const holdListPosition = this.model.get('holdListPosition') || 0;

        if (this.model.get('isPreReleaseTitle') && holdListPosition === 0) {
            return html('preReleaseModal.borrowUponRelease');
        }

        switch (this.type) {
            case 'ratio':
                if (holdsRatio) {
                    return holdsRatio >= 1
                        ? html('holdsRatioHelpModal.listPositionHtml', { holdsRatio: this.model.get('holdsRatio') })
                        : html('holdsRatioHelpModal.nextAvailable');
                }
                return '';
            case 'basicPosition':
            case 'successPosition':
            case 'basic':
            default:
                return '';
        }
    }

    get details() {
        const holdsCount = this.model.get('holdsCount') || 0;
        const ownedCopies = this.model.get('ownedCopies') || 0;
        const holdListPosition = this.model.get('holdListPosition') || 0;
        switch (this.type) {
            case 'basicPosition':
            case 'successPosition':
                return [
                    html('holdsRatioHelpModal.yourHoldsPositionHtml', { holdListPosition, holdsCount }),
                    html('holdsRatioHelpModal.libraryCopies', { ownedCopies })
                ];
            case 'ratio':
            case 'basic':
            default:
                return [
                    html('holdsRatioHelpModal.totalHoldsHtml', { holdsCount }),
                    html('holdsRatioHelpModal.libraryCopies', { ownedCopies })
                ];
        }
    }

    get note() {
        return this.model.get('isFastlane') ? html('advantageFastPassHtml') : '';
    }

    constructor({ type }) {
        super(...arguments);
        this.el = this.el || document.createElement('div');
        this.el.className = `${this.el.className} HoldInformation`;
        this.type = type || 'basic';
    }

    render() {
        const view = HoldInformationTemplate({
            expirationWarning: this.expirationWarning,
            description: this.description,
            details: this.details,
            note: this.note
        });
        this.$el.empty().append(view);

        return this;
    }
}
