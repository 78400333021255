const { html } = window;

export default (noThanksTargetUrl) => `
<div class='info-container'>
    <p>
        ${html('kindleFulfillment.setPrefModal.offer')}
        ${html('kindleFulfillment.setPrefModal.adjustInfoHtml', {
            settingsLink: html('settings'),
        })}
    </p>
</div>
<div class='button-container'>
    ${
        noThanksTargetUrl
            ? `<a href=${noThanksTargetUrl} target='_blank' rel='noreferrer' class='deny-button button margin-small radius outline gray u-allCaps show-desktop'>${html(
                  'noThanks'
              )}</a>`
            : `<button type='button' class='deny-button button margin-small radius outline gray u-allCaps show-desktop'>${html(
                  'noThanks'
              )}</button>`
    }

    <button type='button' class='confirm-button button margin-small radius secondary contrast u-allCaps'>${html(
        'kindleFulfillment.setPrefModal.setPref'
    )}</button>

    ${
        noThanksTargetUrl
            ? `<a href=${noThanksTargetUrl} target='_blank' rel='noreferrer' class='deny-button button margin-small radius outline gray u-allCaps show-mobile'>${html(
                  'noThanks'
              )}</a>`
            : `<button type='button' class='deny-button button margin-small radius outline gray u-allCaps show-mobile'>${html(
                  'noThanks'
              )}</button>`
    }
</div>
`;
