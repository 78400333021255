const { _ } = window;

const logger = {
    log(level, message, fields) {
        const body = {
            level: level || 'info',
            message: message || 'No message specified',
            fields: {
                path: window.location.pathname
            }
        };

        if (level === 'error') {
            body.fields.stackTrace = new Error().stack;
        }

        if (!_(fields).isEmpty()) {
            Object.assign(body.fields, fields);
        }

        // TODO: remove LHTNG-2598 test code
        console.log(body);

        const requestData = JSON.stringify(body);

        let request = new XMLHttpRequest();
        request.open('POST', '/log', true);
        request.setRequestHeader('Content-Type', 'application/json');
        request.send(requestData);
    }
};

export default Object.freeze(logger);
