import LanguageSelectionView from './views/language_selection';
import PreferencesModel from '../../models/preferences';

const createComponent = ({el, footerEl, mobileEl, mobileFooterEl}) => {
    const preferencesModel = new PreferencesModel();
    preferencesModel.parseBootstrappedData(OverDrive);
    const selectionView = new LanguageSelectionView({el, preferencesModel}); // eslint-disable-line no-unused-vars
    const footerView = new LanguageSelectionView({el: footerEl, preferencesModel}); // eslint-disable-line no-unused-vars
    const mobileView = new LanguageSelectionView({el: mobileEl, preferencesModel}); // eslint-disable-line no-unused-vars
    const mobileFooterView = new LanguageSelectionView({el: mobileFooterEl, preferencesModel}); // eslint-disable-line no-unused-vars
};

export default createComponent;
