import { Modal } from './modal';

class ConfirmDialog extends Modal {
    constructor(options) {
        super(options);

        this.$el.append($(this.buttonTemplate));

        this.$buttonSubmit  = this.$el.find('.button.submit');
        this.$buttonCancel  = this.$el.find('.button.cancel');

        this.bindEvents();

        this._render();
    }

    // We dont actually want to use the Foundation modal events since they get weird fast...
    // lets use our own so consumers dont have to know its foundation
    bindEvents() {
        this.$buttonSubmit.on('click', () => {
            this.trigger('submit', this);
            this.trigger('close');
        });

        this.$buttonCancel.on('click', (e) => {
            this.trigger('cancel', this);
            this.trigger('close');
        }).on('keydown', (e) => e.stopPropagation);
    }

    get buttonTemplate() {
        return $(`
            <div class="button-container ${this.buttonAlign || 'right'}">
                <button type="button" class="button submit radius margin-small u-allCaps secondary">${this.submitText}</button>
                <button type="button" class="button cancel radius margin-small u-allCaps outline gray">${this.cancelText}</button>
            </div>
        `);
    }
}

export default ConfirmDialog;
