import NookModal from '../../components/media/views/NookEulaModal';

class NookActions {
    constructor(bus) {
        this.bus = bus;

        this.bus
            .on('title:nook:success',       (data) => this.nookSuccess(data))
            .on('title:nook:fail',          (data) => this.nookFail(data))
            .on('title:nook:request-eula',  (data) => this._openNookEulaModal(data.titleModel));
    }

    nookSuccess(data) {
        window.location.href = window.url(window.routes.mediaDownload, {format: 'periodicals-nook', mediaId: data.titleModel.id});
    }

    nookFail(data) {
        const response = JSON.parse(data.jqXHR.responseText);

        if (response.errorCode === 'PatronCardFailedAuthentication') {
            this.bus.trigger('user:card:invalid');
        }
        else if (response.errorCode === 'PatronExceededPeriodicalsCheckoutLimit') {
            const href = window.url(window.routes.accountLoans);
            const linkText = window.html('loans');
            const link = `<a class="bold" href="${href}" tabindex="0">${linkText}</a>`;
            if (toaster) toaster.push(`<strong>${window.html('genericError')}</strong> ${window.text(`error.${response.errorCode}`, {link})}`, 'error');
        }
        else if (response.sessionExpired) {
            this.bus.trigger('user:session:expired');
        }
    }

    _openNookEulaModal(titleModel) {
        new NookModal(this.bus, titleModel);
    }
}

export default NookActions;
