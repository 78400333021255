import CollectionView from '../../../views/collection';
import AutocompleteView from './autocompletion';
const fs = require('fs');
const path = require('path');
const template = fs.readFileSync(path.join(__dirname, '/../templates/autocompletions.html'), 'utf8');

const Autocompletions = CollectionView.extend({

    template: _.template(template),

    initialize({el, query, collection, autocompleteBus, options = {}}) {
        this.el = el;
        this.collection = collection;
        this.query = query;
        this.autocompleteBus = autocompleteBus;
        this.options = options;
        this.$advancedSearch = $('.js-desktopAdvancedSearch');
        this.$iconClear = $('.icon-clear');
        this.listenTo(this.collection, 'reset', this.emptyElAndClearActive);
        this.listenTo(this.collection, 'smartsync', this.smartRender);
        this.listenTo(autocompleteBus, 'quicksearch:blur', this.emptyElAndClearActive);
        this.listenTo(autocompleteBus, 'quicksearch:focus', this.smartRender);
        this.listenTo(autocompleteBus, 'quicksearch:highlightItem', this.highlightItem);
    },

    emptyElAndClearActive() {
        if (this.options.desktop) {
            this.$advancedSearch.show();
        }
        // Hack until all the logic is moved into views
        if (this.options.mobile) {
            this.resetMobileSearchBar();
        }
        this.collection.invoke('set', {active: false});
        this.emptyEl();
        this.highlightedItemIndex = null;
    },

    resetMobileSearchBar() {
        // Mess of jquery until navbar can be systematically refactored
        $('.account-dropdown-mobile a').removeClass('hideMeImportant');
        $('.toggle-topbar.search').addClass('invisible');
        $('.toggle-topbar.menu-icon').removeClass('invisible');
        $('.toggle-topbar.browse').removeClass('invisible');
    },

    highlightItem(direction) {
        if (this.collection.models.length === 0) {
            return;
        }
        if (this.highlightedItemIndex == null) {
            this.highlightedItemIndex = direction > 0 ? 0 : direction;
        } else {
            this.highlightedItemIndex += direction;
        }
        if (this.highlightedItemIndex < 0) {
            this.highlightedItemIndex = this.views.length - 1;
        } else if (this.highlightedItemIndex >= this.collection.models.length) {
            this.highlightedItemIndex = 0;
        }
        const model = this.collection.models[this.highlightedItemIndex];
        this.collection.invoke('set', {active: false});
        model.set('active', true);
        this.autocompleteBus.trigger('quicksearch:fill', model.get('text'));
    },

    appendItems() {
        const className = '.js-autocompletionsList';
        this.$(className).append(this.temp);
    },

    smartRender() {
        this.highlightedItemIndex = null;
        if (this.query.get('text').length && this.collection.models.length) {
            this.render();
            this.$advancedSearch.hide();
        } else {
            this.emptyEl();
        }
    },

    createItemView({model}) {
        return new AutocompleteView({
            model,
            query: this.query
        });
    }

});

export default Autocompletions;
