import ImportRatedCompleteModal from '../../components/account_history/views/ImportFromRatedCompleteModal';

export default class ReadingHistoryActions {
    constructor(bus) {
        this.bus = bus;

        this.bus
            .on('history:importFromRated:success',  (...params) => this.importFromRatingsSuccess(...params))
            .on('title:history:fail',               (...params) => this.historyFailure(...params));

    }

    importFromRatingsSuccess(data) {
        new ImportRatedCompleteModal(data.importedTitleCount, data.patronHasRatedTitles);
    }

    historyFailure({ titleModel }, jqXHR, textStatus, errorThrown) {
        let response = JSON.parse(jqXHR.responseText || '{}');

        if (response.sessionExpired) {
            this.bus.trigger('user:session:expired');
        } else if (toaster) {
            toaster.push(window.html('error.defaultErrorHtml'), 'error');
        }
    }
}
