
let bitFlag = require('../lib/utility/bitFlag.js');

module.exports = class HighlightedFeatureState {

    constructor(flags, featureManager, hasPartnerLibraries) {
        this.featureManager = featureManager;
        this.hasPartnerLibraries = hasPartnerLibraries;

        if (flags){
            this.flags = flags;
        }
        else {
            this.flags = 0;
        }
    }

    get features() {
        return {
            partnerLibrariesMenu: {flagBit: 0, active: this.featureManager.isEnabled('reciprocalLending') && this.hasPartnerLibraries}
        };
    }

    wasVisited(featureName){
        if (!this.features.hasOwnProperty(featureName)){
            throw new Error(`'${featureName}' is not a known feature.`);
        }

        return bitFlag.bitDecode(this.features[featureName].flagBit, this.flags);
    }

    visit(featureName){
        if (!this.features.hasOwnProperty(featureName)){
            throw new Error(`'${featureName}' is not a known feature.`);
        }
        this.flags = bitFlag.bitEncode(true, this.features[featureName].flagBit, this.flags);
    }

    visitAllActiveFeatures(){
        let activeFeatureNames = Object.getOwnPropertyNames(this.features).filter((featureName => this.features[featureName].active));
        activeFeatureNames.forEach((featureName) => this.visit(featureName));
    }

    get unvisitedFeaturePresent() {
        let activeFeatureNames = Object.getOwnPropertyNames(this.features).filter((featureName => this.features[featureName].active));
        return activeFeatureNames.length !== 0 && activeFeatureNames.some((featureName) => !this.wasVisited(featureName));
    }
};
