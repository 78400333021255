import getQueryParams from './getQueryParams';
/*
 * Replace the provided query parameter with a different one and navigate to the new URL
 * @param {string} name Name of the query parameter to replace
 * @param {string} value The new value of the query parameter to replace
 */
const replaceQueryParam = function(name, value) {
    const path = window.location.pathname;
    let queryString = window.location.search.replace(/&page=\d+/g, '&page=1').replace(/\?page=\d+/g, '?page=1');
    const query = getQueryParams(queryString);

    if (queryString) {
        if (query[name]) {
            queryString = queryString.replace(`${name}=${query[name]}`, `${name}=${value}`);
        } else {
            queryString += `&${name}=${value}`;
        }
    } else {
        queryString = `?${name}=${value}`;
    }

    window.location.href = path + queryString;
};

export default replaceQueryParam;
