/*
 * Strip the noLinkedCard parameter from the url if the user has linked cards
 * @param {array} qs
 * @returns {*}
 */
const stripNoLinkedCardQuery = function(qs) {
    const arr = qs.split('&');

    if (arr.length === 1 && arr[0].indexOf('no-linked-card') < 0) {
        return qs;
    }

    let newQS = '';
    for (let x = 0; x < arr.length; x++) {
        if (arr[x].indexOf('no-linked-card') > -1) {
            arr[x] = '';
        } else if (x > 0 && x < arr.length) {
            newQS += '&';
        }
        newQS += arr[x];
    }

    return newQS;
};

export default stripNoLinkedCardQuery;
