import '../../util/Promise';
import LendingPeriodCollection  from './LendingPeriodCollection';

const { Logger, url, routes } = window;

export default class PatronTitleLendingPeriods extends Backbone.Model {
    defaults() {
        return {
            mediaId: 0,
            lendingPeriods: new LendingPeriodCollection()
        };
    }

    fetchData() {
        return Promise.ajax({
            method: 'GET',
            url: url(routes.patronLendingPeriod, {mediaId: this.get('mediaId')}),
            timeout: 10000
        })
        // Set our model but pass the data along in case the caller wants to use it for something else
            .then((response) => {
                this.get('lendingPeriods').set(response);
                return response;
            })
            // Catch errors, log them, and then let the call fail
            .catch((error) => {
                Logger.log('error', 'Failure fetching patron lending periods for title', {mediaId: this.get('mediaId'), error});
                throw error;
            });
    }
}
