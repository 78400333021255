const { html, text } = window;

export default ({ title, type, coverSrc, id, isPreReleaseTitle, prereleaseDate, ownedCopies, libraryName, isRequestAgain }) => `
<div class="large-12 columns no-padding-left">
    <h2>${ title }</h2>
    <span class="title-format-badge"><i class="icon-${ type.id }"></i> <span>${ html(type.id) }</span></span>
</div>

<div class="medium-4 columns no-padding-left">
    <img data-id="cover-image" src="${ coverSrc }" alt="${ html('coverImage') }"/>
</div>

<form id="hold-dynamic-form-${ id }" data-abide="ajax" data-media-id="${ id }">
    <div class="medium-8 columns">
        ${(!isPreReleaseTitle ? '' :
            `<p class="header-xxxsmall prerelease text-red modal">${ text('prereleaseLabel', { prereleaseDate }) }</p>`
        )}
        <p id="hold-modal-label" class="js-dialog-label">
            ${(ownedCopies === 0
                ? html('holdsRatioHelpModal.noCopiesText')
                : isRequestAgain ?
                    html('requestAgainModal.requestAgainDesc', { libraryName }) :
                    html('dynamicHoldModal.placeHoldDesc', { libraryName })
            )}
        </p>

        <label>
            <input data-first-focus type="email" id="email-dynamic-${ id }" class="patron-hold-email-dynamic js-first-focus" autofocus placeholder="${ html('emailAddress') }" title="${ html('emailAddress') }" aria-label="${ html('emailAddress') }" aria-labelledby="hold-modal-label" required />
        </label>
        <small class="error">${ html('emailRequiredError') }</small>

        <label>
            <input type="email" id="email-conf-dynamic-${ id }" class="patron-hold-email-conf-dynamic js-email-confirm" placeholder="${ html('confirmEmail') }" title="${ html('confirmEmail') }" aria-label="${ html('confirmEmail') }" required data-equalto="email-dynamic-${ id }" />
        </label>
        <small class="error">${ html('emailsMustMatchError') }</small>
    </div>
    <div class="clear" />

    <div class="button-container js-buttons">
        <span class="TitleAction" />
        <button type="button" class="button cancel radius margin-small u-allCaps outline gray js-close">${ html('cancel') }</button>
    </div>
    <div class="clear" />
</form>`;
