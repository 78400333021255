export default ({languageText, areYouSureText, actionText}) => `
    <div class='modal-text'>
        ${languageText} ${areYouSureText}
        <div>
            <input type='checkbox' id='no-show-checkbox' class='no-show-checkbox' /><label class='no-show-label' for='no-show-checkbox'>${window.html('dontShow')}</label>
        </div>
    </div>
    <div class='button-container'>
        <button type='button' class='confirm-button button margin-small radius secondary contrast u-allCaps'>${actionText}</button>
        <button type='button' class='cancel-button button margin-small outline gray u-allCaps'>${window.html('cancel')}</button>
    </div>
`;
