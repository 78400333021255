/*
 * @param formats
 * @returns {string} The format ID or null
 */
const findFormatId = function(formats) {
    if (formats != null) {
        for (let x = 0; x < formats.length; x++) {
            const val = formats[x];
            if (val.id === 'ebook-epub-adobe'
                    || val.id === 'ebook-epub-open'
                    || val.id === 'audiobook-mp3'
                    || val.id === 'video-streaming') {
                return val.id;
            }
        }
    }
    return null;
};

export default findFormatId;
