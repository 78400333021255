var _ = require('underscore');
var features = require('lightning-common/lib/configuration/features.json');
var reversePreviewSites = require('lightning-common/lib/configuration/reversePreviewSites.json');
var FeatureManager = require('./featureManager');

/**
 * Function for getting configured features for reverse-preview
 * which is a dynamic feature from config/features.json.
 * @param {number} tenant - The tenant id
 * @param {string} allFeatures - The allFeatures secret cookie value
 */
var getFeaturesConfiguredForReversePreview = function(feats, libraryConfiguration, tenant) {
    var returnFeatures = _.extend({}, feats);
    var reversePreviewSite = reversePreviewSites && reversePreviewSites[tenant];
    var isReversePreviewEnabled = (reversePreviewSite && reversePreviewSite.url);

    returnFeatures['reverse-preview'] = {enabled: isReversePreviewEnabled, testable: isReversePreviewEnabled};
    return returnFeatures;
};

var buildFeatureManager = function(libraryConfigurations, tenant, allFeatures, apiFeatures) {
    if (!!apiFeatures) {
        features = apiFeatures;
    }
    return new FeatureManager(features, libraryConfigurations, tenant, allFeatures, [
        {configureDynamicFeature: _.partial(getFeaturesConfiguredForReversePreview)}
    ]);
};

module.exports = buildFeatureManager;
