(() => {
    if (typeof window.CustomEvent === 'function') {
        return false;
    }

    const CustomEvent = (type, eventInitDict) => {
        const event = document.createEvent('CustomEvent');
        if (eventInitDict) {
            event.initCustomEvent(type, eventInitDict.bubbles, eventInitDict.cancelable, eventInitDict.detail);
        } else {
            event.initCustomEvent(type);
        }
        return event;
    };

    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
})();
