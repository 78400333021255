'use strict';
const _ = require('underscore');

class AccountLimits {
    constructor(props) {
        this.loansLimit = 0;
        this.loansCount = 0;
        this.holdsLimit = 0;
        this.holdsCount = 0;
        this.renewalDayLimit = 3;
        // This is an accepted constant value, but it not provided via the thunder interface at this time - etung:20161123
        this.wishlistLimit = 5000;
        this.wishlistCount = 0;
        this.totalItemsCountedTowardsCheckoutLimit = 0;

        _.extend(this, props);
    }

    get loansRemaining() {
        return this.loansLimit - this.totalItemsCountedTowardsCheckoutLimit;
    }

    get holdsRemaining() {
        return this.holdsLimit - this.holdsCount;
    }

    get wishlistRemaining() {
        return this.wishlistLimit - this.wishlistCount;
    }

    static parseFromThunder(card, loans, holds, wishlist) {
        let accountLimits = new AccountLimits();

        accountLimits.holdsCount = holds.totalItems;
        accountLimits.holdsLimit = card.holdLimit;

        accountLimits.loansCount = loans.totalItems;
        accountLimits.totalItemsCountedTowardsCheckoutLimit =
            loans.totalItemsCountedTowardsCheckoutLimit;

        if (card.checkoutLimitsByMediaType) {
            var c = card.checkoutLimitsByMediaType;
            accountLimits.loansLimit =
                c.ebook || c.audiobook || c.video || accountLimits.loansLimit;
        }

        accountLimits.wishlistCount = wishlist.totalItems;
        return accountLimits;
    }
}

module.exports = AccountLimits;
