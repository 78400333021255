import { Modal }                        from '../../common/modal';
import KindlePreferenceModalTemplate    from '../templates/KindlePreferenceModal.html';

export default class KindlePreferenceModal extends Modal {
    constructor(noThanksTargetUrl) {
        const modalContents = {
            classes: ['KindlePreferenceModal'],
            title: window.html('kindleFulfillment.setPrefModal.heading'),
            content: KindlePreferenceModalTemplate(noThanksTargetUrl),
            closeOnEsc: false,
            closeOnBlur: false
        };
        super(modalContents);

        let kindleLogo = `<div class='logo-container'><img src='https://images.overdrive.com/misc/kindle/Kindle-icon.png' alt='${html('kindleFulfillment.kindle')}' /></div>`;
        this.$title.before(kindleLogo);

        this.$el.find('.close-reveal-modal').remove();
    }
}
