import BaseModel from '../../../models/base';

const AUTOCOMPLETE_MINIMUM = 1;
const SEARCH_MINIMUM = 1;

const QuickSearchModel = BaseModel.extend({
    defaults() {
        return {
            text: '',
        };
    },

    isValidForAutocomplete() {
        const query = this.get('text');
        const queryIsOnlySpaces = query.replace(/\s/g, '').length === 0;
        const queryMeetsAutoCompleteMin =
            this.get('text').length >= AUTOCOMPLETE_MINIMUM;

        return !queryIsOnlySpaces && queryMeetsAutoCompleteMin;
    },

    isValidForSearch() {
        return this.get('text').length >= SEARCH_MINIMUM;
    },
});

export default QuickSearchModel;
