import TitleActionButton        from '../TitleActionButton';
import PlaceHoldPanelTemplate   from '../templates/PlaceHoldPanel.html';

export default class PlaceHoldPanel extends Backbone.View {
    template = PlaceHoldPanelTemplate

    constructor({ bus, titleModel, context, isRequestAgain = false }) {
        super(...arguments);

        this.titleModel = titleModel;
        this.context    = context;
        this.bus        = bus;
        this.card       = window.card;
        this.isRequestAgain = isRequestAgain;

        this.card._fetch.then(() => {
            this.render();
            this._bindEvents();
        });
    }

    render() {
        const viewData = {
            title             : this.titleModel.get('title'),
            type              : this.titleModel.get('type'),
            coverSrc          : this.titleModel.getCoverSrc(510),
            id                : this.titleModel.id,
            isPreReleaseTitle : this.titleModel.get('isPreReleaseTitle'),
            prereleaseDate    : this.titleModel.get('estimatedReleaseDate'),
            ownedCopies       : this.titleModel.get('ownedCopies'),
            //Should fix the issue with the apostrophe's not properly encoding. 
            libraryName       : String(window.OverDrive.libraryName).replace('&#39;', '\''),
            html              : window.html,
            text              : window.text,
            isRequestAgain    : this.isRequestAgain
        };

        this.$el.html(this.template(viewData));

        this.$form = this.$el.find('form');

        if (this.titleModel.isLuckyDayCheckout) {
            this.titleModel.set('titleState', 'hold');
        }

        this.titleActionButton = new TitleActionButton({
            model   : this.titleModel,
            type    : 'button',
            el      : this.$form.find('.TitleAction'),
            action  : () => this.$form.submit()
        });
    }

    _bindEvents() {
        this.$form
            .foundation('abide', 'reflow')
            .on('valid.fndtn.abide', () => {
                this.card.setHoldEmail(this.$el.find('input[type="email"]:first').val().trim());
                this.bus.trigger('title:hold', this.titleModel, this.context);
            });
    }
}
