export default ({text, ariaLabel, removeButton, cancelButton}) => `
        <p>${text}</p>
    
        <div class="button-container">
            <button type="button" data-remove aria-label="${ariaLabel}" class="js-remove-history button margin-small radius secondary contrast u-allCaps">
                <span>${removeButton}</span>
            </button>
            <button type="button" data-cancel data-reveal-id="history-modal" class="button radius margin-small outline gray cancel u-allCaps js-cancel" tabindex="0">${cancelButton}</button>
        </div>
`;
