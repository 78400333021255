
let bitFlag = require('../lib/utility/bitFlag.js');

module.exports = class OdTrack {

    constructor(flags, isGdprCoutnry) {
        if (flags){
            this.flags = flags;
        }
        else {
            // defaults
            this.flags = 0;
            if (!isGdprCoutnry){
                this.perfTrackingEnabled = true;
                this.analyticsTrackingEnabled = true;
            }
            else {
                this.perfTrackingEnabled = false;
                this.analyticsTrackingEnabled = false;
            }
        }
    }

    get perfTrackingEnabled(){
        return bitFlag.bitDecode(0, this.flags);
    }

    set perfTrackingEnabled(enabled){
        this.flags = bitFlag.bitEncode(enabled, 0, this.flags);
    }

    get analyticsTrackingEnabled(){
        return bitFlag.bitDecode(1, this.flags);
    }

    set analyticsTrackingEnabled(enabled){
        this.flags = bitFlag.bitEncode(enabled, 1, this.flags);
    }

}
