export default ({ seeMoreLink }) => `
    <div class="HoldSuccessModal-relatedTitlesPrompt" tabindex="0">${window.html(
        'holdSuccessModal.wantToBorrowSubheading'
    )}</div>

    <section class="relatedTitles"></section>

    <div class="HoldSuccessModal-callToActionContainer">
        <a class="button radius HoldSuccessModal-callToAction" href="${seeMoreLink}" tabindex="0">${window.html(
    'holdSuccessModal.showMore'
)}</a>
    </div>
`;
