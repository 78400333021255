module.exports={
    "codes": [
        "BH",
        "CY",
        "EG",
        "IQ",
        "IR",
        "IL",
        "JO",
        "KW",
        "LB",
        "OM",
        "PS",
        "QA",
        "SA",
        "SY",
        "KW",
        "LY",
        "MR",
        "MA",
        "SD",
        "TN",
        "TR",
        "AE",
        "YE"
    ]
}
