/*
 * Handle opening the hold conflict modal
 */
const openHoldConflictModal = function(mediaData) {
    const originalHTMLContents = $(`.primary-action[data-media-id="${mediaData.id}"]`).html();

    $('.modal').foundation('reveal', 'close');

    const dataType = mediaData.typeId;
    const holdButton = $('.now-unavailable-button-container a:not([data-cancel])');

    $(`.primary-action[data-id="${mediaData.id}"]`).html(originalHTMLContents);
    $('[data-id="title"]').html(mediaData.title);
    $('[data-id="cover-image"]').attr('src', mediaData.cover);

    holdButton.attr('data-title', mediaData.title).attr('data-artwork', mediaData.cover)
        .attr('data-type', dataType).attr('data-type-name', dataType.toUpperCase()).attr('data-pre-release', mediaData.prerelease);

    loading.changeButtonState(holdButton, 'hold');

    holdButton.attr('data-media-id', mediaData.id).attr('href', 'javascript:void(0)').attr('data-action', 'hold');
    $('#now-unavailable-modal').foundation('reveal', 'open');
};


export default openHoldConflictModal;
