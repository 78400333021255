import { Modal } from '../common/modal';
import HoldInformationV2 from './HoldInformationV2';

export default class HoldInformationModalV2 extends Modal {
    constructor({ model, hideHoldsLink = false}) {
        const information = new HoldInformationV2({model, hideHoldsLink, isVisitor: window.OverDrive.isVisitor}).render();
        const modalContents = {
            classes: ['HoldInformationModalV2'],
            title: window.html('holdsRatioHelpModal.heading'),
            content: information.el
        };
        super(modalContents);
    }
}
