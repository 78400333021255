import Borrow from './borrow/Borrow';
import Wishlist from './wishlist/Wishlist';
import Hold from './holds/Hold';
import Nook from './nook/Nook';
import StarRating from './ratings/StarRating';
import ReadingHistory from './history/ReadingHistory';

import LanguageMismatch from './checks/LanguageMismatch/LanguageMismatch';
import LanguageMismatchModal from './checks/LanguageMismatch/LanguageMismatchModal';
import Preview from './Preview';

export default class TitleActionHandler {
    constructor(bus, card, patronSettings) {
        this.bus = bus;
        this.card = card;
        this.patronSettings = patronSettings;

        this._borrow = new Borrow(this.bus);
        this._hold = new Hold(this.bus, this.card);
        this._wishlist = new Wishlist(this.bus);
        this._nook = new Nook(this.bus);
        this._rating = new StarRating(this.bus);
        this._history = new ReadingHistory(this.bus);
        this._preview = new Preview(this.bus);

        this.bus
            .on('title:borrow', (title, context) =>
                this.perform('borrow', title, context)
            )
            .on('title:hold', (title, context) =>
                this.perform('hold', title, context)
            )
            .on('title:hold-cancel', (title, context) =>
                this.perform('holdCancel', title, context)
            )
            .on('title:wishlist', (title, context) =>
                this.perform('wish', title, context)
            )
            .on('title:nook', (title, context) =>
                this.perform('nook', title, context)
            )
            .on('title:rating', (title, context) =>
                this.perform('rating', title, context)
            )
            .on('title:history', (title, context) =>
                this.perform('history', title, context)
            )
            .on('title:preview', (title, context) =>
                this.perform('preview', title, context)
            )
            .on('title:renew', (title, context) =>
                this.perform('renew', title, context)
            )
            .on('title:requestAgain', (title, context) =>
                this.perform('requestAgain', title, context)
            );
    }

    perform(action, titleModel, context) {
        if (!window.OverDrive.isAuthenticated && action !== 'preview') {
            this._redirectToAuth(
                action,
                titleModel.id,
                titleModel.get('tempStarRating')
            );
        } else if (
            !window.OverDrive.hasLinkedCards &&
            !window.featureManager.isEnabled('sampleOnlyMode')
        ) {
            let titleAction = titleModel.get('titleState');
            if (action === 'rating') {
                titleAction = `rating~${titleModel.get('tempStarRating')}`;
            }
            this.bus.trigger('user:card:none', { action: titleAction });
        } else if (typeof this._actions[`${action}`] === 'function') {
            this._executeAction(action, titleModel, context);
        }

        return;
    }

    _redirectToAuth(action, id, rating) {
        let actionStr = `${action}~${id}`;
        if (rating) {
            actionStr += `~${rating}`;
        }
        window.location = window.url(window.routes.accountSignIn, {
            forward:
                window.location.pathname +
                window.location.search +
                '#action=' +
                actionStr,
        });
    }

    _executeAction(action, title, context) {
        this._languageCheck(action, title, () =>
            this._actions[`${action}`](title, context)
        );
    }

    _languageCheck(action, title, successCallback) {
        let shouldCheck = false;
        if (_(['borrow', 'hold', 'preview']).contains(action)) {
            shouldCheck = true;
        } else if (action === 'wish') {
            shouldCheck = title.get('wishlistState') === 'wishlistAdd';
        } else if (action === 'history') {
            shouldCheck = !title.get('isInHistory');
        }

        if (shouldCheck) {
            let fetchLanguageData = !title.get('languages')
                ? title.getMissingAttribute('languages')
                : Promise.resolve();
            fetchLanguageData.then(() => {
                if (
                    this._checkLanguageMatch(title) ||
                    (Cookies.get('language-mismatch-setting') &&
                        Cookies.get('language-mismatch-setting') === '1')
                ) {
                    successCallback();
                } else {
                    window.Logger.log(
                        'warn',
                        'launching language mismatch modal',
                        {
                            patronLanguage:
                                window.patronSettings['language-selection'],
                            titleLanguages: title.get('languages'),
                        }
                    );
                    const mismatchModal = new LanguageMismatchModal({
                        model: new LanguageMismatch({
                            languages: title.get('languages'),
                            action,
                        }),
                    });
                    mismatchModal.$el
                        .find('.confirm-button')
                        .on('click', () => {
                            mismatchModal.close();
                            if (
                                mismatchModal.$el
                                    .find('.no-show-checkbox')
                                    .is(':checked')
                            ) {
                                const millisecondsInYear =
                                    1000 * 60 * 60 * 24 * 365;
                                const expireDate = new Date(
                                    Date.now() + millisecondsInYear
                                );
                                Cookies.set('language-mismatch-setting', '1', {
                                    httpOnly: false,
                                    expires: expireDate,
                                });
                            }
                            successCallback();
                        });
                    mismatchModal.$el.find('.cancel-button').on('click', () => {
                        mismatchModal.close();
                        title.trigger('change:wishlistState');
                    });
                }
            });
        } else {
            successCallback();
        }
    }

    _checkLanguageMatch(title) {
        const titleLanguages = title.get('languages');
        const titleLanguageIds =
            (titleLanguages && titleLanguages.map((language) => language.id)) ||
            [];
        let patronLanguage = window.patronSettings['language-selection'];
        if (!!patronLanguage) {
            const varietyIndex = patronLanguage.indexOf('-');
            if (varietyIndex !== -1) {
                patronLanguage = patronLanguage.substring(0, varietyIndex);
            }
            if (!titleLanguageIds.includes(patronLanguage)) {
                return false;
            }
        }
        return true;
    }

    get _actions() {
        let that = this;
        return {
            borrow(title, context) {
                that._borrow.borrowTitle(title, context);
            },

            hold(title, context) {
                that._hold.holdTitle(title, context);
            },

            holdCancel(title, context) {
                that._hold.holdCancel(title, context);
            },

            wish(title, context) {
                that._wishlist.wishlistAction(title, context);
            },

            nook(title, context) {
                that._nook.sendToNook(title, context);
            },

            rating(title, context) {
                that._rating.handlingRating(title, context);
            },

            history(title, context) {
                that._history.readingHistoryAction(title, context);
            },

            preview(title) {
                that._preview.giveSample(title);
            },

            renew(title, context) {
                context.isRenewal = true;
                context.formatLock = null;
                if (title.attributes.isFormatLockedIn) {
                    context.formatLock =
                        title.attributes.otherFormats.length &&
                        title.attributes.otherFormats[0].id;
                }
                that._borrow.borrowTitle(title, context);
            },

            requestAgain(title, context) {
                context.isRequestAgain = true;
                that._hold.holdTitle(title, context);
            },
        };
    }
}
