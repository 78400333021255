import {Modal } from '../common/modal';
import Checkbox from '../common/Checkbox';
import template from './cookieSettingsModal.html';
import OdTrack from '../../../../models/OdTrack';
import CookieConfig from '../../../../lib/cookieConfig';

const { Cookies, OverDrive } = window;
const { reload } = location;
const { odTrack } = CookieConfig;

export default class CookieSettingsModal extends Backbone.View {

    COOKIE_NAME = 'od_track';

    constructor(opts){
        super(opts);

        this.odTrack = new OdTrack(Cookies.get(this.COOKIE_NAME), OverDrive.isGdprCountry);
        this.isDismissable = opts.isDismissable;
    }

    render() {

        this.$el.empty().append(template());

        this.perfCookieCheckbox = new Checkbox({
            el: this.$el.find('.perfCookieCheckbox'),
            label: html('gdpr.settingsPanel.performanceHeading'),
            ariaLabel: html('gdpr.settingsPanel.performanceHeading')
        }).render();
        this.perfCookieCheckbox.set(this.odTrack.perfTrackingEnabled);
        this.perfCookieCheckbox.on('change', (val) => {
            this.odTrack.perfTrackingEnabled = val;
        });

        this.analyticsCookieCheckbox = new Checkbox({
            el: this.$el.find('.analyticsCookieCheckbox'),
            label: html('gdpr.settingsPanel.analyticsHeading'),
            ariaLabel: html('gdpr.settingsPanel.analyticsHeading')
        }).render();
        this.analyticsCookieCheckbox.set(this.odTrack.analyticsTrackingEnabled);
        this.analyticsCookieCheckbox.on('change', (val) => {
            this.odTrack.analyticsTrackingEnabled = val;
        });


        this.$el.find('.confirm').on('click', () => this.confirm());

        let opts = {
            autoShow: false,
            title: html('gdpr.settingsPanel.title'),
            content: this.$el,
            classes: ['cookieSettingsModal'],
            closeOnEsc : this.isDismissable,
            closeOnBlur : this.isDismissable,
            showCloseButton: this.isDismissable
        };


        this.$el.find('.rainbowText').html((i, html) => {
            let chars = $.trim(html).split('');
            return `<span>${chars.join('</span><span>')}</span>`;
        });

        this.modal = new Modal(opts);
        this.modal.show();
    }

    confirm(){
        Cookies.set(odTrack.name, this.odTrack.flags,{
            domain: window.OverDrive.isLocal ? 'lightning.test' : odTrack.domain,
            expires: new Date(Date.now() + odTrack.durationMS)
        });
        this.modal.hide();

        // We need to send the cookie to the server before it can take effect
        location.reload();
    }
}


