import { Modal } from '../../common/modal';
import HoldInformation from './HoldInformation';

export default class HoldInformationModal extends Modal {
    constructor({ model, type }) {
        const information = new HoldInformation({ model, type }).render();
        const modalContents = {
            classes: ['HoldInformationModal'],
            title: window.html('holdsRatioHelpModal.heading'),
            content: information.el
        };
        super(modalContents);
    }
}
