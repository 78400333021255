import BaseView from '../../../views/base';
import {surroundSubtext} from '../../../util/text';
const fs = require('fs');
const path = require('path');
const template = fs.readFileSync(path.join(__dirname, '/../templates/autocompletion.html'), 'utf8');

const Autocompletion = BaseView.extend({

    template: _.template(template, {variable: 'data'}),

    initialize({model, query}) {
        this.model = model;
        this.query = query;
        this.listenTo(this.model, 'change:active', this.render);
    },

    deleteViewAndModel() {
        this.model.destroy();
        this.deleteView();
    },

    deleteView() {
        this.remove();
        this.unbind();
    },

    getTemplateData() {
        const href = window.url(window.routes.search, {
            query: this.model.get('text')
        });

        return _.extend({}, this.model.attributes, {
            href,
            resultText: surroundSubtext({
                text: this.model.get('text'),
                matcher: this.query.get('text'),
                tag: 'b',
                ignoreCase: true
            }),
            field: window.html('autoComplete.' + this.model.get('field'))
        });
    }
});

export default Autocompletion;
