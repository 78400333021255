import { Modal } from '../../../components/common/modal';
import LanguageMismatchModalTemplate from './LanguageMismatchModal.html';

export default class LanguageMismatchModal extends Modal {
    constructor({model}) {
        const modalContents = {
            classes: ['LanguageMismatchModal'],
            title: window.html('languageMismatch.languageConfirmation'),
            content: LanguageMismatchModalTemplate({
                languageText: model.languageText,
                areYouSureText: model.areYouSureText,
                actionText: model.actionText
            })
        };
        super(modalContents);
    }
}
