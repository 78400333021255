module.exports={
    "advantage": { "enabled": true, "testable": true },
    "autocomplete": { "enabled": true, "testable": true },
    "redis-cache": {
        "enabled": true,
        "_comment": "this feature cannot be testable, and cannot use the featureManager"
    },
    "content-holds": { "enabled": true, "testable": true },
    "backToMainCollectionLink": { "enabled": true, "testable": true },
    "libbyTout": { "enabled": true, "testable": true },
    "promoteLibby": { "enabled": true, "testable": true },
    "switchToLibby": { "enabled": true, "testable": true },
    "readingHistory": {
        "enabled": true,
        "testable": true,
        "_comment": "LHTNG-1809"
    },
    "atosLevels": { "enabled": true, "testable": true },
    "lexileScores": { "enabled": true, "testable": true },
    "gradeLevels": { "enabled": true, "testable": true },
    "interestLevels": { "enabled": true, "testable": true },
    "bannerIsSecondaryColor": {
        "enabled": true,
        "testable": true,
        "_comment": "this feature makes the banner the secondary color and the toast darker"
    },
    "kindleFooter": {
        "enabled": true,
        "testable": true,
        "_comment": "LHTNG-2315 - kindle footer"
    },
    "kindleNav": {
        "enabled": true,
        "testable": true,
        "_comment": "LHTNG-2315 - kindle nav promotion"
    },
    "kindleLoanFormatLimit": {
        "enabled": true,
        "testable": true,
        "_comment": "LHTNG-2315 - kindle loans format limit"
    },
    "kindleSearchOffer": {
        "enabled": true,
        "testable": true,
        "_comment": "LHTNG-2315 - offer to set from search"
    },
    "kindleLoanOffer": {
        "enabled": true,
        "testable": true,
        "_comment": "LHTNG-2315 - offer to set from fulfill"
    },
    "getACard": {
        "enabled": true,
        "testable": true,
        "_comment": "LHTNG-2479 - get a card promotion modal"
    },
    "enhancedLibbyPromo": { "enabled": true, "testable": true },
    "showLibbyIntercept": { "enabled": false, "testable": true },
    "enhancedSeries": { "enabled": false, "testable": true },
    "sampleOnlyMode": { "enabled": true, "testable": true },
    "reciprocalLending": { "enabled": true, "testable": true },
    "LHTNG-2863": { "enabled": false, "testable": true },
    "disableOdAppAccess": { "enabled": false, "testable": true }
}
