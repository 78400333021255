import KindlePreferenceModal        from './views/KindlePreferenceModal';
import KindlePreferenceResultModal  from './views/KindlePreferenceResultModal';

const { url, routes } = window;

export default class KindlePreference {
    constructor({bus, globalPreferences, source, completionTargetUrl}) {
        this.bus = bus;
        this.source = source;
        this.globalPreferences = globalPreferences;
        this.completionTargetUrl = completionTargetUrl;
    }

    run() {
        this.preferenceModal = new KindlePreferenceModal(this.completionTargetUrl);

        this.preferenceModal.$el.find('.confirm-button').on('click', () => {
            const resultButtonText = this.source === 'content_fulfillment' ? html('kindleFulfillment.setPrefModal.continue') : html('ok');
            let showResult = this._updatePreference(true).then(() => {
                this.preferenceModal.close();
                this.resultModal = new KindlePreferenceResultModal({kindleSelected: true, wasSuccessful: true, buttonText: resultButtonText, continueTargetUrl: this.completionTargetUrl});
            }).catch(() => {
                this.preferenceModal.close();
                this.resultModal = new KindlePreferenceResultModal({kindleSelected: true, wasSuccessful: false, buttonText: resultButtonText, continueTargetUrl: this.completionTargetUrl});
            });
        });

        this.preferenceModal.$el.find('.deny-button').on('click', () => {
            this._updatePreference(false).then(() => {
                this.preferenceModal.close();
            }).catch(() => {
                this.preferenceModal.close();
            });
        });
    }

    _updatePreference(val) {
        return this.globalPreferences.setKindleFulfillmentPreference(val, this.source);
    }
}
