export default class LendingPeriod extends Backbone.Model {
    get defaults() {
        return {
            isPreferredLendingPeriod: false,
            lendingPeriod           : 1,
            units                   : 'days',
            selected                : false // Non API property
        };
    }

    get period() {
        let lendingPeriod = this.get('lendingPeriod');
        if (this.get('units') === 'minutes') {

            let dayRemainderMinutes = (lendingPeriod % (60 * 24));

            return {
                days: (lendingPeriod - dayRemainderMinutes) / (60 * 24),
                hours: Math.ceil(dayRemainderMinutes / 60)
            };
        }
        return {
            days: lendingPeriod
        };
    }

    /** @type {string} - Localized display label for lending period */
    get label() {
        // ref: LHTNG-1789 - `For [{n} days] [{d} hours]`
        let textParts = [];
        !!this.period.days && this.period.days > 0  && textParts.push(window.html('accountLoans.expiresDays',  { time: this.period.days }));
        !!this.period.hours && this.period.hours > 0 && textParts.push(window.html('accountLoans.expiresHours', { time: this.period.hours }));

        return window.html('borrowForTextHtml', { text: textParts.join(' ') });

    }
}
