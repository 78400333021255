import routing from '../../../lib/internal/routing.js';

const routes = routing.routes;

const url = function(route, parameters) {
    const result = routing.getRouteUrl({baseUrl: window.OverDrive._baseUrl}, route, parameters);

    if (!result) {
        try {
            let message = `Failed to route ${route}`;
            if (parameters != null) {
                const parametersJSON = JSON.stringify(parameters);
                message += ` with parameters ${parametersJSON}`;
            }

            const error = new Error(message);

            if (window.NREUM && window.NREUM.noticeError) {
                window.NREUM.noticeError(error);
            } else if (window.console && window.console.error) {
                window.console.error(error);
            }
        } catch (e) {
            // ¯\_(ツ)_/¯
        }
    }
    return result;
};

export {
    url,
    routes
};
