import { Modal } from '../../common/modal';
import RemoveHistoryModalTemplate from '../templates/RemoveHistory.html';

export default class RemoveHistoryModal extends Backbone.View {
    initialize({bus, title, action}) {
        this.bus        = bus;
        this.action     = action || (() => {});

        const viewData  = {
            text            : window.html('readingHistory.removeWarning'),
            ariaLabel       : window.html('readingHistory.removeTitle', { title: title.attributes.title }),
            removeButton    : window.html('readingHistory.remove'),
            cancelButton    : window.html('cancel')
        };

        const content = $(RemoveHistoryModalTemplate(viewData));

        let modalContents = {
            autoShow: false,
            title: window.html('readingHistory.remove'),
            content: content,
            id: 'removeHistoryModal'
        };

        this.modal = new Modal(modalContents);

        this.modal.$el.find('.js-cancel').on('click', () => {
            this.modal.close();
        });

        this.modal.$el.find('.js-remove-history').on('click', () => {
            this.action();
            this.modal.close();
        });

        this.modal.show();
    }
}
