const { html } = window;
export default (atts, cover, title, author, isAvailable, deliveredPreviously, isSuspended) => `

<div class="large-12 columns no-padding-left suspend-title" tabindex="-1">
    ${ window.featureManager.isEnabled('suspend-modal-data-adjustment') ? 
        `<img src="${cover}" class="mediaCover" />
        <h5 class="title"><strong>${title}</strong></h5>
        <p class="mediaAuthor">${author}</p>`
        :
        `<img src="${atts.covers.cover150Wide.href}" class="mediaCover" />
        <h5 class="title"><strong>${atts.title}</strong></h5>
        <p class="mediaAuthor">${atts.firstCreatorName}</p>`
    }
</div>

<div class="row suspend-options">
    <div class="suspend-select-container">
        ${isAvailable || deliveredPreviously ? 
            `<label id="select-suspension-label">${html('holdRedelivery.suspendSelectDeliveredLabel')}</label>`
            : ''
        }
        <select id="select-suspension" data-open="false">
            ${deliveredPreviously ? 
                `<option value="0" selected>${html('suspendHoldModal.suspendHoldRemove')}</option>`
                : ''
            }
            <option value="7" ${deliveredPreviously ? '' : 'selected'}>${isAvailable || deliveredPreviously ? html('suspendHoldModal.suspendHoldAfter', {num: 7}) : html('suspendHoldModal.suspendHoldFor', {num: 7})}</option>
            <option value="14">${isAvailable || deliveredPreviously ? html('suspendHoldModal.suspendHoldAfter', {num: 14}) : html('suspendHoldModal.suspendHoldFor', {num: 14})}</option>
            <option value="21">${isAvailable || deliveredPreviously ? html('suspendHoldModal.suspendHoldAfter', {num: 21}) : html('suspendHoldModal.suspendHoldFor', {num: 21})}</option>
            <option value="28">${isAvailable || deliveredPreviously ? html('suspendHoldModal.suspendHoldAfter', {num: 28}) : html('suspendHoldModal.suspendHoldFor', {num: 28})}</option>
            <option value="60">${isAvailable || deliveredPreviously ? html('suspendHoldModal.suspendHoldAfter', {num: 60}) : html('suspendHoldModal.suspendHoldFor', {num: 60})}</option>
            <option value="90">${isAvailable || deliveredPreviously ? html('suspendHoldModal.suspendHoldAfter', {num: 90}) : html('suspendHoldModal.suspendHoldFor', {num: 90})}</option>
            <option value="180">${isAvailable || deliveredPreviously ? html('suspendHoldModal.suspendHoldAfter', {num: 180}) : html('suspendHoldModal.suspendHoldFor', {num: 180})}</option>
        </select>
    </div>
</div>

<div class="large-12 columns no-padding-left">
    <p id="suspend-description" aria-labelledby="suspend-description"></p>
</div>

<div class="button-container">
    <button tabindex="0" id="suspend-hold" aria-busy="false" aria-label="${isAvailable || deliveredPreviously ? html('confirm') : html('suspendHoldModal.suspend')}"
            class="button radius margin-small secondary u-allCaps">
        <span>${isAvailable || deliveredPreviously ? html('confirm') : html('suspendHoldModal.suspend')}</span>
    </button>
    ${isAvailable || deliveredPreviously || !isSuspended ?
         '' : 
        `<button type="button" data-reveal-id="remove-suspension-modal" class="button radius margin-small secondary remove-suspension-button u-allCaps">
            <span>${ html('suspendHoldModal.removeSuspend') }</span>
        </button>`}
    <button type="button" data-cancel class="button radius margin-small outline gray cancel u-allCaps js-last-focus">${ html('cancel') }</button>
</div>
`;
