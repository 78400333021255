import SessionExpiredModal from '../../components/session_expired_modal/SessionExpiredModal';

export default class SharedActions {
    constructor(bus) {
        this.bus = bus;

        this.bus
            .on('user:session:expired', (data) => this._sessionExpired(data))
            .on('user:card:invalid', () => this._patronCardFailedAuthentication())
            .on('user:card:none', (data) => this._noLinkedCards(data.action));
    }

    _sessionExpired(data) {
        let forwardUrl = window.location.pathname + window.location.search;
        if (data && data.action){
            forwardUrl += `#action=${encodeURIComponent(data.action)}`;
        }
        new SessionExpiredModal(forwardUrl);
    }

    _noLinkedCards(action) {
        if (!window.featureManager.isEnabled('sampleOnlyMode')){
            const forwardUrl = window.location.pathname + window.location.search.replace(/(&|\?)no-linked-card(=true|\=false)/, '');
            const initiateCardLinkUrl = window.url(window.routes.accountStartCardLink, {forward: forwardUrl, action});
            $('#add-card-button').attr('href', initiateCardLinkUrl);
            $('#link-card-modal').foundation('reveal', 'open');
        }
    }

    _patronCardFailedAuthentication() {
        if (window.OverDrive.authType === 'library' || window.featureManager.isEnabled('OzoneAuthentication')) {
            new SessionExpiredModal();
        } else {
            // the user's card is no longer valid. Direct them to the link a card flow so that they may resolve any issues.
            $('.js-card-error-modal').foundation('reveal', 'open');
        }
    }
}
