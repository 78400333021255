import BaseView from '../../../views/base';

const AccountMenuMobileView = BaseView.extend({
    events: {
        'click .js-account-menu-link': 'toggleAccountDropdown'
    },
    initialize({el}) {
        this.el = el;
        this.$accountMenuLink = $('.js-account-menu-link');
        this.$accountMenuContent = $('.js-account-menu-mobile');
    },
    open() {
        $('.icon-account.mobile').addClass('secondary-color');
        this.$accountMenuLink.add(this.$accountMenuContent[0]).addClass('is-open');

        // Close all open foundation dropdowns. hopefully we can kill this eventually.
        $(document).foundation('dropdown', 'close', $('[data-dropdown-content]'));
        if (toaster) toaster._stickToast();
    },
    close() {
        $('.icon-account.mobile').removeClass('secondary-color');
        this.$accountMenuLink.add(this.$accountMenuContent[0]).removeClass('is-open');
        if (toaster) toaster._stickToast();
    },
    toggleAccountDropdown() {
        if (this.$accountMenuLink.hasClass('is-open')) {
            this.close();
        } else {
            this.open();
        }
    }
});

export default AccountMenuMobileView;
