export default ({signInUrl, stillTroubleText}) => `
<div class="modal-container" >
    <p > ${ html('reauthenticateModal.textBody') } </p>

    <p> ${stillTroubleText} </p>

    <div class="button-container">
        <a href="${ signInUrl }" role="button" class="button margin-small radius secondary u-allCaps" aria-label="${ html('signIn') }">
            ${ html('signIn') }
        </a>
        <button type="button" class="button radius margin-small outline gray cancel u-allCaps">${ html('cancel') }</button>
    </div>
</div>
`;
