const { html } = window;
export default (atts, cover, title, author, expirationDate) => `

<div class="large-12 columns no-padding-left suspend-title" tabindex="-1">
    ${ window.featureManager.isEnabled('suspend-modal-data-adjustment') ? 
        `<img src="${cover}" class="mediaCover" />
        <h5 class="title"><strong>${title}</strong></h5>
        <p class="mediaAuthor">${author}</p>`
        :
        `<img src="${atts.covers.cover150Wide.href}" class="mediaCover" />
        <h5 class="title"><strong>${atts.title}</strong></h5>
        <p class="mediaAuthor">${atts.firstCreatorName}</p>`
    }
</div>

<div class="large-12 columns no-padding-left">
    <p id="suspend-description" aria-labelledby="suspend-description">
        ${expirationDate === 'today' ?
            `${html('holdRedelivery.suspendHoldRemoveConfirmationHtml')}` :
            `${html('holdRedelivery.suspendHoldConfirmationHtml', {expirationDate: html('date-long', {d: expirationDate})})}`
        }
    </p>
</div>

<div class="button-container">
    <button type="button" data-cancel class="button radius margin-small outline gray cancel u-allCaps js-last-focus">${ html('close') }</button>
</div>
`;