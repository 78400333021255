import keyCodes from '../keycodes.js';
/*
 * Force account menu to be navigable with arrow keys
 * @param {object} e event object
 */
const arrowTabAccountMenu = function(e) {
    if (e.which === keyCodes.DOWN) {
        e.preventDefault();
        if ($(e.target).is('ul')) {
            $('ul#dropAccount li:first-child a').focus();
        } else {
            $(e.target).emulateTab();
        }
    }
    if (e.which === keyCodes.UP) {
        e.preventDefault();
        $(e.target).emulateTab(-1);
    }

    e.stopPropagation();
};

export default arrowTabAccountMenu;
