import expensiveDomTraversalForDyslexicPleaseDoNotUse from './expensiveDomTraversalForDyslexicPleaseDoNotUse.js';

/*
 * Do the expensive DOM traversal if cookie set.
 */
const expensiveDomTraversalForDyslexicIfCookiePleaseDoNotUse = function() {
    if (window.Cookies && window.Cookies.get('dyslexic-setting') === '1') {
        expensiveDomTraversalForDyslexicPleaseDoNotUse('open-dyslexic', -1);
    }
};

export default expensiveDomTraversalForDyslexicIfCookiePleaseDoNotUse;
