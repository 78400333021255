/**
* Flips the value of a bit at the index
* @param index - index of bit to flip. 0 is the LSB
* @param flagString - Encoded string, bits are represented by base 10 integer
* @return flagString - the updated and encoded string
*/
var bitFlip = function(index, flagString) {
    var pow = Math.pow(2, index);
    return (parseInt(flagString, 10) ^ pow).toString();
};

/**
* Returns the value of a bit at the index specified from the encoded flagString
* @param index - index of bit flag. 0 is the LSB
* @param flagString - Encoded string, bits are represented by base 10 integer
* @return boolean value at index
*/
var bitDecode = function(index, flagString) {
    var pow = Math.pow(2, index);
    return (parseInt(flagString,10) & pow) === pow;
};

/**
* Encodes the bit at the index on/off into a flagString
* @param value - value to be encoded (true/false)
* @param index - index of bit to set. 0 is the LSB
* @param flagString - optional if updating existing flagString
* @return the encoded string of bits
*/
var bitEncode = function(value, index, flagString = '0') {
    return bitDecode(index, flagString) === value ? flagString : bitFlip(index, flagString);
};

module.exports = {
    bitFlip: bitFlip,
    bitDecode: bitDecode,
    bitEncode: bitEncode
};
