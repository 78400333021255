import HistoryEventListener from '../history_event_tracker/HistoryEventListener';
export default class AutoScroll extends HistoryEventListener {
    constructor(tracker) {
        super(tracker);
        this._cancelLoop = false;
    }

    onLoad(states) {
        this._checkAgain();
        this._startLoopTimer();
    }

    onUnload() {
        this._setScrollCookie();
    }


    /**
     * Scrolls the user down the page based on the param
     */
    _scrollUser() {
        if ($(window) && $(window).scrollTop && this.getTracker().getData(0) && this.getTracker().wasBackButtonHit() &&
            this.getTracker().getData(0).scroll) {
            $(window).scrollTop(this.getTracker().getData(0).scroll);
        }
    }

    /**
     * Checks if the dynamic content has been shown yet
     */
    _checkDynamicContentState() {
        var dynamicContent = $('.js-dynamic-content');
        var dynamicParent = $('.js-dynamic-parent'); // Necessary for dynamically appended content

        if ((dynamicParent.length || dynamicContent.length) && !dynamicContent.is(':visible') && !this._cancelLoop) {
            this._checkAgain();
        } else {
            clearTimeout(this.loopTimer);
            this._scrollUser();
        }
    }

    /**
     * Loop back to check for visible dynamic content
     * If requestAnimationFrame is supported check for visible content on the next frame.
     * Otherwise fallback to setTimeout
     */
    _checkAgain() {
        return !!requestAnimationFrame ?
            requestAnimationFrame(this._checkDynamicContentState.bind(this)) :
            setTimeout(this._checkDynamicContentState.bind(this), 50);
    }

    /**
     * Exit the loop if content does not appear within 30 seconds
     * Starts the timer to cancel the loop
     */
    _startLoopTimer() {
        this.looptimer = setTimeout(() => {
            this._cancelLoop = true;
        }, 30000);
    }

    _setScrollCookie() {
        if ($(window) && $(window).scrollTop) {
            this.getTracker().addDataToLatest('scroll', $(window).scrollTop());
        }
    }
}
