/**
 * Get all query parameters
 * @param {string} qs The current query string provided by window.location.search
 * @returns {object} A JSON object containing the query string name and values
 */
const getQueryParams = function (qs) {
    const qsReplaced = qs.split('+').join(' ');

    const params = {};
    const re = /[?&]?([^=]+)=([^&]*)/g;
    let tokens = re.exec(qsReplaced);

    while (tokens) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        tokens = re.exec(qsReplaced);
    }

    return params;
};

export default getQueryParams;
