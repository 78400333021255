var focused = $(':focus');

/**
 * Actions to take when opening modals
 */
$('.reveal-modal').each(function() {
    /* Capture element that opened the modal, so it can be re-focused when the modal closes */
    $(this).on('open.fndtn.reveal', function() {
        focused = document.activeElement;
    });

    $(this).on('opened.fndtn.reveal', function() {
        var modal = this;

        $(modal).attr('aria-hidden', false);
        $('.container').attr('aria-hidden', 'true');
        $('.row.footer').attr('aria-hidden', 'true');
        $('.nav-container').attr('aria-hidden', 'true');

        //Place initial focus inside of modal
        $(modal).find('.js-first-focus').focus();

    }).on('closed.fndtn.reveal', function() {
        $(this).attr('aria-hidden', true);
        $('.container').attr('aria-hidden', 'false');
        $('.row.footer').attr('aria-hidden', 'false');
        $('.nav-container').attr('aria-hidden', 'false');
        //Restore focus to last focused element outside of modal
        focused.focus();
    });
});

/**
 * Keep focus inside the modal. If the user tabs past the last element, re-focus
 * on the first element (js-first-focus). If the user shift-tabs on the first
 * element, focus on the last element (js-last-focus).
 */
$(document).on('keydown', '.reveal-modal', function(e) {
    var activeElem = document.activeElement;

    if ($(activeElem).hasClass('js-first-focus') && e.which === 9 && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        $(this).find('.js-last-focus').focus();
    } else if ($(activeElem).hasClass('js-last-focus') && e.which === 9 && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        $(this).find('.js-first-focus').focus();
    }
});

//Close modal when enter pressed on close button
$('a.close-reveal-modal').keydown(function(e) {
    if (e.which == 13) {
        $(this).click();
    }
});

//Stop spinners when borrow or holds related modal is closed
$('#hold-dynamic-modal, #now-available-modal, #now-unavailable-modal').on('closed.fndtn.reveal', function() {

    //Put title elements back to original state.
    $('a.primary-action img:visible').each(function() {
        var container = $(this).closest('a.primary-action');
        container.html(container.attr('data-original-text'));
    });
});
