import TitleActionHandler from '../../title-actions/TitleActionHandler';
import SharedActions from '../../title-actions/shared/SharedActions';
import BorrowActions from '../../title-actions/borrow/BorrowActions';
import HoldActions from '../../title-actions/holds/HoldActions';
import NookActions from '../../title-actions/nook/NookActions';
import StarRatingActions from '../../title-actions/ratings/StarRatingActions';
import ReadingHistoryActions from '../../title-actions/history/ReadingHistoryActions';

import TrackingCirculation from './ga/TrackingCirculation';
import TrackingKindleFulfill from './ga/TrackingKindleFulfillment';
import TrackingPublicApiFulfillment from './ga/TrackingPublicAPIFulfillment';
import TrackingSignInForms from './ga/TrackingSignInForms';
import NotificationsEventTracker from '../notifications_event_tracker/NotificationsEventTracker';

class EventBridge {
    constructor({
        bus,
        card,
        patronSettings,
        featureManager,
        globalPreferences,
    }) {
        this.bus = bus;
        this.card = card;
        this.patronSettings = patronSettings;
        this.featureManager = featureManager;
        this.globalPreferences = globalPreferences;

        this.registerTitleAction();
        this.registerSharedActionHandlers();
        this.registerAnalyticsIntegrations();
    }

    registerTitleAction() {
        void new TitleActionHandler(this.bus, this.card, this.patronSettings);
        void new BorrowActions(
            this.bus,
            this.featureManager,
            this.globalPreferences
        );
        void new HoldActions(this.bus);
        void new NookActions(this.bus);
        void new StarRatingActions(this.bus);
        void new ReadingHistoryActions(this.bus);

        if (this.featureManager.isEnabled('Notifications')) {
            void new NotificationsEventTracker(this.bus);
        }
    }

    registerSharedActionHandlers() {
        void new SharedActions(this.bus);
    }

    registerAnalyticsIntegrations() {
        void (window.OverDrive.gaEventLogger = new TrackingCirculation(
            this.bus
        ));
        void new TrackingKindleFulfill(this.bus);
        void new TrackingSignInForms(this.bus);
        void new TrackingPublicApiFulfillment(this.bus);
    }
}

module.exports = EventBridge;
