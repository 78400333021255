import roundScroll from './roundScroll.js';

/*
 * Scroll a media carousel to the right or left
 * @param {string} direction The direction the carousel should scroll
 * @param {string} elem Selector to indicate the carousel element to be scrolled
 */
const scrollCarousel = function(direction, elem) {
    const media = $(elem).children('.js-carouselItem');
    const elemWidth = elem[0].getBoundingClientRect().width;
    const mediaWidth = media[0].getBoundingClientRect().width;
    const mediaAmount = media.length;

    // Calculate # of titles to left
    const totalLeft = Math.ceil(elem.scrollLeft() / mediaWidth);

    // Calculate number of 4px gaps between titles to compensate
    const gapTotalLeft = (totalLeft + 4) * 4;

    if (direction === 'right') {
        if (elem.scrollLeft() + elemWidth < (mediaWidth * mediaAmount)) {
            const a = roundScroll(elem.scrollLeft(), 'right', mediaWidth);
            const b = roundScroll(elemWidth, 'right', mediaWidth);
            const scrollLeft = a + b + gapTotalLeft;
            elem.animate({scrollLeft});
        }
    } else {
        // Check for obscured cover and if greater or less than 50% shown
        let modifier = 0;
        if (roundScroll(elem.scrollLeft(), 'left', mediaWidth) - elem.scrollLeft() > 100) {
            modifier += mediaWidth;
        }
        const a = roundScroll(elem.scrollLeft(), 'left', mediaWidth);
        const b = -elemWidth;
        const c = gapTotalLeft;
        const d = -24;
        const e = -modifier;
        const scrollLeft = a + b + c + d + e;
        elem.animate({scrollLeft});
    }
};

export default scrollCarousel;
