export default class HistoryCookieListener {
    constructor(tracker) {
        this._tracker = tracker;
    }

    onLoad(states) {
        return;
    }

    onUnload() {
        return;
    }

    getTracker() {
        return this._tracker;
    }

}
