const routes = window.routes;
const Cookies = window.Cookies;
const numbers = require('../../../lib/numbers');

export default class Card extends Backbone.Model {
    url = routes.rest.account.get;

    defaults() {
        return {
            holdLimit: 0,
            accounts: [],
            lastHoldEmail: '',
            bestLibraryKey: '',
            displayName: '',
            email: '',
            canPlaceHolds: true,
            isVisitingCard: false,

            checkoutLimitsByMediaType: {
                periodical: 0,
                video: 0,
                audiobook: 0,
                ebook: 0,
            },
        };
    }

    // 'Client Account Cookie' - AuthUtility is aware of this cookie and clears is on SignOut
    _key = '_cac';

    constructor() {
        super(...arguments);

        if (
            window.OverDrive.isAuthenticated &&
            window.OverDrive.hasLinkedCards
        ) {
            if (this._hasCachedModel) {
                this.set(this._cacheGet());

                // _fetch should only be used for async syncing and not for raw data access
                this._fetch = Promise.resolve();
            } else {
                this._fetch = this.fetch();
                this._fetch.then(() => this._cacheSet(this.attributes));
            }
        }
    }

    setHoldEmail(email) {
        this.set({ lastHoldEmail: email });

        this._cacheSet(this.attributes);
    }

    get preferredHoldEmail() {
        return this.get('lastHoldEmail') || this.get('this.email') || '';
    }

    get _hasCachedModel() {
        return !_(this._cacheGet()).isEmpty();
    }

    get canPlaceHolds() {
        return this.get('canPlaceHolds');
    }

    get isVisitingCard() {
        return this.get('isVisitingCard');
    }

    setAllowReadingHistory(allow) {
        this.set('allowReadingHistory', !!allow);
        this._cacheSet(this.attributes);
    }

    _cacheGet() {
        return JSON.parse(Cookies.get(this._key) || '{}');
    }

    cacheReset() {
        if (
            window.OverDrive.isAuthenticated &&
            window.OverDrive.hasLinkedCards
        ) {
            Cookies.remove(this._key);
            this._fetch = this.fetch();
            return this._fetch.then(() => this._cacheSet(this.attributes));
        }
        return Promise.resolve();
    }

    _cacheSet(model) {
        Cookies.set(this._key, JSON.stringify(model), {
            expires: new Date(
                new Date().getTime() + 10 * numbers.minuteInMilliseconds
            ),
        });
    }
}
