import { Modal } from  '../common/modal';

const { html } = window;

export default class HoldCancelModal extends Modal {

    constructor() {
        super({
            title   : html('cancelHoldModal.removeHold'),
            content : `
                <p>${ html('cancelHoldModal.removeConfirm') }</p>
                <div class="button-container"></div>`
        });

        this.$buttonConfirm = $(`
            <button aria-label="${ html('cancelHoldModal.confirmAriaLabel') }" class="button margin-small radius secondary u-allCaps">
                ${ html('cancelHoldModal.removeHold') }
            </button>`)
            .on('click keydown', (e) => { this.isSelectEvent(e) && this.trigger('confirm', this); });

        this.$buttonCancel  = $(`
            <button class="button radius margin-small outline gray cancel u-allCaps">${ html('cancel') }</button>`
            )
            .on('click keydown', (e) => { this.isSelectEvent(e) && this.trigger('cancel', this); });

        this.$content.find('.button-container').append([
            this.$buttonConfirm,
            this.$buttonCancel
        ]);
    }
}
