export default class RadioButton extends Backbone.View {
    template({value, checked, className, name, label, ariaLabel, rid}) {
        return `
            <label class='custom-radio'>
                <span class='custom-radio-button'>
                    <input type='radio' value='${value}' name='${name}-${rid}' class='${className}' aria-label='${ariaLabel}' ${checked ? 'checked' : ''} />
                    <i class='icon icon-radio-unselected'></i>
                    <i class='icon icon-radio-selected secondary-color'></i>
                </span>
                <span class='custom-radio-text'>
                    ${label}
                </span>
            </label>`;
    }

    get events() {
        return {
            'change [type=radio]': (e) => {
                this.trigger('change', e.currentTarget.checked);
            }
        };
    }

    constructor({ className, name, label, ariaLabel, rid}) {
        super(...arguments);

        _.extend(this, arguments[0]);
    }

    render() {
        this.$el.empty().append(this.template(this));

        this.$radio = this.$el.find('[type=radio]:first');

        return this;
    }

    set(checked) {
        this.$radio.prop('checked', checked).change();
    }
}
