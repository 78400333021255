/*
 * Encode a string
 * @param {string} str String to be encoded
 * @returns {string}
 */
const rfc3986EncodeURIComponent = function(str) {
    return encodeURIComponent(str).replace(/[&!'()*]/g, escape);
};

export default rfc3986EncodeURIComponent;
