/* node-env browser */

import BaseModel from './base';

const toJSONMapper = {
    automaticHoldCheckout: 'auto-checkout-setting',
    audience: 'audience-setting',
    highContrast: 'high-contrast',
    dyslexic: 'dyslexic',
    languageSelection: 'language-selection',
    kindleFulfillment: 'kindleFulfillment',
};

const PreferencesModel = BaseModel.extend({
    initialize() {
        this.on('change:automaticHoldCheckout', this.updateHolds.bind(this));
    },

    url() {
        return window.url(window.routes.preferences);
    },

    isNew() {
        return false;
    },

    getParsedAudience(audience) {
        return _.filter(
            _.map(_.pairs(audience), (item) => item[1] === true && item[0])
        );
    },

    parseBootstrappedData({
        audience,
        highContrast,
        automaticHoldCheckout,
        dyslexic,
        languageSelection,
        kindleFulfillment,
    }) {
        this.set(
            _.extend(
                { audience: this.getParsedAudience(audience) },
                {
                    automaticHoldCheckout: !!automaticHoldCheckout,
                    highContrast: !!highContrast,
                    dyslexic: !!dyslexic,
                    languageSelection,
                    kindleFulfillment,
                }
            ),
            { silent: true }
        );
    },

    getPatchData() {
        const changedAttributes = _.keys(this.changedAttributes());
        const toJSON = this.toJSON();
        const data = {};

        changedAttributes.forEach((item) => {
            data[toJSONMapper[item]] = toJSON[toJSONMapper[item]];
        });

        return _.omit(data, (value) => value === void 0);
    },

    toJSON() {
        const toJSON = {
            [toJSONMapper.audience]: this.get('audience'),
            [toJSONMapper.automaticHoldCheckout]: this.get(
                'automaticHoldCheckout'
            ),
            [toJSONMapper.highContrast]: this.get('highContrast'),
            [toJSONMapper.dyslexic]: this.get('dyslexic'),
            [toJSONMapper.languageSelection]: this.get('languageSelection'),
            [toJSONMapper.kindleFulfillment]: this.get('kindleFulfillment'),
        };
        return _.omit(toJSON, (value) => value === void 0);
    },

    updateHolds() {
        // Updates all previously held items to match new autocheckout setting
        const autoCheckout = this.get('automaticHoldCheckout');
        $.ajax({
            url: window.url(window.routes.updateHoldSettings),
            contentType: 'application/json',
            type: 'POST',
            data: JSON.stringify({ shouldAutoCheckout: autoCheckout }),
        });
    },
});

export default PreferencesModel;
