import { mandatory } from './parameters';

const createOpeningTag = (tag) => `<${tag}>`;

const createClosingTag = (tag) => `</${tag}>`;

const decodeEntities = (encodedString) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
};

const specialChar = [
    's',
    ',',
    '.',
    '+',
    '-',
    ' ',
    '(',
    ')',
    '|',
    '{',
    '}',
    '[',
    ']',
    '?',
    '*',
];

const getCleanMatcher = (matcher) => {
    let cleanMatcher = matcher;
    specialChar.forEach((sc) => {
        const pattern = new RegExp(`\\${sc}`, 'g');
        cleanMatcher = cleanMatcher.replace(pattern, '');
    });
    return cleanMatcher;
};

const getMatchSpecialCharString = () => {
    let specialCharList = '';
    specialChar.forEach((sc) => {
        if (specialCharList === '') {
            specialCharList = `\\${sc}`;
        } else {
            specialCharList = `${specialCharList}|\\${sc}`;
        }
    });
    return `(${specialCharList})*`;
};

const surroundSubtext = ({
    text = mandatory(),
    matcher = mandatory(),
    tag = mandatory(),
    ignoreCase = false,
    global = false,
}) => {
    // Makes users input of special characters be disregard
    const cleanMatcher = getCleanMatcher(matcher);
    // Makes users input match those with special characters
    const pattern = `(${cleanMatcher
        .split('')
        .join(getMatchSpecialCharString())})`;
    const flags = `${ignoreCase ? 'i' : ''}${global ? 'g' : ''}`;
    const regexp = new RegExp(pattern, flags);
    const newSubstring = `${createOpeningTag(tag)}$1${createClosingTag(tag)}`;
    return text.replace(regexp, newSubstring);
};

const createRegex = ({
    text = mandatory(),
    ignoreCase = false,
    global = false,
    ignoreWhitespace = false,
}) => {
    const pattern = ignoreWhitespace
        ? `(${text.split('').join('(\\s|\\n)*')})`
        : text;
    const flags = `${ignoreCase ? 'i' : ''}${global ? 'g' : ''}`;
    const regexp = new RegExp(pattern, flags);
    return regexp;
};

export {
    createOpeningTag,
    createClosingTag,
    decodeEntities,
    surroundSubtext,
    createRegex,
};
