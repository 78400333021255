import VisitorActionDisallowedModal from '../../components/visitor_action_disallowed_modal/VisitorActionDisallowedModal';

export default class Hold {
    constructor(bus, card) {
        this.bus = bus;
        this.card = card;
    }

    holdTitle(titleModel, context) {
        if (
            window.featureManager.isEnabled('LHTNG-3387') &&
            window.OverDrive.isVisitor &&
            (!window.featureManager.isEnabled('content-holds') ||
                !titleModel.get('isHoldable'))
        ) {
            new VisitorActionDisallowedModal('hold');
        } else if (this.card.preferredHoldEmail.length) {
            // LHTNG-1584 - Dont re-check availability, causes UI loops
            this._holdAjax(titleModel, context);
        } else {
            this.card._fetch.then(() => {
                if (this.card.preferredHoldEmail.length) {
                    this._holdAjax(titleModel, context);
                } else {
                    this.bus.trigger('title:hold:request-email', {
                        titleModel,
                        context,
                    });
                }
            });
        }
    }

    holdCancel(titleModel, context) {
        return $.ajax({
            type: 'DELETE',
            url: window.url(window.routes.mediaRemoveHold, {
                mediaId: titleModel.id,
            }),
        })
            .done((response) => {
                titleModel.set('titleState', 'hold');
                this.bus.trigger('title:hold-cancel:success', {
                    titleModel,
                    context,
                });
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                titleModel.set('titleState', 'onHold');
                this.bus.trigger('title:hold-cancel:fail', {
                    jqXHR,
                    textStatus,
                    errorThrown,
                    titleModel,
                    context,
                });
            })
            .complete(() => {
                this.bus.trigger('title:hold-cancel:end', {
                    titleModel,
                    context,
                });
            });
    }

    _holdAjax(titleModel, context) {
        this.bus.trigger('title:hold:start', {
            titleModel,
            email: this.card.preferredHoldEmail,
            context,
        });

        if (!context.isRequestAgain) {
            titleModel.set('titleState', 'waiting');
        }

        let { mobile, tablet } = bowser;

        return $.ajax({
            url: window.url(window.routes.mediaPlaceHold),
            type: 'POST',
            data: {
                mediaId: titleModel.id,
                email: this.card.preferredHoldEmail,
                isMobile: mobile || tablet,
                languageCode:
                    window.patronSettings['language-selection'] ||
                    window.Cookies.get('language-selection') ||
                    $('html').attr('lang') ||
                    'en',
            },
        })
            .done((response) => {
                let isRequestAgain =
                    !!context.isRequestAgain ||
                    /account\/loans/.test(window.location.href);
                titleModel.set(
                    'titleState',
                    isRequestAgain ? 'requested' : 'onHold'
                );
                this.bus.trigger('title:hold:success', { titleModel, context });
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                if (!context.isRequestAgain) {
                    titleModel.set('titleState', 'hold');
                }
                this.bus.trigger('title:hold:fail', {
                    jqXHR,
                    textStatus,
                    errorThrown,
                    titleModel,
                    context,
                });
            })
            .complete(() => {
                this.bus.trigger('title:hold:end', { titleModel, context });
            });
    }
}
