module.exports={
    "ru": {
        "name": "русский"
    },
    "ru-RU": {
        "name": "русский"
    },
    "ar": {
        "name": ""
    },
    "he": {
        "name": ""
    },
    "zh-Hans": {
        "name": "中文(简体)"
    },
    "zh-CN": {
        "name": "中文(简体)"
    },
    "zh-SG": {
        "name": "中文(简体)"
    },
    "zh-Hant": {
        "name": "中文(繁體)"
    },
    "zh-HK": {
        "name": "中文(繁體)"
    },
    "zh-MO": {
        "name": "中文(繁體)"
    },
    "zh-TW": {
        "name": "中文(繁體)"
    },
    "ja": {
        "name": "日本語"
    },
    "ja-JP": {
        "name": "日本語"
    },
    "ta": {
        "name": "மொழி"
    }
}
