import { Modal } from '../common/modal';
import template from './NonHoldConlictModal.html';
import TitleCard from '../media/TitleCard';
import TitleCollection from '../media/models/TitleCollection';
import TitleCollectionView from '../media/TitleCollectionView';
import relatedTitlesTemplate from '../media/templates/HoldSuccessRelatedTitles.html';
import Popover from '../../components/popover/popover';

const { html } = window;

export default class NonHoldConflictModal extends Modal {
    constructor(titleModel, options = {}) {
        const message = html(
            options.isLuckyDay
                ? 'nonHoldConflictModal.luckyDayMessageHtml'
                : 'nonHoldConflictModal.messageHtml',
            {
                title: `<span style="font-weight: bold">${titleModel.get(
                    'title'
                )}</span>`,
            }
        );

        const modalContents = {
            classes: ['nonHoldConflictModal'],
            title: html('nonHoldConflictModal.heading'),
            content: template({ titleModel, options, message }),
        };

        super(modalContents);

        this.collection = new TitleCollection();
        this.collection.url = `${window.url(
            window.routes.rest.media.availableRelated,
            { id: titleModel.id }
        )}?max=4`;
        this.collection.parse = (response) => {
            return _.values(response.titles);
        };
        this.$titleContainer = this.$('.relatedTitlesContainer');
        this.titleModel = titleModel;
        this.fetchRelatedTitles();

        if (options.isLuckyDay) {
            this.ldtooltip = new Popover({
                target: this.$el.find('#luckyDayTooltip'),
                contents: `<p>${html('nonHoldConflictModal.luckyDayInfo')}</p>`,
            });
        }
    }

    fetchRelatedTitles() {
        this.collection
            .fetch()
            .then((res) => {
                if (_(res.titles).isEmpty()) {
                    this.$titleContainer.empty();
                    return;
                }

                this.renderRelatedTitles();
            })
            .fail(() => {
                this.$titleContainer.empty();
            });
    }

    renderRelatedTitles() {
        this.$titleContainer.empty();
        this.$titleContainer.append(
            relatedTitlesTemplate({ titleModel: this.titleModel.attributes })
        );

        this.titleCollectionView = new TitleCollectionView({
            el: this.$titleContainer.find('.relatedTitles'),
            bus: this.bus,
            View: TitleCard,
            attributes: {
                class: 'titleContainer small-block-grid-2 medium-block-grid-4 large-block-grid-4',
            },
            collection: this.collection,
            coverWidths: { mobile: 150, tablet: 185, desktop: 220 },
        });
    }
}
