// import when we move more to modules
const Backbone = window.Backbone;
const fs = require('fs');
const path = require('path');
const loaderTemplate = _.template(fs.readFileSync(path.join(__dirname, '/../templates/loader.html'), 'utf8'));

const BaseView = Backbone.View.extend({

    close() {
        this.trigger('close');
        this.remove();
        this.unbind();
        this.stopListening();
        // Views should define this in their extend declaration
        this.onClose && this.onClose();
        return this;
    },

    getHtml({templateData} = {}) {
        const globals = {
            html: window.html,
            text: window.text
        };
        const data = templateData || this.getTemplateData();
        return this.template(_.extend({}, data, globals));
    },

    buildElement(options) {
        return this.$el.html(this.getHtml(options));
    },

    doRender(options) {
        this.buildElement(options);
        if (this.target && !this.attached) {
            this.beforeAttach && this.beforeAttach();
            this.attach();
            this.attached = true;
        }
    },

    attach() {
        if (!(this.$target instanceof Backbone.$)) {
            this.$target = Backbone.$(this.target);
        }
        this.$target.append(this.$el);
    },

    render(options) {
        this.clearLoader();

        if (this.beforeRender) {
            this.beforeRender();
        }
        this.doRender(options);
        if (this.afterRender) {
            this.afterRender();
        }
        return this;
    },

    getTemplateData() {
        return this.model && this.model.toJSON() || {};
    },

    renderLoader() {
        const elem = $(this.target).length ? $(this.target) : this.$el;
        elem.empty().append(loaderTemplate({html: window.html}));
    },

    clearLoader() {
        // Clear loader if it exists
        const $loader = $('.js-es6loader');
        if ($loader.length) {
            $loader.remove();
        }
    }

});

export default BaseView;
