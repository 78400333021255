import querystring from 'querystring';

const removeQueryParam = (qs, param) => {
    let newqs = qs.toLowerCase();
    if (qs[0] === '?') {
        newqs = qs.substr(1); // Remove starting '?'
    }
    const queryObj = querystring.parse(newqs);
    delete queryObj[param];
    return `?${querystring.stringify(queryObj)}`; // Reappend '?'
};

export {
    removeQueryParam
};
