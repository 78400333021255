var _ = require('underscore');
const semver = require('semver');

// View model for loans page.  I'm not really sure where this belongs in our project structure.  A good name also eludes me.
//
// I tried putting this logic in the swig and ran into some frustrating limitations.


class LoanTitle {
    constructor(title, limitDisplayFormats, preferKindle, isInODApp, isMac, macOsAudioDownloadsDisabled, macOSAudioDownloadReenabledPreCatalina,
        isPreCatalinaMac, restrictVideo, hideDisabledFormats, odAppSunset, fixMagazineDisplay, odreadFulfillmentFirst) {
        this.limitDisplayFormats = limitDisplayFormats;
        this.preferKindle = preferKindle;
        this.isInODApp = isInODApp;
        this.isMac = isMac;
        this.macOsAudioDownloadsDisabled = macOsAudioDownloadsDisabled; // feature flag for LHTNG-3702 'noAudiobooksForMacOS'
        this.macOSAudioDownloadReenabledPreCatalina = macOSAudioDownloadReenabledPreCatalina;  // feature flag for LHTNG-3789
        this.isPreCatalinaMac = isPreCatalinaMac;
        this.restrictVideo = restrictVideo; // feature flag for LHTNG-4042
        this.hideDisabledFormats = hideDisabledFormats; // feature flag for LHTNG-4218
        this.odAppSunset = odAppSunset; // feature flag for LHTNG-4191
        this.fixMagazineDisplay = fixMagazineDisplay; // feature flag for LHTNG-4278
        this.odreadFulfillmentFirst = odreadFulfillmentFirst; // feature flag for LHTNG-4437
        Object.assign(this, title);
    }

    get alternativeToKindle() {
        let alternativeFormat = '';
        if (this.hasOverdriveFormat) {
            alternativeFormat = 'odread';
        }
        else if (this.hasNonKindleNonReadFormat) {
            alternativeFormat = this.nonKindleNonReadFormats[0].id;
        }
        return alternativeFormat;
    }
    
    get showKindleTroubleAccordian() {
        return !this.showNonKindleNonReadFormats && !this.showODFormatButton && !(this.restrictedFormats && this.restrictedFormats.length > 0);
    }

    get isLockedOnKindle() {
        return this.isFormatLockedIn && this.otherFormats != null && this.otherFormats.length > 0 && this.otherFormats[0].id === 'ebook-kindle';
    }

    get isEbook() {
        return this.type.id === 'ebook';
    }

    get isAudiobook() {
        return this.type.id === 'audiobook';
    }

    get isVideo() {
        return this.type.id === 'video';
    }

    get isMagazine() {
        return this.type.id === 'magazine';
    }

    get hasReadalong() {
        return !!(this.overDriveFormat && this.overDriveFormat.hasAudioSynchronizedText);
    }

    get hasKindleFormat() {
        return this.hideDisabledFormats
            ? this.formats.findIndex((format) => format.id === 'ebook-kindle') !== -1 
            : this.otherFormats.findIndex((format) => format.id === 'ebook-kindle') !== -1;
    }

    get hasMp3Format() {
        return  this.formats.findIndex((format) => format.id === 'audiobook-mp3') !== -1 || this.otherFormats.findIndex((format) => format.id === 'audiobook-mp3') !== -1;
    }

    get hasMediaDoFormat() {
        return  this.formats.findIndex((format) => format.id === 'ebook-media-do') !== -1 || this.otherFormats.findIndex((format) => format.id === 'ebook-media-do') !== -1;
    }

    get hasNonKindleNonReadFormat(){
        return this.nonKindleNonReadFormats.length > 0;
    }

    get kindleFormat() {
        return this.otherFormats.find((format) => format.id === 'ebook-kindle');
    }

    get showNonKindleButtonAboveDivider() {
        return this.showNonKindleDownloadButton
            && !this.hasKindleFormat
            && ((this.odreadFulfillmentFirst && !this.showODFormatButton) || (!this.odreadFulfillmentFirst && this.showODFormatButton)) 
            && !this.showNoKindleMessage
            && !this.showOnlyOverDriveFormat;
    }

    get showNonKindleButtonBelowDivider(){
        return this.showNonKindleDownloadButton && !this.showNonKindleButtonAboveDivider && !this.showOnlyOverDriveFormat;
    }

    get showNonKindleDownloadButton() {
        return this.showNonKindleNonReadFormats
            && this.nonKindleNonReadFormats.length === 1;
    }

    get showDownloadDropdown() {
        return this.isEbook
            && this.showNonKindleNonReadFormats
            && this.nonKindleNonReadFormats.length > 1;
    }

    get showNonKindleNonReadFormats() {
        return (this.isEbook || (this.isAudiobook && this.odreadFulfillmentFirst)) && !this.isFormatLockedIn
            && this.nonKindleNonReadFormats
            && this.nonKindleNonReadFormats.length > 0
            && (!this.preferKindle || !this.limitDisplayFormats || !this.hasKindleFormat);
    }

    get showNoKindleMessage() {
        return !this.isFormatLockedIn && !this.hasKindleFormat && this.preferKindle && this.isEbook;
    }

    get nonKindleFormats() {
        return this.formats.filter((format) => format.id !== 'ebook-kindle' && format.id !== 'ebook-kobo');
    }

    get nonKindleNonReadFormats() {
        return this.hideDisabledFormats
            ? this.formats.filter((format) => format.id !== 'ebook-kindle'
              && format.id !== 'ebook-kobo'
              && format.id !== 'ebook-overdrive'
              && format.id !== 'audiobook-overdrive'
              && format.id !== 'video-streaming'
              && !(this.fixMagazineDisplay && format.id === 'magazine-overdrive')
            )
            : this.otherFormats.filter((format) => format.id !== 'ebook-kindle' && format.id !== 'ebook-kobo');
    }

    get tolinoSupportedDropdownFormats() {
        return this.nonKindleNonReadFormats.filter((format) => format.id !== 'audiobook-mp3');
    }

    get hasOverdriveFormat() {
        return !!(this.overDriveFormat && this.overDriveFormat.id != null);
    }

    get showOnlyOverDriveFormat() {
        return this.hideDisabledFormats && 
            ((this.isFormatLockedIn && (!this.odreadFulfillmentFirst || !this.isLockedOnKindle) && this.nonKindleNonReadFormats.length === 0) ||
            ((this.isEbook && !this.hasKindleFormat && this.nonKindleFormats.length === 1 && this.nonKindleFormats[0].id === "ebook-overdrive")
            || (this.isAudiobook && this.formats.length === 1 && this.formats[0].id === "audiobook-overdrive")));
    }

    get showODFormatButton() {
        return this.hasOverdriveFormat && this.overdriveFormatAllowed && !this.videoIsRestricted &&
            (!this.hasKindleFormat || !this.preferKindle || this.overDriveFormat.hasAudioSynchronizedText);
    }

    get suppressAudiobookDownloadButton() {
        // This is somewhat odd.  In the case where you're on a Mac but there is also a Listen format available, we render the mp3
        // download button server side so that the client-side code to replace it with a format warning will continue to work
        // This is obviously not very maintainable, but we have plans to completely replace this page with client-side
        // rendered backbone components, so I feel a kludge is justified under the circumstances.
        return this.isAudiobook && this.isMac && this.hasMp3Format && this.hasOverdriveFormat  && this.overdriveFormatAllowed && this.macOsAudioDownloadsDisabled
                && (!this.macOSAudioDownloadReenabledPreCatalina || !this.isPreCatalinaMac);
    }

    get overdriveFormatAllowed(){
        // The OD Read format is generally suppressed in the app but there are a few exceptions
        if (this.otherFormats && this.otherFormats.length === 1 && this.isInODApp &&
            (this.otherFormats[0].id === 'ebook-pdf-adobe' || this.otherFormats[0].id === 'ebook-pdf-open' || this.otherFormats[0].id === 'ebook-kindle')) {
            return true;
        } else if (this.overDriveFormat && this.overDriveFormat.hasAudioSynchronizedText) {
            return  true;
        } else {
            return !(this.isInODApp && this.otherFormats && this.otherFormats.length > 0) || (this.isInODApp && this.showOnlyOverDriveFormat);
        }
    }

    get firstButtonSlotOccupied() {
        return (this.isEbook && ((this.isFormatLockedIn && !this.showOnlyOverDriveFormat) 
                || this.hasKindleFormat 
                || this.showNoKindleMessage 
                || (this.odreadFulfillmentFirst && !this.hasKindleFormat && this.showODFormatButton) 
                || this.showNonKindleButtonAboveDivider))
            || (!this.isEbook && this.hasNonKindleNonReadFormat && !this.suppressAudiobookDownloadButton);
    }

    get secondButtonSlotOccupied() {
        return (this.showODFormatButton && !this.odreadFulfillmentFirst) 
            || (this.odreadFulfillmentFirst && this.showODFormatButton && !this.showOnlyOverDriveFormat) 
            || (this.showNonKindleNonReadFormats && !this.showNonKindleButtonAboveDivider);
    }

    get showDivider() {
        return (this.firstButtonSlotOccupied && this.secondButtonSlotOccupied) || (this.isAudiobook && !this.isInODApp && this.odAppSunset && this.showODFormatButton && this.hideDisabledFormats);
    }

    get useFirstPlayText() {
        return this.constraints && this.constraints.watchExpirationHours;
    }

    get videoIsRestricted() {
        return this.isVideo && this.restrictVideo; 
    }

    get isPdfOnly() {
        let nonPdfFormats = this.formats.filter(format => !format.id.includes("pdf") && !format.id.includes("kobo"));
        return nonPdfFormats.length === 0;
    }
}

module.exports = LoanTitle;
