class AppPromoManager {
    constructor() {
        this.APPLICATIONS = {
            LIBBY: 'libby',
            SORA: 'sora',
            KINDLE: 'kindle',
            KINDLEPREF: 'kindle-preference',
        };
    }

    /**
     * App Promotion Manager, check for Tolino Device Status
     * @param req - Express object representing the server request object
     * @param res - Express object representing the server response object
     */
    checkIfTolino(req) {
        let userAgent = req.headers['user-agent'];
        let tolinoCookieOverride = req.cookies['is-tolino-device'] == 1;
        let isTolinoPreviewWebsite =
            req.library &&
            req.library.websiteId &&
            req.intTolinoSites &&
            req.intTolinoSites.includes(String(req.library.websiteId));
        return (
            this.featureManager &&
            (this.featureManager.isEnabled('tolino') ||
                isTolinoPreviewWebsite) &&
            (tolinoCookieOverride ||
                /tolino/i.test(userAgent) ||
                /eReader/i.test(userAgent))
        );
    }

    /**
     * Create App Promotion Manager (Server)
     * @param req - Express object representing the server request object
     * @param res - Express object representing the server response object
     * @param featureManager - Express object representing the server side featureManager
     */
    static createServer(req, res, featureManager) {
        let instance = new this();
        instance.featureManager = featureManager;
        instance.settings = res.locals;
        instance.settings.isTolinoDevice = instance.checkIfTolino(req);
        return instance;
    }

    /**
     * Create App Promotion Manager (Client)
     * @param featureManager
     * @param window - client window object that we get needed information from
     * @private
     */
    static createClient(window) {
        let instance = new this();
        instance.featureManager = window.featureManager;
        instance.settings = window.OverDrive;
        return instance;
    }

    /**
     * Method for determining whether or not to show Application Promotions
     * @method
     * @param {string} application - application to check. Use Application property off FeatureManager
     * @returns {boolean}
     */
    isAppPromoEnabled(application) {
        switch (application) {
            case this.APPLICATIONS.LIBBY:
                return (
                    !this.settings.isTolinoDevice &&
                    this.featureManager.isEnabled('promoteLibby') &&
                    this.settings.enabledPlatforms.includes('libby')
                );
            case this.APPLICATIONS.SORA:
                return (
                    !this.settings.isTolinoDevice &&
                    this.settings.enabledPlatforms.includes('sora')
                );
            case this.APPLICATIONS.KINDLE:
                return (
                    this.settings.hasLinkedCards &&
                    this.settings.isAuthenticated &&
                    !this.settings.isTolinoDevice
                );
            case this.KINDLEPREF:
                return (
                    !this.settings.isTolinoDevice &&
                    this.featureManager.isEnabled('kindleLoanOffer')
                );
        }
    }
}

module.exports = AppPromoManager;
