import TitleActionButton        from '../../components/media/TitleActionButton';
import Title                    from '../media/models/Title';
import SuspensionModal          from '../SuspendHoldModal/SuspendHoldModal';
import HoldCancelModal          from '../HoldCancelModal/HoldCancelModal';
import Borrow                   from '../../title-actions/borrow/Borrow';

const { text } = window;

export default class NotificationsItem extends Backbone.View{
    constructor({id, titleId, type, timeUTC, data, isNotification = true}){
        super(...arguments);
        this.id = id;
        this.titleId = titleId;
        this.type = type;
        this.time = timeUTC;
        this.data = data || {};
        this.model = new Title(data);
        this.system = type === "SiteMessage";
        this.bus = window.globalEvents;
        this.isNotification = isNotification;
        this.dismissed = false;
        this.dismissCallbacks = [];

        this.setStateOfTitle();
    }

    render(){
        this.$el = this.template();
        this.addActionbuttons(this.$el);
        this.attachDismissHandlers();
        this.$el.find('.ClearNotification').on('click', () => this.dismissNotification());
        return this.$el;
    }

    setStateOfTitle(){
        if(["HoldSuspendedAuto", "HoldReady", "HoldSuspendedManual", "HoldSuspendedManual"].includes(this.type)){
            /*The usual method for updating the title's state through the PatronTitleStatus component is overkill 
              here since we can infer these are holds by the notification type. However, the logic for performing 
              actions on holds will not function correctly without this property set.*/
            this.model.set('isOnHold', true);
        }
    }

    registerDismissCallback(callback){
        this.dismissCallbacks.push(callback);
    }

    dismissNotification(){
        this.remove(); // we hide the notification regardless of whether the dismiss call succeeds, so we may as well make it snappy
        $.ajax({
            url: window.url(window.routes.rest.patronNotifications.dismiss, {id: this.id}),
            type: 'DELETE'
        })
        .done(() => {
            this.triggerDismissEvent();
        }).fail((xhr, status, err) => {
            window.Logger.log('error', 'Error dismissing notification', {message: err.statusText || err});
        });
    }

    remove(){
        this.isDismissed = true;
        this.$el.find('.Notification').addClass('notificationDisappear'); // fade
        this.$el.addClass('notificationShut'); // collapse
        setTimeout(() => { this.$el.hide(); }, 450); // vanish
        this.dismissCallbacks.forEach((callback) => {
            callback();
        });
    }

    triggerDismissEvent() {
        switch(this.type) {
            case "HoldSuspendedAuto":
                this.bus.trigger('title:suspend:dismiss', {titleModel: this.model, context: { isNotification: this.isNotification }});
                break;
            case "HoldLapsed":
                this.bus.trigger('title:hold-lapsed:dismiss', {titleModel: this.model, context: { isNotification: this.isNotification }});
                break;
            default:
                // Nothing to trigger
        }
    }

    getDateOfNotification(){
        return new Date(this.time);
    }

    getTitleImage(){
        if(this.data){
            return this.model.getCoverSrc(150);
        }
    }

    getNotificationText(titleName){
        switch (this.type) {
            case "HoldReady":{
                let expDate = new Date(this.model.get("expireDate"));
                let now = new Date();
                let header = "";
                let hoursLeft = Math.floor((expDate.getTime() - now.getTime()) / 3.6e+6);
                if (hoursLeft >= 25) { //display days
                    let daysLeft = Math.floor((expDate.getTime() - now.getTime()) / 8.64e+7);
                    header = text("notifications.HoldReady.header.days", {title: titleName, time: daysLeft});
                }
                else if (hoursLeft >= 1){ //display hours
                    header = text("notifications.HoldReady.header.hours", {title: titleName, time: hoursLeft});
                } else { //display min
                    let minutesLeft = Math.floor((expDate.getTime() - now.getTime()) / 60000);
                    header = text("notifications.HoldReady.header.mins", {title: titleName, time: minutesLeft});
                }
                return {
                    header : header,
                    mainText : text("notifications.HoldReady.mainText")
                };
            }
            case "HoldSuspendedAuto":{
                return {
                    header : text("notifications.HoldSuspendedAuto.header", {title: titleName, date: this.model.get("suspensionEnd")}),
                    mainText : text("notifications.HoldSuspendedAuto.mainText", {date: this.model.get("suspensionEnd")})
                };
            }
            case "HoldLapsed":{
                return {
                    header: text("notifications.HoldLapsed.header", {title: titleName}),
                    mainText : text("notifications.HoldLapsed.mainText")
                };
            }
            default:{
                return {
                    header : `${titleName} is ready for some action. Do something with it.`,
                    mainText : "Look at the buttons below and pick the appropriate action to dismiss this notification."
                };
            }
        }
    }

    isDismissable(){
        return this.system || this.type === "HoldSuspendedAuto" || this.type === "HoldLapsed";
    }

    template(){
        let notificationDate = text("notifications.date" ,{date: this.getDateOfNotification()});
        let titleImage = this.getTitleImage();
        let coverLink = this.model.titleDetailsUrl;
        let titleName = this.model.get("title");

        let textObj = this.getNotificationText(titleName);
        let header = textObj.header;
        let mainText = textObj.mainText;

        return $(`
        <li class="Notification-item" tabindex="0" aria-label="${text("notifications.notificationLabel", {title: titleName})}">
            <div class="Notification show-for-medium-up">
                <div class="Notification-MainInfo ${this.isDismissable() ? "clear-button-added" : ""}">
                    <div class="topRow">
                        <div class="ImageContainer">
                            <a href="${coverLink}"><img alt="${titleName}" src="${titleImage}"></a>
                        </div>
                        <div class="NotificationInfo">
                            <div class="date"><p>${notificationDate}</p></div>
                            <div>
                                <h3 class="titleInfo">${header}</h3>
                                <p class="actionInfo">${mainText}</p>
                            </div>
                        </div>
                        ${this.isDismissable()
                            ?
                            `<button tabindex="0" aria-label="${text("notifications.clearNotificationLabel", {title: titleName})}" class="ClearNotification" data-notId="${this.id}">
                                <i class="icon-clear"></i>
                            </button>` : ''}
                    </div>

                    ${!this.system
                        ? `<div class="actionButtons">
                    </div>` : ''}
                </div>

            </div>

            <div class="Notification show-for-small-only">
                <div class="Notification-MainInfo ${this.isDismissable() ? "clear-button-added" : ""}">
                    <div class="topRow">
                        <div class="ImageContainer">
                            <a href="${coverLink}"><img alt="${titleName}" src="${titleImage}"></a>
                        </div>
                        <div class="NotificationInfo">
                            <div class="date"><p>${notificationDate}</p></div>
                            <div>
                                <p class="titleInfo">${header}</p>
                            </div>
                        </div>
                        ${this.isDismissable()
                            ? `<div tabindex="0" aria-label="${text("notifications.clearNotificationLabel", {title: titleName})}" class="ClearNotification" data-notId="${this.id}">
                                <i class="icon-clear"></i>
                            </div>` : ''}
                    </div>
                    <div class="mobile-bottom-notification-part">
                        <p class="actionInfo">${mainText}</p>
                        ${!this.system ? '<div class="actionButtons"></div>' : ''}
                    </div>

                </div>

            </div>
        </li>
        `);
    }

    attachDismissHandlers() {
        let borrowAction = 'title:borrow:success';
        let cancelHoldAction = 'title:hold-cancel:success';
        let removeSuspensionAction = 'title:suspend:cancelled';
        let suspendHoldAction = 'title:suspend'; // this includes editing a suspension as well as adding a new one
        let placeHoldAction = 'title:hold:success';

        let dismissActions = [];
        switch (this.type) {
            case 'HoldReady':
                dismissActions = [borrowAction, suspendHoldAction, cancelHoldAction];
                break;
            case 'HoldLapsed':
                dismissActions = [placeHoldAction];
                break;
            case 'HoldSuspendedAuto':
                dismissActions = [cancelHoldAction, suspendHoldAction, removeSuspensionAction];
                break;
            default:
                throw new Error(`Notification type '${this.type} is not currently supported.`);
        }

        let dismissIfTitleMatches = ({titleModel}) => {
            if (titleModel && titleModel.get('id') === this.model.get('id')){
                this.dismissNotification();
            }
        };

        dismissActions.forEach((action) => {
            this.bus.on(action, dismissIfTitleMatches);
        });
    }


    addActionbuttons(template){
        let buttonsContainer = template.find(".actionButtons");
        let titleId = this.model.get("id");
        let titleName = this.model.get("title");
        if(buttonsContainer.length > 0){
            switch (this.type) {
                case "HoldReady":
                    buttonsContainer.append(this.borrowButton(titleId, titleName));
                    buttonsContainer.append(this.suspendHoldButton(titleId, titleName));
                    buttonsContainer.append(this.cancelHoldButton(titleId, titleName));
                    break;

                case "HoldLapsed":
                    buttonsContainer.append(this.placeHoldButton(titleId, titleName));
                    break;
                case "HoldSuspendedAuto":
                    buttonsContainer.append(this.dismissNotificationButton());
                    buttonsContainer.append(this.suspendHoldButton(titleId, titleName, text("notifications.editDeliveryDate")));
                    buttonsContainer.append(this.cancelHoldButton(titleId, titleName));
                    break;
                default:
                    throw new Error(`Notification type '${this.type} is not currently supported.`);
            }
        }
    }

    dismissNotificationButton(){
        let template = $(`<button tabindex="0" class="NotificationButton dismiss secondary-background full">
                                ${text("ok")}
                            </button>`);
        template.on('click', () => this.dismissNotification());
        return template;
    }

    borrowButton(titleId, titleName){
        let template = $(`<button tabindex="0" class="NotificationButton borrow secondary-background full" data-titleId="${titleId}" aria-label="${text("borrowTitle", {title: titleName})}">
                                ${text("borrow")}
                            </button>`);

        template.on("click", (e) => {
            new Borrow(this.bus).borrowTitle(this.model, { isNotification: this.isNotification });
        });

        return template;
    }

    cancelHoldButton(titleId, titleName){
        let template = $(`<button tabindex="0" class="NotificationButton secondary-background full cancel-hold" data-titleId="${titleId}" aria-label="${text("accountHolds.removeHoldOnTitle", {title: titleName})}">
                                ${text("accountHolds.removeHoldLabel")}
                            </button>`);


        template.on("click", () => {
            (new HoldCancelModal()).on('confirm', (modal) => {
                // ... because old code
                this.bus.trigger('title:hold-cancel', this.model, { isNotification: this.isNotification });
                modal.close();
            });
        });

        return template;
    }

    suspendHoldButton(titleId, titleName, words=""){
        let copyToUse = words === "" ? text("notifications.deliverLater") : words;
        let template = $(`<button tabindex="0" class="NotificationButton suspend border" data-titleId="${titleId}" aria-label="${copyToUse} - ${titleName}">
                                ${copyToUse}
                            </button>`);

        template.on("click", () => {
            new SuspensionModal(this.model, false, this.isNotification);
        });

        return template;
    }

    placeHoldButton(titleId, titleName){
        let template = $(`<button tabindex="0" class="NotificationButton place-hold secondary-background full" data-titleId="${titleId}" aria-label="${text("placeAHoldOnTitle", {title: titleName})}">
                                ${text("placeAHold")}
                            </button>`);

        template.on("click", (e) => {
            this.bus.trigger('title:hold', this.model, { isNotification: this.isNotification });
        });

        return template;
    }

    renewTitleButton(titleName){
        let template = $(`<button tabindex="0" class="NotificationButton secondary-background full" aria-label="${text("returnModal.heading")} ${titleName}">
                            ${text("accountHolds.return")}
                        </button>`);
        return template;
    }

    returnButton(titleName){
        let template = $(`<button tabindex="0" class="NotificationButton secondary-background full" aria-label="${text("renewModal.renewSpecificTitleAriaLabel", {title: titleName})}">
                            ${text("renewal.renew")}
                        </button>`);
        return template;
    }

    returnEarlyButton(titleName){
        let template = $(`<button tabindex="0" class="NotificationButton border" aria-label="${text("accountHolds.return")} ${titleName}">
                            ${text("accountHolds.return")}
                        </button>`);
        return template;
    }

    requestAgainButton(titleName){
        let template = $(`<button tabindex="0" class="NotificationButton secondary-background full" aria-label="${text("accountLoans.requestTitleAgain", {title: titleName})}">
                            ${text("accountLoans.requestAgain")}
                        </button>`);
        return template;
    }
}
