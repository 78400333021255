/*
 * Remove carousel functionality
 */
const removeCarouselFunctionality = function () {
    const vertOrientation = Math.abs(window.orientation) !== 90;
    // Delete all but 3 in carousels
    $('.carousel.loaded').each((index, el) => {
        let children = $(el).children();
        for (let x = 3; x < children.length; x++) {
            $(children[x]).remove();
        }

        children = children.slice(0, 3);
        children.addClass('text-left');
        $(el).addClass('text-center').css('overflow-x', 'hidden');

        if (vertOrientation) {
            children.last().hide();
        }
    });
    $('.js-carouselArrow').remove(); // Remove arrows
};

/*
 * On orientation change, ensure carousel has no functionality
 */
const persistRemovalOfCarouselFunctionality = function () {
    window.onorientationchange = function () {
        $('.carousel.loaded').each((index, el) =>
            $(el).children().last().hide()
        );
    };
};

/*
 * Display titles without a carousel n old Android (< v. 3.0) devices
 * @param device - Bowser object
 */
const removeCarouselFunctionForOldAndroid = function (device) {
    const semver = require('semver');
    let thisVersion = semver.coerce(device.osversion);
    const is2genKindle =
        device.android &&
        semver.satisfies(thisVersion, '>=4.0.3') &&
        !!window.navigator.userAgent.match(/KFTT/);
    const isOldAndroid = device.android && parseInt(device.osversion, 10) < 3;

    if (is2genKindle || isOldAndroid) {
        removeCarouselFunctionality();
        persistRemovalOfCarouselFunctionality();
    }
};

export { removeCarouselFunctionForOldAndroid };
