import { Modal }                            from '../../common/modal';
import KindlePreferenceResultModalTemplate  from '../templates/KindlePreferenceResultModal.html';

export default class KindlePreferenceSuccessModal extends Modal {
    constructor({ wasSuccessful, buttonText, continueTargetUrl }) {
        const modalContents = {
            classes: ['KindlePreferenceResultModal'],
            title: wasSuccessful ? window.html('genericSuccess') : window.html('genericError'),
            content: KindlePreferenceResultModalTemplate({wasSuccessful, buttonText, continueTargetUrl})
        };
        super(modalContents);

        let kindleLogo = `<div class='logo-container'><img src='https://images.overdrive.com/misc/kindle/Kindle-icon.png' alt='${html('kindleFulfillment.kindle')}' /></div>`;
        this.$title.before(kindleLogo);

        this.$el.find('.continue-button').on('click', () => {
            this.trigger('close');
        });
    }
}
