const { html } = window;
export default (title, text) => `
<div class="modal-container" aria-describedby="remove-suspension-label">
    <div class="large-12 columns no-padding-left">
        <h3 class="mediaTitle" tabindex="-1">${title}</h3>
        <p class="removeHoldSuspensionText">${text}</p>
        <span id="remove-suspension-label">
            ${html('holdRedelivery.areYouSure')}
        </span><br/>
    </div>

    <div class="button-container">
        <button tabindex="0" id="remove-suspend" class="button margin-small radius secondary contrast u-allCaps" role="button" aria-label="${ html('removeSuspensionModal.remove') }">
            <span>${ html('removeSuspensionModal.remove') }</span>
        </button>
        <button type="button" data-reveal-id="suspension-modal" class="button radius margin-small outline gray cancel u-allCaps js-last-focus">${ html('cancel') }</button>
    </div>
</div>
 `;
